export const PREVIEW_EVENT = {
  AUDIO_CONNECT_SUCCESS: 'audio_connect_success',
  AUDIO_INIT_SUCCESS: 'audio_init_success',
  AUDIO_PREVIEW_ASN: 'audio_preview_asn',
  AUDIO_LEAVE_SUCCESS: 'audio_leave_success',
  AUDIO_FORBIDDEN: 'audio_forbidden',
  SPEAKER_CHANGE: 'speaker_change',
  MICROPHONE_CHANGE: 'microphone_change',

  VIDEO_INIT_SUCCESS: 'video_init_success',
  VIDEO_START_SUCCESS: 'video_start_success',
  CAMERA_IS_TAKEN: 'camera_is_taken',
  VIDEO_FORBIDDEN: 'video_forbidden',
  CAMERA_CHANGE: 'camera_change',

  DEVICES_UPDATE: 'devices_update',
};

export const PREVIEW_AUDIO_STATUS = {
  DISABLED: 'disabled',
  FORBIDDEN: 'forbidden',
  NOT_CONNECTED: 'not-connected',
  MUTED: 'muted',
  UNMUTED: 'unmuted',
  LOADING: 'loading',
  CAPTURING: 'capturing',
};

export const PREVIEW_VIDEO_STATUS = {
  DISABLED: 'disabled',
  FPRBIDDEN: 'forbidden',
  OPEN: 'open',
  CLOSED: 'closed',
  LOADING: 'loading',
  CAPTURING: 'capturing',
};

export const PREVIEW_CANVAS = 'canvas-preview';

export const PREVIEW_ERROR = {
  NONE: 'none',
  CAMERA_TAKEN: 'camera_taken',
  AUDIO_FORBIDDEN: 'audio_forbidden',
  VIDEO_FORBIDDEN: 'video_forbidden',
};

export const FETCH_MEETING_STATUS_INTERVAL = 30 * 1000; // 30s

export const ERROR_CODE = {
  webinar_in_practice_session: 3129,
  join_wrong_password: 3004,
  join_need_name: 9503,
  join_need_email: 9504,
  register_info_invalid: 9506,
};

export const CACHE_KEY = {
  PREVIEW_OPTIONS: 'previewOptions',
  AVSTATUS: 'avStatus',
};

export const COOKIE_REMEMBERED_NAME = '_zm_wc_remembered_name';

export const EXPIRE_DAYS_REMEMBERED_NAME = 90;

export const MEETING_INFO_TEXT_LIMIT = 128;

// default connect and open audio/video
export const DEFAULT_PREVIEW_OPTIONS = 7;

export const PREVIEW_OPTIONS = {
  AUTO_JOIN_AUDIO: 1,
  AUDIO_ON: 1 << 1,
  VIDEO_ON: 1 << 2,
};
