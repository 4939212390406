import meetingConfig from 'meetingConfig';
import axios from 'axios';
import qs from 'qs';
import { easyStore, storeType } from '../easy-store';
import _ from 'lodash';
import { infoLog } from '../web-client-logger';

const defaultMeetingTokens = {
  zak: meetingConfig.zak,
  auth: meetingConfig.auth,
  trackAuth: meetingConfig.trackAuth,
  tid: meetingConfig.tid,
  ts: meetingConfig.ts,
  encryptedRWC: meetingConfig.encryptedRWC,
  encryptedGEORWC: meetingConfig.encryptedGEORWC,
};

const meetingTokensKey = 'WEB-CLIENT-MEETING-TOKENS';
export function getMeetingTokens() {
  const valInStore = easyStore.easyGet(meetingTokensKey);
  if (valInStore == null) {
    return defaultMeetingTokens;
  }
  return valInStore;
}

export function setMeetingTokens(newTokens) {
  const { encryptedRWC, encryptedGEORWC } = newTokens;
  easyStore.easySet(
    meetingTokensKey,
    {
      zak: newTokens.zak,
      fileReadZak: newTokens.fileReadZak,
      zmk: newTokens.zmk,
      auth: newTokens.auth,
      trackAuth: newTokens.trackAuth,
      tid: newTokens.tid,
      ts: newTokens.ts,
      encryptedRWC:
        typeof encryptedRWC === 'string'
          ? JSON.parse(encryptedRWC)
          : encryptedRWC,
      encryptedGEORWC:
        typeof encryptedGEORWC === 'string'
          ? JSON.parse(encryptedGEORWC)
          : encryptedGEORWC,
    },
    storeType.memory,
  );
}

function refreshTokens() {
  const { baseUrl } = meetingConfig;
  const url = `${baseUrl}/wc/refresh_tokens`;
  const tokens = getMeetingTokens();
  const { auth, ts, zak, zmk, fileReadZak } = tokens;
  const { meetingNumber, rwcDefault, rwcBackup } = meetingConfig;
  const body = {
    ts,
    auth,
    meetingNumber,
    rwcDefault,
    rwcBackup,
    clientType: 'mobile',
  };
  if (zak !== '') {
    body.zak = zak;
  }
  if (zmk !== '') {
    body.zmk = zmk;
  }
  if (fileReadZak !== '') {
    body.fileReadZak = fileReadZak;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(url, qs.stringify(body))
      .then(({ data }) => {
        if (data.status && !_.isEmpty(data.result)) {
          const result = JSON.parse(data.result);
          resolve(result);
        } else {
          reject(Error('refresh token result is empty'));
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function easySleep(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

function retryRequest(func, retryCount) {
  return func().catch(() => {
    if (retryCount <= 0) {
      throw new Error('retry request failed');
    }
    // eslint-disable-next-line no-param-reassign
    retryCount -= 1;
    // eslint-disable-next-line no-restricted-properties
    return easySleep(Math.pow(2, 4 - retryCount) * 1000).then(() => {
      return retryRequest(func, retryCount);
    });
  });
}

export const fetchRWCToken = async () => {
  infoLog('-- Refresh Authentication Token --');
  const result = await retryRequest(refreshTokens, 5);
  setMeetingTokens(result);
  return result;
};
