/* eslint-disable @babel/no-invalid-this */
import meetingConfig from 'meetingConfig';
import { encodeBase64 } from '../utils/base64';

export function initStorageKey() {
  Object.assign(this.memory, {
    sessionStorageKey: encodeBase64(`easy-${meetingConfig.mid}`),
    localStorageKey: encodeBase64('easy-local-storage'),
  });
}
