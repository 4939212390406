import { Task } from '@zoom/task-core';
import {
  handleMeetingStatusRes,
  requestJoinMeeting,
} from '../../features/preview/service';
import { handleMeetingConfig } from '../../global/service/meetingConfig';
import { taskRunMainClient } from './task.run-main-client';
import { taskRunWaitingForHost } from './task.run-waiting-for-host';
import {
  blackBoxEmitEvent,
  BlackBoxEvent,
} from '../../global/service/black-box-service';

function requestJoinMeetingJob() {
  return requestJoinMeeting().then((res) => {
    const {
      result: { rsRes, MeetingConfig },
    } = res.data;
    handleMeetingConfig(MeetingConfig);
    const { status, tip } = handleMeetingStatusRes(rsRes);
    if (status) {
      blackBoxEmitEvent(BlackBoxEvent.skip_preview_to_start_meeting);
      Task.run({
        expectMainTask: taskRunMainClient,
      });
    } else {
      blackBoxEmitEvent(BlackBoxEvent.skip_preview_to_start_JBH);
      Task.run({
        expectMainTask: taskRunWaitingForHost,
        taskProps: { waitingTip: tip },
      });
    }
  });
}

export const taskRequestJoinMeeting = [requestJoinMeetingJob];
