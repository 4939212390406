import {
  loadScriptOnWebClientStart,
  loadMeidiasdkScriptOnWebClientStart,
} from './jobs';
import {
  runTaskLoadMainClient,
  handleRouter,
  initLogManager,
} from '../../model';
import { initTP } from '../../../global/service/tp-service';
import { blackBoxProblemDetection } from './black-box-problem-detection';

export const taskBeforeLoadMainClient = [
  blackBoxProblemDetection,
  handleRouter,
  loadScriptOnWebClientStart,
  loadMeidiasdkScriptOnWebClientStart,
  initLogManager,
  initTP,
  runTaskLoadMainClient,
];
