import { SESSIONSTORAGE_KEYS } from '../constant';
import { easyStore, storeType } from '../easy-store';

export function getAVStatus() {
  const valInStore = easyStore.easyGet(SESSIONSTORAGE_KEYS.avStatus);
  if (!valInStore) {
    return {};
  }
  return valInStore;
}

export function setAVStatus(obj) {
  const avStatus = getAVStatus();
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.avStatus,
    { ...avStatus, ...obj },
    storeType.sessionStorage,
  );
}
