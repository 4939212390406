export const WS_CONN_KEEPALIVE = 0;

// event type
export const CONF_EVT_TYPE_BASE = 0x1000;
export const AUDIO_EVT_TYPE_BASE = 0x2000;
export const VIDEO_EVT_TYPE_BASE = 0x3000;
export const SHARING_EVT_TYPE_BASE = 0x4000;
export const XMPP_EVT_TYPE_BASE = 0x6000;

// conference level event request/response
export const WS_CONF_JOIN_REQ = 1 | CONF_EVT_TYPE_BASE;
export const WS_CONF_JOIN_RES = 2 | CONF_EVT_TYPE_BASE; // 4098
export const WS_CONF_LOCK_REQ = 3 | CONF_EVT_TYPE_BASE;
export const WS_CONF_LOCK_RES = 4 | CONF_EVT_TYPE_BASE;
export const WS_CONF_END_REQ = 5 | CONF_EVT_TYPE_BASE;
export const WS_CONF_END_RES = 6 | CONF_EVT_TYPE_BASE;
export const WS_CONF_LEAVE_REQ = 7 | CONF_EVT_TYPE_BASE;
export const WS_CONF_LEAVE_RES = 8 | CONF_EVT_TYPE_BASE;
export const WS_CONF_RECORD_REQ = 9 | CONF_EVT_TYPE_BASE;
export const WS_CONF_RECORD_RES = 10 | CONF_EVT_TYPE_BASE;
export const WS_CONF_EXPEL_REQ = 11 | CONF_EVT_TYPE_BASE;
export const WS_CONF_EXPEL_RES = 12 | CONF_EVT_TYPE_BASE;
export const WS_CONF_RENAME_REQ = 13 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ASSIGN_HOST_REQ = 15 | CONF_EVT_TYPE_BASE;
export const WS_CONF_PUT_ON_HOLD_REQ = 17 | CONF_EVT_TYPE_BASE;
export const WS_CONF_SET_MUTE_UPON_ENTRY_REQ = 19 | CONF_EVT_TYPE_BASE;
export const WS_CONF_SET_HOLD_UPON_ENTRY_REQ = 21 | CONF_EVT_TYPE_BASE;
export const WS_CONF_INVITE_CRC_DEVICE_REQ = 23 | CONF_EVT_TYPE_BASE;
export const WS_CONF_INVITE_CRC_DEVICE_RES = 24 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CANCEL_INVITE_CRC_DEVICE_REQ = 25 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CANCEL_INVITE_CRC_DEVICE_RES = 26 | CONF_EVT_TYPE_BASE;
export const WS_CONF_SET_BROADCAST_REQ = 27 | CONF_EVT_TYPE_BASE;
export const WS_CONF_SET_BROADCAST_RES = 28 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CLOSED_CAPTION_REQ = 29 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CLOSED_CAPTION_RES = 30 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_VIEW_PARTICIPANT_REQ = 31 | CONF_EVT_TYPE_BASE;
export const WS_CONF_LOWER_ALL_HAND_REQ = 33 | CONF_EVT_TYPE_BASE;
export const WS_CONF_RAISE_LOWER_HAND_REQ = 35 | CONF_EVT_TYPE_BASE;
export const WS_CONF_RECLAIM_HOST_REQ = 37 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CHAT_REQ = 39 | CONF_EVT_TYPE_BASE; // 4135
export const WS_CONF_CHAT_RES = 40 | CONF_EVT_TYPE_BASE; // 4136
export const WS_CONF_ASSIGN_CC_REQ = 41 | CONF_EVT_TYPE_BASE;
export const WS_CONF_CHAT_PRIVILEDGE_REQ = 45 | CONF_EVT_TYPE_BASE;
export const WS_CONF_FEEDBACK_REQ = 47 | CONF_EVT_TYPE_BASE;
export const WS_CONF_FEEDBACK_CLEAR_REQ = 49 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_UNMUTE_VIDEO_REQ = 51 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_UNMUTE_AUDIO_REQ = 53 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_RAISE_HAND_REQ = 55 | CONF_EVT_TYPE_BASE;
export const WS_CONF_PANELIST_VOTE_REQ = 57 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_TOKEN_REQ = 77 | CONF_EVT_TYPE_BASE; // 4173
export const WS_CONF_MONITOR_LOG_REQ = 71 | CONF_EVT_TYPE_BASE; // 4167
export const WS_CONF_BO_TOKEN_RES = 78 | CONF_EVT_TYPE_BASE; // 4174
export const WS_CONF_BO_START_REQ = 79 | CONF_EVT_TYPE_BASE; // 4175
export const WS_CONF_BO_STOP_REQ = 81 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_ASSIGN_REQ = 83 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_SWITCH_REQ = 85 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_WANT_JOIN_REQ = 87 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_LEAVE_REQ = 89 | CONF_EVT_TYPE_BASE; // 4185
export const WS_CONF_BO_BROADCAST_REQ = 91 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_HELP_REQ = 93 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_HELP_RESULT_REQ = 95 | CONF_EVT_TYPE_BASE;
export const WS_CONF_BO_JOIN_REQ = 97 | CONF_EVT_TYPE_BASE; // 4193
export const WS_CONF_BO_JOIN_RES = 98 | CONF_EVT_TYPE_BASE; // 4194
export const WS_CONF_ALLOW_PARTICIPANT_RENAME_REQ = 67 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_MESSAGE_FEEDBACK_NOTIFY_REQ =
  75 | CONF_EVT_TYPE_BASE;
export const WS_CONF_REVOKE_COHOST_REQ = 99 | CONF_EVT_TYPE_BASE;
export const WS_CONF_PLAY_CHIME_OPEN_CLOSE_REQ = 101 | CONF_EVT_TYPE_BASE; // 4197
export const WS_CONF_ADMIT_ALL_SILENT_USERS_REQ = 103 | CONF_EVT_TYPE_BASE; // 4199
export const WS_CONF_BIND_UNBIND_TELE_USR_REQ = 105 | CONF_EVT_TYPE_BASE; // 4201
export const WS_CONF_ALLOW_QA_AUTO_REPLY_REQ = 107 | CONF_EVT_TYPE_BASE; // 4203
export const WS_CONF_EXPEL_ATTENDEE_REQ = 109 | CONF_EVT_TYPE_BASE; // 4205
export const WS_CONF_EXPEL_ATTENDEE_RES = 110 | CONF_EVT_TYPE_BASE; // 4206
export const WS_CONF_PRACTICE_SESSION_REQ = 111 | CONF_EVT_TYPE_BASE; // 4207
export const WS_CONF_PRACTICE_SESSION_RES = 112 | CONF_EVT_TYPE_BASE; // 4208
export const WS_CONF_ROLE_CHANGE_REQ = 113 | CONF_EVT_TYPE_BASE; // 4209
export const WS_CONF_ROLE_CHANGE_RES = 114 | CONF_EVT_TYPE_BASE; // 4210
export const WS_CONF_BO_TOKEN_BATCH_REQ = 115 | CONF_EVT_TYPE_BASE; // 4211
export const WS_CONF_BO_PRE_ASSIGN_REQ = 117 | CONF_EVT_TYPE_BASE; // 4213
export const WS_CONF_BO_PRE_ASSIGN_RES = 118 | CONF_EVT_TYPE_BASE; // 4214
export const WS_CONF_CHANGE_MULTI_PIN_PRIVILGE_REQ = 121 | CONF_EVT_TYPE_BASE; // 4217
export const WS_CONF_SET_GROUP_LAYOUT = 123 | CONF_EVT_TYPE_BASE; // 4219
export const WS_CONF_HOST_KEY_REQ = 119 | CONF_EVT_TYPE_BASE; // 4215
export const WS_CONF_HOST_KEY_RES = 120 | CONF_EVT_TYPE_BASE; // 4216
export const WS_CONF_AVATAR_PERMISSION_CHANGED = 126 | CONF_EVT_TYPE_BASE; // 4222
export const WS_CONF_SUSPEND_MEETING = 133 | CONF_EVT_TYPE_BASE; // 4229
export const WS_CONF_SUSPEND_MEETING_REQ_RESULT = 134 | CONF_EVT_TYPE_BASE; // 4230
export const WS_CONF_BO_ASSIGN_BATCH_REQ = 135 | CONF_EVT_TYPE_BASE; // 4231
export const WS_CONF_CHAT_CMD_REQ = 141 | CONF_EVT_TYPE_BASE; // 4237
export const WS_CONF_CHAT_CMD_RES = 142 | CONF_EVT_TYPE_BASE; // 4238

export const WS_CONF_CHAT_CMD_INDICATION = 24 | CONF_EVT_TYPE_BASE | 0x0f00; // 7960
export const WS_CONF_MEETING_TOPIC_REQ = 136 | CONF_EVT_TYPE_BASE; // 4231
export const WS_CONF_MEETING_TOPIC_CHANGE_FAILED = 137 | CONF_EVT_TYPE_BASE; // 4232
export const WS_CONF_MEETING_TOPIC_CHANGE = 138 | CONF_EVT_TYPE_BASE; // 4233

export const WS_CONF_BO_COHOST_START_REQ = 145 | CONF_EVT_TYPE_BASE; // 4241
export const WS_CONF_BO_COHOST_STOP_REQ = 147 | CONF_EVT_TYPE_BASE; // 4243
export const WS_CONF_BO_COHOST_ASSIGN_REQ = 149 | CONF_EVT_TYPE_BASE; // 4245
export const WS_CONF_BO_MOVE_TO_MAIN_SESSION_REQ = 151 | CONF_EVT_TYPE_BASE; // 4247
export const WS_CONF_BO_COHOST_MOVE_TO_MAIN_SESSION_REQ =
  153 | CONF_EVT_TYPE_BASE; // 4249
export const WS_CONF_INTERPRETER_TYPE_CHANGE = 154 | CONF_EVT_TYPE_BASE; // 4250
export const WS_CONF_PARTICIPANT_LIST_CHANGE = 155 | CONF_EVT_TYPE_BASE; // 4251
export const WS_CONF_INTERPRETER_START = 156 | CONF_EVT_TYPE_BASE; // 4252
export const WS_CONF_PARTICIPANT_LANGUAGE_INVALID = 157 | CONF_EVT_TYPE_BASE; // 4253
export const WS_CONF_AB_TOKEN_RES = 203 | CONF_EVT_TYPE_BASE; // 4299
export const WS_CONF_AB_TOKEN_REQ = 204 | CONF_EVT_TYPE_BASE; // 4300
export const WS_CONF_START_BO_BROADCAST_VOICE_INDICATION =
  63 | CONF_EVT_TYPE_BASE | 0x0f00; // 7999

// audio event request/response
export const WS_AUDIO_MUTE_REQ = 1 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_MUTE_RES = 2 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_DROP_REQ = 3 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_DROP_RES = 4 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_DIALOUT_REQ = 5 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_DIALOUT_RES = 6 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_CANCEL_DIALOUT_REQ = 7 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_CANCEL_DIALOUT_RES = 8 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_MUTEALL_REQ = 9 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_MUTEALL_RES = 10 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_ALLOW_TALK_REQ = 12 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_ALLOW_TALK_RES = 13 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_SET_PARTICIPANT_ACTIVE_LANGUAGE =
  14 | AUDIO_EVT_TYPE_BASE;
export const WS_AUDIO_PARTICIPANT_LANGUAGE_CHANGE_RES =
  15 | AUDIO_EVT_TYPE_BASE;
// conf event notification
export const WS_CONF_ROSTER_INDICATION = 1 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_ATTRIBUTE_INDICATION = 2 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_NET_LTT_CAP_STATUS_INDICATION = 8014;

export const WS_CONF_END_INDICATION = 3 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_HOST_CHANGE_INDICATION = 4 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_COHOST_CHANGE_INDICATION = 5 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_HOLD_CHANGE_INDICATION = 6 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_CLOSED_CAPTION_INDICATION =
  7 | CONF_EVT_TYPE_BASE | 0x0f00; // 7943
export const WS_CONF_CHAT_INDICATION = 8 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_OPTION_INDICATION = 9 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_KV_UPDATE_INDICATION = 10 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_CONF_LOCAL_RECORD_INDICATION = 11 | CONF_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_VOIP_JOIN_CHANNEL_REQ = 11 | AUDIO_EVT_TYPE_BASE; // voip
// export const WS_CONF_BO_TOKEN_INDICATION = 12 | CONF_EVT_TYPE_BASE | 0x0f00; // 7948
export const WS_CONF_BO_COMMAND_INDICATION = 13 | CONF_EVT_TYPE_BASE | 0x0f00; // 7949
export const WS_CONF_BO_ATTRIBUTE_INDICATION = 14 | CONF_EVT_TYPE_BASE | 0x0f00; // 7950
export const WS_CONF_ADMIT_ALL_SILENT_USERS_INDICATION =
  15 | CONF_EVT_TYPE_BASE | 0x0f00; // 7951
export const WS_CONF_BIND_UNBIND_INDICATION = 16 | CONF_EVT_TYPE_BASE | 0x0f00; // 7952
export const WS_CONF_UPDATE_MEETING_TOPIC_INDICATION =
  17 | CONF_EVT_TYPE_BASE | 0x0f00; // 7953
export const WS_CONF_DC_REGION_INDICATION = 18 | CONF_EVT_TYPE_BASE | 0x0f00; // 7954
export const WS_CONF_CAN_ADMIT_WHEN_NOHOST_PRESENT_INDICATION =
  19 | CONF_EVT_TYPE_BASE | 0x0f00; // 7955

export const WS_CONF_GROUP_LAYOUT_INDICATION = 22 | CONF_EVT_TYPE_BASE | 0x0f00; // 7958

// audio event notification
export const WS_AUDIO_ASN_INDICATION = 1 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_MUTE_INDICATION = 2 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_SSRC_INDICATION = 3 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_ALLOW_TALK_INDICATION = 4 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_SSRC_ASK_UNMUTE_INDICATION =
  5 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_WEBINAR_VIEW_ONLY_TELEPHONY_INDICATION =
  6 | AUDIO_EVT_TYPE_BASE | 0x0f00;
export const WS_AUDIO_ENCRYPT_KEY_INDICATION = 7 | AUDIO_EVT_TYPE_BASE | 0x0f00; // 12039
export const WS_AUDIO_FEATURE_INDICATION = 8 | AUDIO_EVT_TYPE_BASE | 0x0f00; //12040 silk

// video notificaiton;
export const WS_VIDEO_ACTIVE_INDICATION = 1 | VIDEO_EVT_TYPE_BASE | 0x0f00; // 16129
export const WS_VIDEO_SSRC_INDICATION = 3 | VIDEO_EVT_TYPE_BASE | 0x0f00;
export const WS_VIDEO_MUTE_INDICATION = 5 | VIDEO_EVT_TYPE_BASE | 0x0f00;
export const WS_VIDEO_LEADERSHIP_INDICATION = 7 | VIDEO_EVT_TYPE_BASE | 0x0f00;
export const WS_VIDEO_HOST_MUTE_ALL_VIDEO_INDICATION =
  9 | VIDEO_EVT_TYPE_BASE | 0x0f00;
export const WS_VIDEO_ENCRYPT_KEY_INDICATION =
  10 | VIDEO_EVT_TYPE_BASE | 0x0f00; // 16138
// video;
export const WS_VIDEO_SUBSCRIBE_REQ = 1 | VIDEO_EVT_TYPE_BASE;
export const WS_VIDEO_UNSUBSCRIBE_REQ = 3 | VIDEO_EVT_TYPE_BASE;
export const WS_VIDEO_KEY_FRAME_REQ = 5 | VIDEO_EVT_TYPE_BASE; // WCL KEY FRAME REQUEST
export const WS_VIDEO_NETWORK_FEEDBACK = 7 | VIDEO_EVT_TYPE_BASE; // WCL KEY FRAME REQUEST
export const WS_VIDEO_MUTE_VIDEO_REQ = 9 | VIDEO_EVT_TYPE_BASE;
export const WS_VIDEO_SPOTLIGHT_VIDEO_REQ = 11 | VIDEO_EVT_TYPE_BASE;

// sharing notification
export const WS_SHARING_PAUSE_REQ = 1 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_RESUME_REQ = 3 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_STATUS_INDICATION = 1 | SHARING_EVT_TYPE_BASE | 0x0f00;
export const WS_SHARING_SIZE_CHANGE_INDICATION =
  2 | SHARING_EVT_TYPE_BASE | 0x0f00;
export const WS_SHARING_ENCRYPT_KEY_INDICATION =
  10 | SHARING_EVT_TYPE_BASE | 0x0f00; // 20234
export const WS_SHARING_RECEIVING_CHL_READY_INDICATION =
  11 | SHARING_EVT_TYPE_BASE | 0x0f00; // 20235
export const WS_SHARING_RECEIVING_CHL_CLOSE_INDICATION =
  12 | SHARING_EVT_TYPE_BASE | 0x0f00; // 20236
// Webinar QA RWG Request Type
export const WS_CONF_ALLOW_ANONYMOUS_QUESTION_REQ = 59 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_VIEW_ALL_QUESTION_REQ = 61 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_UPVOTE_QUESTION_REQ = 63 | CONF_EVT_TYPE_BASE;
export const WS_CONF_ALLOW_COMMENT_QUESTION_REQ = 65 | CONF_EVT_TYPE_BASE;
export const WS_SHARING_REMOTE_CONTROL_REQ = 5 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_REMOTE_CONTROL_INDICATION = 7 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_REMOTE_CONTROLLER_GRAB = 9 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_REMOTE_CONTROLLER_GRAB_INDICATION =
  11 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_SUBSCRIBE_REQ = 31 | SHARING_EVT_TYPE_BASE;
export const WS_SHARING_UNSUBSCRIBE_REQ = 33 | SHARING_EVT_TYPE_BASE;

// This is the event that we get when someone tries to promote current user to panelist
export const WS_CONF_PROMOTE_CONSENT_RES = 7963;
// This is the even that we send to respond to a webinar promotion to panelist
export const WS_CONF_PROMOTE__CONSENT_REQ = 4254;

// for collect time spend between RWC and RWG
export const WS_MEETING_RWG_CONNECT_TIME = 4167;
export const EVT_TYPE_WS_VIDEO_DATACHANNEL_ANSWER = 24322;
export const DATA_CHANNEL_SEND_OFFER_TO_RWG = 24321;

//
export const WS_CONF_FOLLOW_HOST_REQ = 4223;
export const WS_CONF_DRAG_LAYOUT_INDICATION = 7957;
export const WS_CONF_SET_DRAG_LAYOUT = 4218;

// live transcription
export const WS_CONF_LIVE_TRANSCRIPTION_ON_OFF_REQ = 131 | CONF_EVT_TYPE_BASE; // 4227
export const WS_CONF_LIVE_TRANSCRIPTION_ON_OFF_RES = 132 | CONF_EVT_TYPE_BASE; // 4228
export const WS_CONF_LIVE_TRANSCRIPTION_STATUS_INDICATION =
  23 | CONF_EVT_TYPE_BASE | 0x0f00; // 7959

// Polling

export const WS_CONF_POLLING_REQ = 4165;
export const WS_CONF_POLLING_USER_ACTION_REQ = 128 | CONF_EVT_TYPE_BASE; // 4224
export const WS_CONF_POLLING_USER_ACTION_ERROR = 129 | CONF_EVT_TYPE_BASE; // 4225
export const WS_CONF_POLLING_SET_POLLING_TOKEN = 130 | CONF_EVT_TYPE_BASE; // 4226

export const WS_CONF_ATTENDEE_VIDEO_CONTROL_MODE_CHANGE_REQ =
  139 | CONF_EVT_TYPE_BASE; // 4235
export const WS_CONF_ATTENDEE_VIDEO_LAYOUT_MODE_CHANGE_REQ =
  140 | CONF_EVT_TYPE_BASE; // 4236
//
export const WS_CONF_UPDATE_ZAK_REQ = 143 | CONF_EVT_TYPE_BASE; // 4239

// #region attention mode
export const WS_CONF_VIDEO_ATTENTION_MODE_LIST =
  30 | CONF_EVT_TYPE_BASE | 0x0f00; // 7966 host,coHost get whitelist
export const WS_CONF_VIDEO_ATTENTION_MODE_SWITCH =
  31 | CONF_EVT_TYPE_BASE | 0x0f00; // 7967 host,cohost enable attention mode
export const WS_CONF_SHARE_ATTENTION_MODE_SET_TYPE_REQ =
  38 | CONF_EVT_TYPE_BASE | 0x0f00; // 7974 change share permission
export const WS_CONF_SHARE_ATTENTION_MODE_LIST =
  37 | CONF_EVT_TYPE_BASE | 0x0f00; // 7973 receive share white list
export const WS_CONF_MMR_SUPPORT_MEETING_ATTENTION_MODE_INDICATION =
  36 | CONF_EVT_TYPE_BASE | 0x0f00; // 7972
// #endregion
// sub and unsub will through mediaSDK for both wasm and webrtc
// export const WS_VIDEO_MULTI_SUBSCRIBE_REQ = 12303;
// export const WS_VIDEO_MULTI_UNSUBSCRIBE_REQ = 12305;
export const WS_CONF_TURN_ON_OFF_WATERMARK_REQ = 175 | CONF_EVT_TYPE_BASE; // 4271
export const WS_VIDEO_ACTIVE_SOURCE_INDICATION =
  11 | VIDEO_EVT_TYPE_BASE | 0x0f00; // 16139 video talking
// network quality
export const WS_CONF_BROADCAST_USER_NETWORK_QUALITY_REQ =
  235 | CONF_EVT_TYPE_BASE; // 4331
export const WS_CONF_NOTIFY_USER_NETWORK_QUALITY_INDICATION =
  69 | CONF_EVT_TYPE_BASE | 0x0f00; // 8005

export const WS_CONF_CHAT_FILE_TRANSFER_REQ = 211 | CONF_EVT_TYPE_BASE; // 4307;

export const WS_CONF_CHAT_FILE_INDICATION = 212 | CONF_EVT_TYPE_BASE; // 4308;

export const WS_CONF_PREVIEW_INFO_RES = 4130;

export const WS_CONF_LAUNCH_PARAMS_REQ = 4301;

export const WS_CONF_CHAT_DISABLED_BY_SERVER_INDICATION =
  7992 | CONF_EVT_TYPE_BASE; // 7992

export const WS_CONF_MEDIA_SESSION_READY_INDICATION =
  60 | CONF_EVT_TYPE_BASE | 0x0f00; // 7996

// new ltt
export const WS_CONF_NEW_LTT_UI_ENABLE_REQ = 4285;
export const WS_CONF_NEW_LTT_STATUS = 7982;
export const WS_CONF_NEW_LTT_LAN_SUPPORT_INDICATION = 7984;
export const WS_CONF_NEW_LTT_SUB_TRANSCRIPTION_INDICATION = 7985;
export const WS_CONF_NEW_LTT_SET_MEETING_LANG_REQ = 4287;
export const WS_CONF_NEW_LTT_MEETING_SPEAK_LANGUAGE_CHANGE_INDICATION = 7986;
export const WS_CONF_NEW_LTT_OP_REQ = 4305;
export const WS_CONF_NEW_LTT_TEXT_DATA_INDICATION = 7983;
export const WS_CONF_NEW_LTT_SEND_MCAP_REQ = 4289;
export const WS_CONF_NEW_LTT_OP_MCAP_REQ = 4291;
export const WS_CONF_NEW_LTT_CAPTION_DISABLED = 8011;

// reaction
export const WS_CONF_RECEIVER_WEBINAR_EMOJI_REACTION_INDICATION =
  45 | CONF_EVT_TYPE_BASE | 0x0f00; // 7981

// test result
export const WS_CONF_SEND_DTRESULT_REQ = 249 | CONF_EVT_TYPE_BASE; //4345

export const WS_CONF_MSDK_BYPASS_MSG = 4366;
