export const PollingStatus = {
  ZMPollUIStatus_No_Poll: 0,

  ZMPollUIStatus_Not_Start: 1, // default status.

  ZMPollUIStatus_Starting_Poll: 2, // sent “start” to web server, wait for it response.

  ZMPollUIStatus_In_Progress: 3, // server respond, host change to this status.

  ZMPollUIStatus_Doing_Poll: 4, // attendee receive “start” from xmpp, change to this status.

  ZMPollUIStatus_Submitting_Poll: 5, // sent “submit” to web server, wait for it response.

  ZMPollUIStatus_Submitted_Poll: 6, // web server response submit ok.

  ZMPollUIStatus_Starting_Share: 7, // sent “share” to web server, wait for it response.

  ZMPollUIStatus_Sharing_Results: 8, // host share action ok.

  ZMPollUIStatus_Viewing_Results: 9, // attendee view share results.

  ZMPollUIStatus_Stopping_Poll: 10, // host sent ”close”, Next status is ZMPollUIStatus_Closed

  ZMPollUIStatus_Stopping_Share: 11, // host sent “stopshare”, Next status is ZMPollUIStatus_Closed.

  ZMPollUIStatus_Closed: 12,

  ZMPollUIStatus_Relaunching_Poll: 13, // host send “reopen”,the same as starting_poll.
};

export const PollItemState = {
  ZoomPollState_NotStart_unLaunch: 0, // unLaunch

  ZoomPollState_Open_launching: 1, // launching

  ZoomPollState_Closed_launched: 2, // launched

  ZoomPollState_ShareResult_sharing: 3, // sharing
};

export const ShowType = {
  pollList: 'pollList', // coOrHost only

  preview: 'preview', // coOrHost only

  sharing: 'sharing',

  launching: 'launching',

  launched: 'launched',
};

export const QuestionTypeString = {
  0: 'single',
  1: 'multiple',
};

export const QuestionTypeCode = {
  single: 0,
  multiple: 1,
};

export const PollingActionTypes = {
  start: 'start',
  close: 'close',
  reopen: 'reopen',
  stopShareResult: 'stopshare',
  startShareResult: 'share',
  submit: 'submit',
};
