import { iText } from '../../global/util';

// common
export const DIALOG_CANCEL = iText('Cancel', 'apac.dialog.btn_cancle');
export const DIALOG_YES = iText('Yes', 'apac.wc_yes');
export const DIALOG_NO = iText('No', 'apac.wc_no');
export const DIALOG_SAVE = iText('Save', 'apac.dialog.btn_save');
export const DIALOG_RETRY = iText('Retry', 'apac.dialog.btn_retry');
export const DIALOG_EXIT = iText('Exit', 'apac.dialog.btn_exit');
export const DIALOG_OK = iText('OK', 'apac.dialog.ok');
export const DIALOG_CREATE = iText('Create', 'apac.dialog.btn_create');
export const DIALOG_CLOSE = iText('Close', 'apac.wc_chat.close');
export const DIALOG_CONTINUE = iText('Continue', 'apac.wc_continue');
export const DIALOG_LEAVE = iText(
  'Leave',
  'apac.has_been_removed_dialog.ok_text',
);
export const CALL = iText('Call', 'apac.wc_call');
export const DIALOG_CONFIRM_TITLE = iText(
  'Confirmation',
  'apac.wc_dlg_confirm',
);
export const DIALOG_END_WEBINAR = iText(
  'End Webinar',
  'apac.dialog.btn_endconf_webinar',
);
export const DIALOG_END_MEETING = iText(
  'End Meeting',
  'apac.dialog.btn_endconf_meeting',
);
export const DIALOG_LEAVE_WEBINAR = iText(
  'Leave Webinar',
  'apac.dialog.btn_leaveconf_webinar',
);
export const DIALOG_LEAVE_MEETING = iText(
  'Leave Meeting',
  'apac.dialog.btn_leaveconf_meeting',
);
export const DIALOG_MEETING_ALERT = iText(
  'Meeting alert',
  'aoac.wc_video.meeting_alert',
);
export const DIALOG_ERROR_CODE = iText('Error Code:', 'apac.dialog.error_code');
// lock conf
export const LOCK_CONF_WEBINAR_HEADER = iText(
  'Lock Webinar',
  'apac.dialog.lock_webinar',
);
export const LOCK_CONF_WEBINAR_HEADER_DESC1 = iText(
  'Are you sure you want to lock this webinar now?',
  'apac.dialog.lock_webinar_confirm',
);
export const LOCK_CONF_WEBINAR_HEADER_DESC2 = iText(
  'Locking will prevent any other people from joining the webinar.',
  'apac.dialog.webinar_lock_prevent_join',
);
export const LOCK_CONF_MEETING_HEADER = iText(
  'Lock Meeting',
  'apac.dialog.lock_meeting',
);
export const LOCK_CONF_MEETING_HEADER_DESC1 = iText(
  'Are you sure you want to lock this meeting now?',
  'apac.dialog.lock_meeting_confirm',
);
export const LOCK_CONF_MEETING_HEADER_DESC2 = iText(
  'Locking will prevent any other people from joining the meeting.',
  'apac.dialog.meeting_lock_prevent_join',
);
export const LOCK_CONF_LOCK_BTN = iText('Lock', 'apac.toolbar_lock');
export const LOCK_CONF_WARNING = iText(
  'Do not warn me again.',
  'apac.dialog.warning',
);

// unlock conf
export const UNLOCK_CONF_TITLE = iText(
  'New attendees can join this meeting once unlocked.',
  'apac.wc_unlock_meeting_content',
);
export const UNLOCK_MEETING = iText('Unlock Meeting', 'apac.wc_unlock_meeting');
export const UNLOCK_MEETING_CONTENT = iText(
  'The meeting has been locked by the host',
  'apac.wc_unlock_meeting_content2',
);
// hangup
export const HANG_UP_BTN = iText('Hang Up', 'apac.dialog.btn_hangup');
export const HANG_UP_CONFIRM = iText(
  'Are you sure you want to disconnect from audio?',
  'apac.dialog.hangup_confirm',
);
export const HANG_UP_CONFIRM_2 = iText(
  'You will be disconnected from audio and remain as a viewer.',
  'apac.dialog.remain_view',
);

// end conf
export const END_CONF_END_WEBINAR_TITLE = iText(
  'End this webinar',
  'apac.dialog.end_webinar',
);
export const END_CONF_END_WEBINAR_CONFIRM_1 = iText(
  'Are you sure you want to end this webinar now?',
  'apac.dialog.end_webinar_confirm',
);
export const END_CONF_END_WEBINAR_CONFIRM_2 = iText(
  'Everyone on the call will be disconnected and webinar will be over?',
  'apac.dialog.webinar_over',
);
export const END_CONF_LEAVE_WEBINAR_TITLE = iText(
  'Leave this webinar',
  'apac.dialog.leave_webinar',
);
export const END_CONF_LEAVE_WEBINAR_CONFIRM = iText(
  'Are you sure you want to leave this webinar now?',
  'apac.dialog.leave_webinar_confirm',
);
export const END_CONF_END_MEETING_TITLE = iText(
  'End this meeting',
  'apac.dialog.end_meeting',
);
export const END_CONF_END_MEETING_CONFIRM_1 = iText(
  'Are you sure you want to end this meeting now?',
  'apac.dialog.end_meeting_confirm',
);
export const END_CONF_END_MEETING_CONFIRM_2 = iText(
  'Everyone on the call will be disconnected and meeting will be over?',
  'apac.dialog.meeting_over',
);
export const END_CONF_LEAVE_MEETING_TITLE = iText(
  'Leave this meeting',
  'apac.dialog.leave_meeting',
);
export const END_CONF_LEAVE_MEETING_CONFIRM = iText(
  'Are you sure you want to leave this meeting now?',
  'apac.dialog.leave_meeting_confirm',
);

// rename
export const RENAME_TITLE = iText('Rename', 'apac.dialog.rename');
export const RENAME_LABEL_NAME = iText('Name', 'apac.dialog.newname');

// expel
export const EXPEL_CONFIRM = iText(
  'Do you want to remove',
  'apac.wc_remove_user',
);
export const expelAttendee = (name) =>
  iText(
    `Do you want to remove ${name}? Once removed, ${name} will not be able to rejoin the webinar`,
    'apac.wc_remove_attendee',
    [name, name],
  );

// reconnect conf
export const RECONNECT_CONF_TITLE = iText(
  'Something went wrong',
  'apac.dialog.something_wrong',
);
export const RECONNECT_CONF_CONFIRM = iText(
  'The server encountered an internal error and was unable to process your request.',
  'apac.dialog.server_error',
);

// expel info
export const EXPEL_INFO_TITLE = iText(
  'You have been removed',
  'apac.dialog.you_are_removed',
);
export const EXPEL_INFO_WEBINAR_DESC = iText(
  'You have been removed from this webinar by the host.',
  'apac.dialog.webinar_removed_by_host',
);
export const EXPEL_INFO_MEETING_DESC = iText(
  'You have been removed from this meeting by the host.',
  'apac.dialog.meeting_removed_by_host',
);

// conf ended
export const CONF_ENDED_WEBINAR_TITLE = iText(
  'The webinar has been ended',
  'apac.dialog.webinar_ended',
);
export const CONF_ENDED_WEBINAR_DESC = iText(
  'This webinar has been ended by host.',
  'apac.dialog.host_end_webinar',
);
export const CONF_ENDED_MEETING_TITLE = iText(
  'The meeting has been ended',
  'apac.dialog.meeting_ended',
);
export const CONF_ENDED_MEETING_DESC = iText(
  'This meeting has been ended by host.',
  'apac.dialog.host_end_meeting',
);

// conf full
export const CONF_FULL_WEBINAR_TITLE = iText(
  'The webinar is at capacity',
  'apac.dialog.webinar_capacity_reached_title',
);
export const CONF_FULL_WEBINAR_DESC = iText(
  'This webinar has reached the maximun number of participants. Please try again later.',
  'apac.dialog.webinar_capacity_reached_content1',
);
export const CONF_FULL_WEBINAR_DESC2 = iText(
  'This webinar has reached the maximun number of participants.You can watch a live stream of this webinar from your browser.',
  'apac.dialog.webinar_capacity_reached_content2',
);
export const CONF_FULL_WEBINAR_BTN = iText(
  'Watch Live Stream',
  'apac.dialog.webinar_capacity_reached_btn',
);

export const CONF_FULL_MEETING_TITLE = iText(
  'Meeting capacity has been reached',
  'apac.dialog.meeting_capacity_reached',
);
export const CONF_FULL_MEETING_DESC = iText(
  'You cannot join this meeting because it has reached the maximum number of participant allowed.',
  'apac.dialog.meeting_cannot_join_reached_capacity',
);

// conf locked
export const CONF_LOCKED_WEBINAR_TITLE = iText(
  'The webinar has been locked',
  'apac.dialog.webinar_locked',
);
export const CONF_LOCKED_WEBINAR_DESC = iText(
  'You cannot join this webinar now because the host locked it.',
  'apac.dialog.webinar_cannot_join_locked',
);
export const CONF_LOCKED_MEETING_TITLE = iText(
  'The meeting has been locked',
  'apac.dialog.meeting_locked',
);
export const CONF_LOCKED_MEETING_DESC = iText(
  'You cannot join this meeting now because the host locked it.',
  'apac.dialog.meeting_cannot_join_locked',
);

// about video
export const ABOUT_VIDEO_TITLE = iText('About video', 'apac.wc_about_video');
export const ABOUT_VIDEO_DESC = iText(
  'Zoom does not currently support sharing your video from a browser.',
  '',
);

// join voip timeout
export const JOIN_VOIP_TIMEOUT_TITLE = iText(
  'Unable to use computer audio',
  'apac.wc_unable_pc_audio',
);
export const JOIN_VOIP_TIMEOUT_DESC = iText(
  'Zoom is temporarily unable to use your computer\'s devices to connect to the meeting audio. Click "Join Audio by Computer" to try again.',
  'apac.wc_join_audio_timeout',
);

// make host
export const MAKE_HOST_CONFIRM = iText(
  'Do you want to change the host to',
  'apac.wc_make_host_text',
);

// make co-host
export const tMakeCoHost = (userName) =>
  iText(
    `Do you want to make ${userName} the co-host of this meeting?`,
    'apac.wc_make_co_host_text',
    userName,
  );
// mute video
export const MUTE_VIDEO_DESC_START = iText(
  'The host has asked you to start your video',
  'apac.wc_video.model_start',
);
export const MUTE_VIDEO_DESC_STOP = iText(
  'You cannot start your video because the host has stopped it',
  'apac.wc_video.model_stop',
);
export const MUTE_VIDEO_BTN_START = iText(
  'Start My Video',
  'apac.wc_video.start_my_video',
);
export const MUTE_VIDEO_BTN_LATER = iText('Later', 'apac.wc_video.later');

// unmute audio
export const UNMUTE_AUDIO_CONFIRM = iText(
  'The host is not allowing participants to unmute themselves',
  'apac.wc_audio.cannot_unmute',
);

// request control
export function requestControlText1(userName) {
  return iText(
    `You are about to request remote control of ${userName}'s shared content`,
    'apac.wc_remote_control.text1',
    userName,
  );
}
export function requestControlText2(userName) {
  return iText(
    `Select Request and wait for ${userName} approval.`,
    'apac.wc_remote_control.text2',
    userName,
  );
}
export const REQUEST_CONTROL_TEXT_3 = iText(
  "Select Cancel if you don't want to send a request.",
  'apac.wc_remote_control.text3',
);
export function requestControlText4(userName) {
  return iText(
    `${userName} declined your request`,
    'apac.wc_remote_control.text4',
  );
}
export const REQUEST_CONTROL_TEXT_5 = iText(
  'Select Request to try again',
  'apac.wc_remote_control.text5',
);
export const REQUEST_CONTROL_BTN = iText(
  'Request',
  'apac.wc_remote_control.request',
);

// connect error
export const CONNECT_ERROR_MEETING_TITLE = iText(
  'Joining meeting timeout',
  'apac.wc_meeting_timeout_title',
);
export const CONNECT_ERROR_WEBINAR_TITLE = iText(
  'Joining webinar timeout',
  'apac.wc_webianr_timeout_title',
);
export const CONNECT_ERROR_MEETING_CONTENT = iText(
  'Your connection has timed out and you cannot join the meeting. Verify your network connectivity and try again.',
  'apac.wc_meeting_timeout',
);
export const CONNECT_ERROR_WEBINAR_CONTENT = iText(
  'Your connection has timed out and you cannot join the webinar. Verify your network connectivity and try again.',
  'apac.wc_webinar_timeout',
);

// asign new cc type
export function asignCCConfirm([target1, target2]) {
  return iText(
    `${target1} is currently assigned to type Closed Caption, do you want to assign ${target2} to type instead?`,
    'apac.wc_assign_cc_confirm',
    [target1, target2],
  );
}

// mute all
export const MUTE_ALL_TITLE = iText('Mute All', 'apac.toolbar_muteall');
export const MUTE_ALL_TITLE_NEW = iText(
  'Mute Current and New Participants',
  'apac.toolbar_muteall_new',
);
export const MUTE_ALL_CONTENT = iText(
  'Mute all current and new participants',
  'apac.wc_audio.mute_all_confirm',
);
export const MUTE_ALL_CHECKBOX_TEXT = iText(
  'Allow participants to unmute themselves',
  'apac.wc_allow_unmute',
);

// stop recording
export const STOP_RECORDING_TITLE = iText(
  'Stop Cloud Recording?',
  'apac.wc_stop_recording_title2',
);
export const STOP_RECORDING_CONTENT = iText(
  'After stopping, you’ll receive an email notification when the cloud recording is ready.',
  'apac.wc_stop_recording_content2',
);

// mute on entry
export const MUTE_ON_ENTRY_TITLE = iText(
  'Mute Participants Upon Entry',
  'apac.wc_mute_on_entry_title',
);
export const MUTE_ON_ENTRY_TITLE_NEW = iText(
  'Mute New Participants Upon Entry',
  'apac.wc_mute_on_entry_title_new',
);
export const MUTE_ON_ENTRY_CONTENT = iText(
  'All the new participants will be muted',
  'apac.wc_mute_on_entry_content',
);
export const MUTE_ON_ENTRY_CHECKBOX_TEXT = MUTE_ALL_CHECKBOX_TEXT;
// promote attendee  to panelist
export const PROMOTE_TO_PANELIST_BTN = iText(
  'Promote to panelist',
  'apac.wc_webinar.promote_to_panelist',
);
export function allowToTalkPromote(target) {
  return iText(
    `Allow to talk is not availiable because ${target} is using an older version of Zoom. Choose Promote Panelist to allow ${target} to talk.`,
    'apac.wc_allow_to_talk_disabled',
    [target, target],
  );
}

// unmute by host
export const UNMUTE_BY_HOST_TITLE = iText(
  'The host would like you to unmute',
  'apac.wc_audio.unmute_by_host_title',
);
export const TALK_BY_HOST_TITLE = iText(
  'The host would like you to speak',
  'apac.wc_audio.unmute_by_host_content_1',
);
export const MWCL_DOESNT_SUPPORT_UNMUTE = iText(
  'To participate in this Webinar via Audio, please rejoin from a desktop or a native client.',
  'apac.mwcl_doesnt_support_unmute',
);
export const UNMUTE_BY_HOST_CONTENT = iText(
  'The host would like you to unmute',
  'apac.wc_audio.unmuted_by_host_content',
);
export const UNMUTE_BY_HOST_BTN1 = iText(
  'Stay muted',
  'apac.wc_unmute_by_host_btn1',
);
export const UNMUTE_BY_HOST_BTN2 = iText(
  'Switch to Computer Audio to unmute',
  'apac.wc_unmute_by_host_btn2',
);
export const UNMUTE_BY_HOST_BTN3 = iText(
  'Unmute myself',
  'apac.wc_unmute_by_host_btn3',
);

// unmuted by host for being spotlighted
export const UNMUTE_BY_HOST_CONTENT2 = iText(
  'The host has spotlighted your video for everyone. Would you like to unmute your microphone to speak?',
  'apac.wc_unmute_by_host_content2',
);
export const UNMUTE_BY_HOST_CONTENT3 = iText(
  'The host has spotlighted your video for everyone. Would you like to join audio to speak?',
  'apac.wc_unmute_by_host_content3',
);

// hotfix 20230811 webinar allow to talk
export const TALK_BY_HOST_WEBINAR_CONTENT = iText(
  'If you choose to unmute, others in the webinar will be able to hear you. If the host or panelists decide to record, livestream, or archive the webinar after you unmute, your voice will be included. If the webinar is recorded it may be shared with Smart Recording which uses AI technology, which may include third-party models. Zoom does not use any audio, video, chat, screen sharing, attachments or other communications-like content (such as poll results, whiteboard and reactions) to train Zoom’s or third-party AI models.',
  'apac.wc_audio.unmute_by_host_content_6_2',
);
// hotfix 20230811 meeting allow to talk
export const TALK_BY_HOST_MEETING_CONTENT = iText(
  'If you choose to unmute, others in the meeting will be able to hear you. If the host or participants decide to record, livestream, or archive the meeting after you unmute, your voice will be included. If the meeting is recorded it may be shared with Smart Recording which uses AI technology, which may include third-party models. Zoom does not use any audio, video, chat, screen sharing, attachments or other communications-like content (such as poll results, whiteboard and reactions) to train Zoom’s or third-party AI models.',
  'apac.wc_audio.unmute_by_host_content_5_2',
);
export const UNMUTE_BY_HOST_CONTENT4 = iText(
  'When you unmute your microphone, your computer sound will stop.',
  'apac.wc_audio.unmute_by_host_content_4',
);

export const UNMUTE_BY_HOST_BTN4 = iText(
  'Join Audio',
  'apac.toolbar_join_audio',
);
export const UNMUTE_BY_HOST_BTN5 = iText('Later', 'apac.wc_video.later');

// simulive webinar
export const SIMULIVE_AUTO_REPLY_TITLE = iText(
  'Q&A auto reply is On',
  'apac.wc_simulive_auto_reply_title',
);
export const SIMULIVE_AUTO_REPLY_TITLE2 = iText(
  'Q&A auto reply is Off',
  'apac.wc_simulive_auto_reply_title2',
);
export const SIMULIVE_AUTO_REPLY_CONTENT = iText(
  'This simulive webinar has enabled Q&A auto reply. You can view the Q&A report and reply later after the webinar.',
  'apac.wc_simulive_auto_reply_content',
);
export const SIMULIVE_AUTO_REPLY_CONTENT2 = iText(
  'This simulive webinar does not enable Q&A auto reply. You will receive online attendee’s questions and you can reply to them manually.',
  'apac.wc_simulive_auto_reply_content2',
);
export const SIMULIVE_AUTO_REPLY_CONTENT3 = iText(
  'This simulive webinar has enabled Q&A auto reply. Do you want to reply by yourself?',
  'apac.wc_simulive_auto_reply_content3',
);
export const SIMULIVE_AUTO_REPLY_BTN1 = iText(
  'Continue Auto Reply',
  'apac.wc_simulive_auto_reply_btn1',
);
export const SIMULIVE_AUTO_REPLY_BTN2 = iText(
  'Reply by Myself',
  'apac.wc_simulive_auto_reply_btn2',
);

// multiple sharing
export const SWITCH_TO_SINGLE_SHARE_TITLE = iText(
  'Switch to single participant share',
  'apac.wc_switch_to_single_title',
);
export const SWITCH_TO_SINGLE_SHARE_CONTENT = iText(
  'More than 2 participants are sharing, switch will stop all sharing, do you want to continue?',
  'apac.wc_switch_to_single_content',
);
export const SHARING_SETTING_TITLE = iText(
  'Advanced Sharing Options',
  'apac.wc_sharing_setting_title',
);
export const SHARING_SETTING_LOCK_CONTENT = iText(
  'You cannot modify the advanced sharing options as these settings are locked by your admin.',
  'apac.wc_sharing_setting_lock',
);

// separate audio
export const SEPARATE_AUDIO_BTN = iText(
  'Separate',
  'apac.wc_separate_audio_btn',
);
export const SEPARATE_AUDIO_TITLE = iText(
  'Separate audio',
  'apac.wc_separate_audio_title',
);
export function separateAudioContent(name) {
  return iText(
    `Separate audio from ${name}'s video?`,
    'apac.wc_separate_audio_content',
    [name],
  );
}

// can not join meeting dialog
export const CANNOT_JOIN_TITLE = iText(
  'Unable to join this meeting',
  'apac.wc_cannot_join_title',
);
export const CANNOT_JOIN_CONTENT = iText(
  'Your admin has restricted communication between certain groups and users.',
  'apac.wc_cannot_join_content',
);

export const CANNOT_JOIN_CONTENT2 = iText(
  // eslint-disable-next-line prettier/prettier
  'You\'ve already joined this meeting on another device.',
  'apac.wc_cannot_join_content2',
);

// can not share dialog
export const CANNOT_SHARE_TITLE = iText(
  'Unable to share screen',
  'apac.wc_cannot_share_screen',
);
export const CANNOT_SHARE_CONTENT = iText(
  'Your admin has disabled screen sharing between certain groups and users.',
  'apac.wc_cannot_share_screen_content',
);
export const CANNOT_RECORDING_TITLE = iText(
  'Unable to record this meeting',
  'apac.wc_cannot_recording',
);
export const CANNOT_RECORDING_CONTENT = iText(
  'Your admin has disabled recording in meetings with certain groups and users.',
  'apac.wc_cannot_recording_content',
);

export const PREVENT_SHARE_ACCESS = iText(
  'Please grant browser access to Screen Recording.',
  'apac.wc_prevent_access_share',
);
export const LEARN_MORE = iText('Learn more', 'apac.wc_learn_more');
export const PREVENT_MICROPHONE_ACCESS = iText(
  'Your browser is preventing access to your microphone.',
  'apac.wc_prevent_access_mic',
);
export const LEARN = iText('Learn', 'apac.wc_learn');
export const HOW_TO_ALLOW = iText(
  'how to allow access to your microphone.',
  'apac.wc_how_to_allow',
);
export const DIALOG_END_MEETING_FOR_ALL = iText(
  'End Meeting for All',
  'apac.wc_btn_endconf_meeting_all',
);

export const DIALOG_FOR_CANCEL_PIN = iText(
  'Remove all pinned videos',
  'apac.wc_remove_all_pin_title',
);
export const toCancelAllPin = (userName) =>
  iText(
    `Spotlighting ${userName} for everyone will remove all pinned videos, Do you want to continue?`,
    'apac.wc_wc_remove_all_pin_desc',
    userName,
  );

export const DIALOG_FOR_CANCEL_SPOTLIGHT = iText(
  'Remove all spotlighted videos',
  'apac.wc_remove_all_spotlight_title',
);
export const toCancelAllSpotlight = (userName) =>
  iText(
    `Pinning ${userName} for everyone will remove all spotlighted videos, Do you want to continue?`,
    'apac.wc_wc_remove_all_spotlight_desc',
    userName,
  );
export const UNMUTE_NOT_YET_JOIN_2 = iText(
  'Clicking "Unmute" will connect computer audio and unmute you.',
  'apac.wc_unmute_dialog_tip_2',
);
export const UNMUTE_BY_HOST_STAY_MUTED = iText(
  'Stay Muted',
  'apac.wc_unmute_by_host_stay_muted',
);
export function unmuteDialogByPhone(participantId) {
  return iText(
    `Already joined by phone? Enter #${participantId}# on your phone, then click Unmute.`,
    'apac.wc_unmute_dialog_tip2',
    [participantId],
  );
}
export const UNMUTE_BY_HOST_NOT_SUPPORT_UNMUTE = iText(
  'Your browser does not support computer audio, please join audio using other browsers such as Chrome, Firefox and Chromium Edge. ',
  'apac.wc_unmute_by_host_not_support_unmute',
);

export function removedSuccessTipText(userName) {
  return iText(`"${userName}" has been removed`, 'apac.wc_removed_tip', [
    userName,
  ]);
}
export const CANNOT_MOVE_VIDEO = iText(
  'Cannot Move Video',
  'apac.video_cannot_move_video',
);

export const CANNOT_MOVE_VIDEO_HIDE_NON_VIDEO = iText(
  'You cannot move a video while non-video participants are hidden',
  'apac.video_cannot_move_video_hide_non_video',
);

export const CANNOT_MOVE_VIDEO_HIDE_SELF_VIDEO = iText(
  'You cannot move a video while your video is hidden',
  'apac.video_cannot_move_video_hide_self_video',
);

export const CANNOT_MOVE_VIDEO_FOLLOW_HOST_ORDER = iText(
  "You cannot move a video because you are following the host's video order",
  'apac.video_cannot_move_video_follow_host_order',
);

export const CANNOT_MOVE_VIDEO_HAS_PIN = iText(
  'You cannot move a video while people are pinned',
  'apac.video_cannot_move_video_has_pin',
);

export const CANNOT_MOVE_VIDEO_HAS_PIN_NEW = iText(
  'You cannot move a video while people are pinned',
  'apac.video_cannot_move_video_has_pin_new',
);

export const CANNOT_MOVE_VIDEO_HAS_RAISE_HAND = iText(
  'You cannot move a video while someone has their hand raised',
  'apac.video_cannot_move_video_has_raise_hand',
);

export const CANNOT_MOVE_VIDEO_HAS_SPOTLIGHT = iText(
  'You cannot move a video while people are spotlighted',
  'apac.video_cannot_move_video_has_spotlight',
);

export const CLAIM_HOST = iText('Claim Host', 'apac.dialog.claim_host');

export const CLAIM_HOST_TITLE = iText(
  'Enter the Host Key to claim host role',
  'apac.dialog.claim_host_title',
);

export const CLAIM_HOST_TITLE_NEW = iText(
  'Enter host key to claim host',
  'apac.dialog.claim_host_title_new',
);

export const CLAIM_HOST_ERROR_TIPS = iText(
  'The Host Key you entered is invalid',
  'apac.dialog.claim_host_error_tips',
);

export const CLAIM_HOST_ERROR_TIPS_NEW = iText(
  'The host key you entered is not valid',
  'apac.dialog.claim_host_error_tips_new',
);

export const CLAIM_HOST_HELPFUL_TIPS = iText(
  'Host key is a 6-10 digit number',
  'apac.dialog.claim_host_helpful_tips',
);

export const CLAIM_HOST_HELPFUL_TIPS_NEW = iText(
  'Enter 6-digit host key',
  'apac.dialog.claim_host_helpful_tips_new',
);

export const CLAIM_HOST_VERIFY = iText(
  'Verifying Host Key...',
  'apac.dialog.claim_host_verify',
);

export const NETWORK_ERROR = iText(
  'Network error,please try again.',
  'apac.dialog.network_error',
);
export const STOP_RECORDING_TOAST = iText(
  'You will get an email notification when the cloud recording is ready',
  'apac.toast_after_stop_recording',
);

export const UPGRAGE_TEXT = iText('Upgrade', 'apac.dialog.upgrade_btn');
export const UPGRAGE_NOW_TEXT = iText(
  'Upgrade Now',
  'apac.dialog.upgrade_now_btn',
);
export const GIFT_TITLE_1 = iText(
  'A gift from Zoom',
  'apac.dialog.gift_title1',
);
export const GIFT_CONTENT_1 = iText(
  'Running out of time? We’ve removed the 40-minute time limit on your group meeting.',
  'apac.dialog.gift_content1',
);
export const GIFT_OK_BTN_1 = iText('Love it!', 'apac.dialog.gift_okbtn1');

export const GIFT_TITLE_2 = iText(
  'We are in a giving mood',
  'apac.dialog.gift_title2',
);
export const GIFT_CONTENT_2 = iText(
  'If you schedule now, we’ll remove the 40-minute time limit on your next group meeting, too.',
  'apac.dialog.gift_content2',
);
export const GIFT_OK_BTN_2 = iText('Schedule Now', 'apac.dialog.gift_okbtn2');

export const GIFT_TITLE_3 = iText('Join Zoom Pro', 'apac.dialog.gift_title3');
export const GIFT_CONTENT_3 = iText(
  'Remove the 40-min time limit on all future meetings',
  'apac.dialog.gift_content3',
);

export const GIFT_TITLE_4 = iText(
  'Your free meeting will end in 10 minutes',
  'apac.dialog.gift_title4_new',
);
export const GIFT_CONTENT_4 = iText(
  'Need more time? Zoom Pro provides unlimited meetings and more.',
  'apac.dialog.gift_content4_new',
);

export const GIFT_TITLE_5 = iText(
  'Your free meeting has ended',
  'apac.dialog.gift_title5_new',
);
export const GIFT_CONTENT_5 = iText(
  'Get unlimited meetings and more with Zoom Pro.',
  'apac.dialog.gift_content5_new',
);

export const GIFT_TITLE_6 = iText(
  'This free Zoom meeting has ended',
  'apac.dialog.gift_title6',
);
export const GIFT_CONTENT_6 = iText(
  'Thank you for choosing Zoom!',
  'apac.dialog.gift_content6',
);

export const GIFT_TITLE_7 = iText(
  'Your meeting will end in 10 minutes',
  'apac.dialog.gift_title7',
);
export const GIFT_CONTENT_7 = iText(
  'Need more time? Contact your administrator to upgrade to a plan with unlimited meetings.',
  'apac.dialog.gift_content7_new',
);

export const GIFT_MEETING_TIP_FOR_OTHERS = iText(
  'This meeting has been upgraded',
  'apac.dialog.gift_tip_for_others',
);
export const GIFT_MEETING_TIP_CONTENT_FOR_OTHERS = iText(
  'This meeting has been upgraded by the host and now includes unlimited minutes.',
  'apac.dialog.gift_tip_content_for_others',
);
export const GIFT_MEETING_TIP_CONTENT_FOR_OTHERS2 = iText(
  'This meeting now includes unlimited minutes.',
  'apac.dialog.gift_tip_content_for_others2',
);

export const DLP_SEND_MESSAGE = iText('Send message?', 'apac.dlp.title1');

export const DLP_WARNING = iText(
  'Your message triggers one or more Chat Etiquette policies defined by your account admin, but your admin will not be notified. Are you sure you want to send this message?',
  'apac.dlp.warning',
);

export const DLP_NO_SEND_MESSAGE = iText(
  'Unable to send message',
  'apac.dlp.title2',
);

export const DLP_BLOCK = iText(
  'Your message triggers one or more Chat Etiquette policies defined by your account admin and cannot be sent. Please contact your admin for more information.',
  'apac.dlp.block',
);

export const REPORT_PROBLEM = iText(
  'Report Problem',
  'apac.dialog.report_problem',
);

export const ABOUT = iText('About', 'apac.about');

// export const COMPANY_TEXT = iText(
//   'Zoom Video Communications, Inc.',
//   'apac.about.company',
// );

export const COMPANY_LEGAL_TEXT = iText(
  'Zoom Communications, Inc.',
  'apac.about.company_legal_name',
);

export const ALL_RIGHTS_RESERVED_TEXT = iText(
  'All rights reserved.',
  'apac.about.all_rights_reserved',
);

export const HELP = iText('Help', 'apac.help');

export const SEND = iText('Send', 'apac.dialog.send');

export const PROBLEM = iText('Problem', 'apac.dialog.problem');

export const AUDIO_QUALITY = iText(
  'Audio Quality',
  'pac.dialog.label_audio_quality',
);

export const VIDEO_QUALITY = iText(
  'Video Quality',
  'apac.dialog.label_video_quality',
);

export const DIAL_IN_TITLE = iText('Dial-In', 'apac.dialog.label_dial_in');

export const JOIN_MEETING = iText(
  'Join Meeting',
  'apac.dialog.label_join_meeting',
);

export const OTHERS = iText('Others', 'apac.dialog.label_others');

export const OCCURRENCE_TIME = iText(
  'Occurrence Time',
  'apac.dialog.occurrence_time',
);

export const EXPLAIN_WHAT_HAPPENED = iText(
  'Briefly explain what happened...',
  'apac.dialog.brifely_explain_what_happened',
);

export const INCLUDE_LOG_FILE = iText(
  'Include Log File',
  'apac.dialog.include_log_file_capitalized',
);

export const LOG_FILE_EXPLANATION = iText(
  'This log will include information about your device and this app to address technical issues and to improve our services',
  'apac.dialog.log_file_explanation',
);

export const TICKET_ID_EXPLANATION = iText(
  'By sending this report, you agree to Zoom’s access of the data necessary to respond to this problem, subject to Zoom’s ',
  'apac.dialog.ticket_id_explanation',
);

export const TICKET_ID_TITLE = iText(
  'Ticket ID',
  'apac.dialog.ticket_id_title',
);

export const PRIVACY_STATEMENT = iText(
  'Privacy Statement ',
  'apac.dialog.privacy_statement',
);

export const INPUT_TICKET_ID = iText(
  'Input ticket ID',
  'apac.dialog.nput_ticket_id',
);

export const HAVE_TICKET_ID = iText(
  'I have a ticket ID',
  'apac.dialog.have_a_ticket',
);

export const TRACKING_ID = iText(
  'Tracking ID',
  'apac.dialog.tracking_id_capitalized',
);

export const SEND_REPORT = iText('Send Report', 'apac.wc_security.send_report');

export const THANK_YOU_FOR_REPORT = iText(
  'Thank you for your report!',
  'apac.dialog.thank_you_report',
);

export const UNEXPECTED_ERROR = iText(
  'There was an unexpected error',
  'apac.unexpected_error',
);

export const DISCONNECT_AUDIO = iText(
  'Disconnect Audio',
  'apac.wcm_audio.disconnect_audio',
);

export const STATISTICS = iText(
  'Video Statistics',
  'apac.wcm_settings.statistics',
);

export const frequencyText = iText('Frequency', 'apac.settings.frequency');

export const jitterText = iText('Jitter', 'apac.settings.jitter');

export const latencyText = iText('Latency', 'apac.settings.latency');

export const itemNameText = iText('Item Name', 'apac.settings.item_name');

export const sendText = iText('Send', 'apac.settings.send');

export const receiveText = iText('Receive', 'apac.settings.receive');

export const packetLossText = iText(
  'Packet Loss - Avg(Max)',
  'apac.settings.packet_loss',
);

export const resolutionText = iText('Resolution', 'apac.settings.resolution');

export const framePerSecondText = iText(
  'Frame Per Second',
  'apac.settings.frame_per_second',
);

export const CHAT_DELETED_DLP_CONTENT = iText(
  'Your message has been deleted because it triggers one or more policies defined by your account. Please contact your admin for more information',
  'apac.dlp.chat_deleted_dlp_content',
);

export const MADE_HOST = iText(
  'You have been made the host',
  'apac.dialog.madeHost',
);

export const HOST_NOT_SUPPORTED = iText(
  'Host mode is not supported on mobile web client. If you want to use host features, please sign in using desktop.',
  'apac.dialog.host_not_supported',
);
export const SWITCH_VIEW_MODE_TEXT = iText(
  'You must rejoin the meeting to apply this change',
  'apac.settings.switch_view_mode_text',
);

export const REJOIN_BTN = iText('Rejoin', 'apac.settings.rejoin');

export const TEST_AUDIO = iText('Test Audio', 'apac.dialog.test_audio');
export const STOP = iText('Stop', 'apac.dialog.stop');
export const TEST_SPEAKER = iText('Test Speaker', 'apac.dialog.test_speaker');
export const OUTPUT_LEVEL = iText('Output Level:', 'apac.dialog.output_level');
export const MICROPHONE = iText('Microphone', 'apac.dialog.microphone');
export const INPUT_LEVEL = iText('Input Level:', 'apac.dialog.input_level');
export const SPEAKER = iText('Speaker', 'apac.dialog.speaker');
export const TEST_MIC = iText('Test Mic', 'apac.dialog.test_mic');
export const PLAYING = iText('Playing', 'apac.dialog.playing');
export const badNetworkErrorMessage = iText(
  'Poor internet connection may affect your meeting experience, please close other applications or check your network bandwidth to improve your experience.',
  'apac.settings.badNetworkErrorMessage',
);

export const auto_renderer_type = iText(
  'Auto',
  'apac.settings.auto_renderer_type',
);

export const rendering_method = iText(
  'Rendering Method',
  'apac.settings.video_rendering_method',
);

export const GETTY_DIALOG_WORDING_WEBINAR_1 = iText(
  'Participants with relevant permissions also may save and share the webinar transcript.',
  'apac.getty.content_webinar_1',
);
export const use_webRTC = iText('Use WebRTC', 'apac.settings.use_webRTC');

export const use_webRTC_strategy = iText(
  'Use WebRTC Strategy',
  'apac.settings.use_webRTC_strategy',
);

export const use_webRTC_strategy_enabled = iText(
  'Enabled',
  'apac.settings.use_webRTC_strategy_enabled',
);

export const use_webRTC_strategy_disabeld = iText(
  'Disabled',
  'apac.settings.use_webRTC_strategy_disabled',
);

export const use_webRTC_strategy_auto = iText(
  'Auto',
  'apac.settings.use_webRTC_strategy_auto',
);

export const use_webRTC_strategy_error = iText(
  'WebRTC Strategy Error',
  'apac.settings.use_webRTC_strategy_error',
);

export const use_webRTC_strategy_error_code = iText(
  'Error code',
  'apac.settings.use_webRTC_strategy_error',
);

export const use_webRTC_strategy_error_unknown = iText(
  'Unknown error.',
  'apac.settings.use_webRTC_strategy_error_unknown',
);

export const use_webRTC_strategy_error_invalid_args = iText(
  'Invalid arguments.',
  'apac.settings.use_webRTC_strategy_error_invalid_args',
);

export const use_webRTC_strategy_error_broswer_not_support = iText(
  'Browser not support.',
  'apac.settings.use_webRTC_strategy_error_broswer_not_support',
);

export const use_webRTC_strategy_error_device_on_blacklist = iText(
  'Device is in blacklist.',
  'apac.settings.use_webRTC_strategy_error_device_on_blacklist',
);

export const use_webRTC_strategy_error_invalid_device_info = iText(
  'Invalid device information.',
  'apac.settings.use_webRTC_strategy_error_invalid_device_info',
);

export const use_webRTC_strategy_error_unknown_selection = iText(
  'Unknown selection.',
  'apac.settings.use_webRTC_strategy_error_unknown_selection',
);

export const use_webRTC_strategy_other_ex = iText(
  'Other exception.',
  'apac.settings.use_webRTC_strategy_other_ex',
);
