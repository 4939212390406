/* eslint-disable @babel/new-cap */
import meetingConfig from 'meetingConfig';
import {
  OpFeatureUtility,
  FEATUTRE_FLAG as COMMON_FEATUTRE_FLAG,
} from '@zoom/common-utils';
import {
  isAndroidDevice,
  isMTRAndroid,
  isOnIOS,
  isOpenHarmonyDevice,
  isTeslaMode,
} from './util';

export const FEATUTRE_FLAG = COMMON_FEATUTRE_FLAG;

export const BIT_AND_LOGIC = (opBit, bit) => (opBit & bit) === bit;

export const opFeatureUtility = new OpFeatureUtility(
  meetingConfig.webclientFeatureOptions || '',
);

const getOpFeatureOption = (opBit) =>
  opFeatureUtility.getOpFeatureFlag(opBit, 1, (opBit) =>
    BIT_AND_LOGIC(opBit, 0b1),
  );
const mediaSDKOPFeature = new OpFeatureUtility(
  meetingConfig.mediaFeatureOptions || '',
);
export const opAudioBridgeFlag = mediaSDKOPFeature.getOpFeatureFlagFromRuleMap(
  1,
  2,
  (opBit, targetItem) => BIT_AND_LOGIC(opBit, targetItem.value),
  [
    {
      checked: isOnIOS(),
      value: 0b1,
    },
    {
      checked: isTeslaMode(),
      value: 0b10,
    },
    {
      checked: isAndroidDevice() || isOpenHarmonyDevice(),
      value: 0b100,
    },
    {
      checked: isMTRAndroid(),
      value: 0b1000,
    },
  ],
);

export const isOpenMobileMultiViewFlag = () => {
  const opOpenMobileMultiViewFlag = opFeatureUtility.getOpFeatureFlag(
    8,
    1,
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
  return opOpenMobileMultiViewFlag;
};

export const isEnableMobileLTFlag = () => {
  const opOpenMobileMultiViewFlag = opFeatureUtility.getOpFeatureFlag(
    14,
    1,
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
  return opOpenMobileMultiViewFlag;
};

export const isEnableIosDecode720Flag = () => {
  const isEnableIosDecode720Flag = opFeatureUtility.getOpFeatureFlag(
    15,
    1,
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
  return isEnableIosDecode720Flag;
};

export const isEnableNewAutoJoinFlowFlag = () => {
  const isEnableNewAutoJoinFlowFlag = opFeatureUtility.getOpFeatureFlag(
    19,
    1,
    (opBit) => BIT_AND_LOGIC(opBit, 0b1),
  );
  return isEnableNewAutoJoinFlowFlag;
};
export const isUseUnifiedRender = () => {
  return opFeatureUtility.getOpFeatureFlag(20, 1, (opBit) =>
    // eslint-disable-next-line @babel/new-cap
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableJMFLog = () => {
  return opFeatureUtility.getOpFeatureFlag(23, 1, (opBit) =>
    // eslint-disable-next-line @babel/new-cap
    BIT_AND_LOGIC(opBit, 0b1),
  );
};

export const isEnableWebRTC = () => {
  return (meetingConfig.webclientFeatureOptions ?? '').split('').reverse()[
    16 - 1
  ];
};

export const isEnableRWGSupportWebRTC = () => {
  return (meetingConfig.webclientFeatureOptions ?? '').split('').reverse()[
    17 - 1
  ];
};

export const isUnifiedRender = (useWBVideo) => {
  return useWBVideo || isUseUnifiedRender();
};

export const isEnableRWGCommandBypassToWCL = () => getOpFeatureOption(30);
