import { logConfig } from './laplace';
import meetingConfig from 'meetingConfig';
import { STY_API_USER } from '../constant';

export const isLaplaceEnabled = () => {
  return !!logConfig?.enable;
};

export const isLogableMediaCallback = (type) => {
  return !(logConfig?.excludedMediaCallback ?? []).includes(type);
};

export const isExcludedMediaSendType = (type) => {
  return !(logConfig?.excludedMediaSendType ?? []).includes(type);
};

export const canAutoLog = () => {
  if (
    !meetingConfig.isLogin ||
    Number(easyStore.easyGet('user_sty')) === STY_API_USER
  ) {
    return meetingConfig?.enableAutoLog;
  } else {
    return (
      meetingConfig?.enableAutoLog && meetingConfig.collectionOption?.toggle
    );
  }
};
