/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
/* eslint-disable func-names */
/* eslint-disable no-cond-assign */
// eslint-disable-next-line import/no-unresolved
import langResource from 'langResource';
import cookie from 'react-cookie';
import classNames from 'classnames';
import meetingConfig from 'meetingConfig';
import { WEBINAR_ATTENDEES } from '../constants/UserRoles';
import {
  isAllowOnlyCanTalk,
  isIE,
  isSupportAV,
  isViewOnly,
  isWebinar,
} from './service';
import { I18nLoader } from './load-apac-resource';
import { ShowType } from '../features/poll/constants';
import {
  CALC_VIEW_PORT_HEIGHT,
  CALC_VIEW_PORT_WIDTH,
  WEBINAR_CLIENT_CAP_CAN_MUTE,
  WEBINAR_CLIENT_CAP_SUPPORT_FETCH_MEETING_TOKEN,
  WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL,
  supportedMinimumChromeOSVersion,
  supportedMinimumSafariOSVersion,
} from './constant';

export const stringFormat = (source, ...params) =>
  params.reduce(
    (s, val, index) => s.replace(new RegExp(`\\{${index}\\}`, 'g'), val),
    source,
  );

export const isEdge = () => /edge\/(\d+)/i.test(navigator.userAgent);

export const isOpera = () => /opera|opr\/[\d]+/i.test(navigator.userAgent);

export const isFirefox = () => /firefox/i.test(navigator.userAgent);
export const isMacOS = () => /mac/i.test(navigator.platform);

export const isChrome = () => {
  const { userAgent } = navigator;
  return (
    !isOpera() &&
    !isEdge() &&
    /chrome/i.test(userAgent) &&
    /webkit/i.test(userAgent)
  );
};

export const isChromeForIos = () => {
  return /crios/i.test(navigator.userAgent);
};

export const isIOSChromeUnder17 = () => {
  return isOnIOS() && getIOSOSVersion() < 17 && isChromeForIos();
};

export function isPollEnabled(
  coOrHost,
  showType,
  isRwgEnablePolling,
  hasPollingInMeeting,
  isMeInRoom,
) {
  if (isMeInRoom) {
    return false;
  }
  if (isWebinar()) {
    if (
      typeof meetingConfig.meetingOptions.isWebinarPollingEnabled !==
      'undefined'
    ) {
      if (meetingConfig.meetingOptions.isWebinarPollingEnabled !== true) {
        return false;
      }
    }
    if (showType === ShowType.launching || showType === ShowType.sharing) {
      return true;
    }
    if (coOrHost) {
      return true;
    }
    return false;
  }
  if (
    !isWebinar() &&
    isRwgEnablePolling &&
    meetingConfig.meetingOptions.isPollingEnabled &&
    !meetingConfig.meetingOptions.isInstantMeeting
  ) {
    if (showType === ShowType.launching || showType === ShowType.sharing) {
      return true;
    }
    if (hasPollingInMeeting && coOrHost) {
      return true;
    }
    if (!hasPollingInMeeting && meetingConfig.isOriginhost && coOrHost) {
      return true;
    }
    return false;
  }
  return false;
}

export const isChromeOS = () => {
  return /\bCrOS\b/.test(navigator.userAgent);
};

export const isGoogleNestMode = () => {
  return false;
};

export const getGoogleNestVersion = () => {
  const googleNestUa = navigator.userAgent.match(/CrKey\/([\d.]+)/);
  if (googleNestUa) {
    const version = googleNestUa[1];
    const mainVersion = version.match(/[0-9]{0,2}[.][0-9]{0,2}/);
    return mainVersion ? mainVersion[0] : -1;
  }

  return -1;
};

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const isArm = () => {
  return /arm/i.test(navigator.userAgent);
};

export const isArmChromeOS = () => isChromeOS() && isArm();

export const isSupportAudioWorklet = () => typeof AudioWorklet === 'function';

export const isDeviceLessPerformant = () =>
  !!(navigator.hardwareConcurrency && navigator.hardwareConcurrency <= 4);

export const isIPad = () => {
  // const ua = navigator.userAgent.toLowerCase();
  // let portraitWidth = 0;
  // let portraitHeight = 0;

  // // iPad height, resolution table width // same width Note that there is 1668px
  // const screens = {
  //   1024: 768, // iPad mini 4, iPad Air 2, iPad Pro 9.7,
  //   1112: 834, // iPad Pro 10.5
  //   1194: 834, // iPad Pro 11
  //   1366: 1024, // iPad Pro 12.9 2nd
  // };
  // // held vertically held horizontally (portrait mode, landscape mode)
  // // determines in portrait position either case an
  // if (window.screen.width < window.screen.height) {
  //   portraitWidth = window.screen.width;
  //   portraitHeight = window.screen.height;
  // } else {
  //   portraitWidth = window.screen.height;
  //   portraitHeight = window.screen.width;
  // }

  // // iOS and iPadOS return true if the iPad either case return
  // return (
  //   ua.indexOf('ipad') > -1 ||
  //   (ua.indexOf('macintosh') > -1 && screens[portraitHeight] === portraitWidth)
  // );
  return /MacIntel/i.test(navigator.platform) && navigator?.maxTouchPoints > 2;
};

export const isOnIOS = () => {
  const isIOS = /iPad|iPhone|iPod/i.test(navigator.userAgent);
  return isIOS || isIPad();
};

export const isMobileDevice = () =>
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  isIPad() ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i);

export const isAndroidDevice = () => navigator.userAgent.match(/Android/i);

export const isOpenHarmonyDevice = () =>
  navigator.userAgent.match(/\(([^;]+); OpenHarmony ([\d.]+)\)/);

export const isTeslaMode = () => {
  return /TESLA/.test(navigator.userAgent);
};

export const isMTRAndroid = () => {
  return /MSFT Teams Android Room/.test(navigator.userAgent);
};

export const getNextA11yLevel = (base, increment) => {
  if (!base) return null;
  const baseArr = base.split('-');
  const newBase =
    +baseArr[baseArr.length - 1] + (increment === undefined ? 1 : increment);
  baseArr[baseArr.length - 1] = newBase;
  return baseArr.join('-');
};

export const performSafeFocus = (ele) => {
  if (!ele) return;
  setTimeout(() => {
    ele.focus();
  }, 0);
};

const escapeHtml = (str) => {
  const div = document.createElement('div');
  div.appendChild(document.createTextNode(str));
  return div.innerHTML;
};

export const ariaNotifier = document.getElementById('aria-notify-area');
export const safeNotifier = (str) => {
  ariaNotifier.innerHTML = escapeHtml(str);
};

export const promptA11yInfo = (msg) => {
  ariaNotifier.innerText = msg;
};

export const lastItem = (list) => list[list.length - 1];

export const isNone = (...arg) =>
  arg.every((v) => v === null || v === undefined || v === '');

/**
 *
 * @param {object} participant type1:cmd websocket participant object
 *  {displayName: "user1",...,isHost:false,bCoHost:false,userRole: 0}
 * @param {object} participant type2:type=x websocket participant object
 * {bRaiseHand: false,clientCap: 3,,jid:"wu_xxx.us",name: "attendee",node: 16795648,role: "30"}
 */
export const checkRole = (participant) => {
  const isHost = participant.isHost;
  const isCoHost = participant.bCoHost;
  const isAttendee = (() => {
    if (isWebinar()) {
      if (typeof participant.userRole !== 'undefined') {
        return (
          isViewOnly(participant.userRole) ||
          isAllowOnlyCanTalk(participant.userRole)
        );
      }
      if (typeof participant.role !== 'undefined') {
        return participant.role === WEBINAR_ATTENDEES;
      }
    } else {
      return !isHost && !isCoHost;
    }
    return true;
  })();
  const isPanelist = !isAttendee && !isHost && !isCoHost && isWebinar();
  return {
    isHost,
    isCoHost,
    isAttendee,
    isPanelist,
    isWebinarAttendee: isAttendee && isWebinar(),
  };
};

export const getSelectedClass = (flag) => {
  let selectClass;
  if (flag) {
    selectClass = classNames('glyphicon', 'glyphicon-ok', 'i-ok-margin');
  } else {
    selectClass = classNames('i-margin');
  }
  return selectClass;
};

export const waitGetIndexDBVersion = (dbName) =>
  new Promise((resolve) => {
    if (!window.indexedDB) {
      resolve('');
    }
    const req = window.indexedDB.open(dbName);
    req.onerror = () => resolve('');
    req.onsuccess = () => {
      resolve(req.result.version);
    };
  });

export function toHalfCode(tmpStr) {
  let tmp = '';
  for (let i = 0; i < tmpStr.length; i++) {
    if (tmpStr.charCodeAt(i) === 12288) {
      tmp += String.fromCharCode(tmpStr.charCodeAt(i) - 12256);
      // eslint-disable-next-line no-continue
      continue;
    }
    if (tmpStr.charCodeAt(i) > 65280 && tmpStr.charCodeAt(i) < 65375) {
      tmp += String.fromCharCode(tmpStr.charCodeAt(i) - 65248);
    } else {
      tmp += String.fromCharCode(tmpStr.charCodeAt(i));
    }
  }
  return tmp;
}

export function getFullOrHalfNum(tmpStr) {
  let tmp = '';
  for (let i = 0; i < tmpStr.length; i++) {
    if (tmpStr.charCodeAt(i) >= 65296 && tmpStr.charCodeAt(i) <= 65305) {
      tmp += String.fromCharCode(tmpStr.charCodeAt(i));
    } else if (tmpStr.charCodeAt(i) >= 48 && tmpStr.charCodeAt(i) <= 57) {
      tmp += String.fromCharCode(tmpStr.charCodeAt(i));
    }
  }
  return tmp;
}

export function truncateString(str, { length }) {
  // eslint-disable-next-line
  const r = /[^\x00-\xff]/g;
  if (str.replace(r, '**').length <= length) {
    return str;
  }
  const m = Math.floor(length / 2);
  for (let i = m; i < str.length; i++) {
    if (str.substr(0, i).replace(r, '**').length >= length) {
      return `${str.substr(0, i)}...`;
    }
  }
  return str;
}

const HEX = 16;

export const getCharLength = (char) => {
  const charCode = char.charCodeAt(0);
  if (charCode <= parseInt('0x7F', HEX)) {
    return 1;
  }
  if (charCode <= parseInt('0x7FF', HEX)) {
    return 2;
  }
  if (charCode <= parseInt('0xFFFF', HEX)) {
    return 3;
  }
  if (charCode <= parseInt('0x1FFFFF', HEX)) {
    return 4;
  }
  if (charCode <= parseInt('0x3FFFFFF', HEX)) {
    return 5;
  }
  return 6;
};

export const sliceStringByCodeLength = (str, codeLength) => {
  if (!str || codeLength === 0) {
    return '';
  }
  let where = 0;
  for (let i = 0; i < str.length; i++) {
    where += getCharLength(str[i]);
    if (where === codeLength) {
      return str.slice(0, i + 1);
    }
  }
  return str;
};

export function getStringLength(inputStr) {
  if (inputStr === '' || inputStr === undefined) {
    return 0;
  }
  let iLength = 0;
  for (let i = 0; i < inputStr.length; i++) {
    if (inputStr.charCodeAt(i) <= parseInt('0x7F', HEX)) {
      iLength += 1;
    } else if (inputStr.charCodeAt(i) <= parseInt('0x7FF', HEX)) {
      iLength += 2;
    } else if (inputStr.charCodeAt(i) <= parseInt('0xFFFF', HEX)) {
      iLength += 3;
    } else if (inputStr.charCodeAt(i) <= parseInt('0x1FFFFF', HEX)) {
      iLength += 4;
    } else if (inputStr.charCodeAt(i) <= parseInt('0x3FFFFFF', HEX)) {
      iLength += 5;
    } else {
      iLength += 6;
    }
  }
  return iLength;
}

export const replaceStringByCodeLength = (
  rawStr,
  strToInsert,
  startAt,
  eraseCount,
) => {
  let currentWhere = 0;
  let result = '';
  let count = eraseCount;
  let where = startAt;
  const contentLength = getStringLength(rawStr);
  if (rawStr === '') {
    return strToInsert;
  }
  if (startAt >= contentLength && count === 0) {
    return rawStr + strToInsert;
  }
  if (where === 0 && count === 0) {
    return strToInsert;
  }
  if (eraseCount === -1 || startAt + eraseCount > contentLength) {
    count = contentLength - startAt;
  }
  if (count === 0 && startAt === contentLength && startAt > 0) {
    where = startAt - 1;
  }

  for (let i = 0; i < rawStr.length; i++) {
    if (currentWhere === where) {
      result = rawStr.slice(0, i) + strToInsert;
    }
    if (currentWhere === contentLength - 1) {
      break;
    }
    if (currentWhere === where + count) {
      result += rawStr.slice(i);
      break;
    }
    currentWhere += getCharLength(rawStr[i]);
  }
  return result;
};

export function cutString(str, len) {
  if (str.length * 2 <= len) {
    return str;
  }
  let strlen = 0;
  let s = '';
  for (let i = 0; i < str.length; i++) {
    s += str.charAt(i);
    if (str.charCodeAt(i) > 128) {
      strlen += 2;
      if (strlen >= len) {
        return `${s.substring(0, s.length - 1)}...`;
      }
    } else {
      strlen += 1;
      if (strlen >= len) {
        return `${s.substring(0, s.length - 2)}...`;
      }
    }
  }
  return s;
}

export function getJoinMeetingLog(log) {
  const JOIN_MEETING_FLOW_JOIN_BO = easyStore.easyGet(
    'JOIN_MEETING_FLOW_JOIN_BO',
  );
  const JOIN_MEETING_FLOW_LEAVE_BO = easyStore.easyGet(
    'JOIN_MEETING_FLOW_LEAVE_BO',
  );
  const JOIN_MEETING_FLOW_PROMOTE = easyStore.easyGet(
    'JOIN_MEETING_FLOW_PROMOTE',
  );
  const JOIN_MEETING_FLOW_DEPROMOTE = easyStore.easyGet(
    'JOIN_MEETING_FLOW_DEPROMOTE',
  );

  let tag = '';
  tag += JOIN_MEETING_FLOW_JOIN_BO ? 'JOIN_MEETING_FLOW_JOIN_BO' : '';
  tag += JOIN_MEETING_FLOW_LEAVE_BO ? 'JOIN_MEETING_FLOW_LEAVE_BO' : '';
  tag += JOIN_MEETING_FLOW_PROMOTE ? 'JOIN_MEETING_FLOW_PROMOTE' : '';
  tag += JOIN_MEETING_FLOW_DEPROMOTE ? 'JOIN_MEETING_FLOW_DEPROMOTE' : '';

  return `${tag}\n${log}\nmid:${meetingConfig.mid}`;
}

export const getDeviceType = () => {
  if (isMobileDevice()) {
    return 'Mobile';
  } else {
    return 'Desktop';
  }
};

export const isSupportWebGLOffscreenCanvas = (() => {
  if (typeof OffscreenCanvas !== 'function') {
    return false;
  }

  const ofs = new OffscreenCanvas(1, 1);
  if (ofs.getContext('webgl')) {
    return true;
  }

  return false;
})();

export function getBrowserVersion() {
  if (navigator.browserVersion) return navigator.browserVersion;
  navigator.browserVersion = (function () {
    const ua = navigator.userAgent;
    let tem;
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return `IE ${tem[1] || ''}`;
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem && tem.length) {
        return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i))) M.splice(1, 1, tem[1]);
    return M.join('');
  })();
  return navigator.browserVersion;
}

/**
 * Determine whether it is not the expected browser and version
 * @param {String} name Required browser name
 * @param {Number} num Required browser version
 * @returns {boolean}
 */
export function isBelowSpecifiedBrowserVersion(name, num) {
  if (!navigator.browserVersion) {
    getBrowserVersion();
  }
  const bv = navigator.browserVersion || '';
  const browserName = bv.replace(/[^a-zA-Z]+/g, '');
  const browserVersion = bv.replace(/[^0-9]+/g, '');
  const result = browserName === name && Number(browserVersion) < num;
  return result;
}

export function saveCookie(liveTime, cookieName, cookieValue, options = {}) {
  const cookieTime = new Date();
  cookieTime.setTime(cookieTime.getTime() + liveTime);
  cookie.save(cookieName, cookieValue, {
    expires: cookieTime,
    path: '/',
    ...options,
  });
}

export function removeCookie(cookieName, options = {}) {
  cookie.remove(cookieName, { path: '/', ...options });
}

export function loadCookie(cookieName) {
  return cookie.load(cookieName);
}

const i18n = IS_LOCAL ? new I18nLoader({}) : new I18nLoader(langResource);

export function iText(defaultText, resourceKey, resourceParameter) {
  if (typeof i18n === 'undefined') {
    return defaultText || resourceKey;
  }
  const i18nText =
    typeof resourceParameter !== 'undefined'
      ? i18n.get(resourceKey, resourceParameter)
      : i18n.get(resourceKey);
  if (!!i18nText && i18nText !== resourceKey) {
    return i18nText;
  }
  return defaultText || resourceKey;
}

export function sortByDisplayName(a, b) {
  const nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
  const nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
}
export const bytesArrayBuffer2String = (arraybuffer) => {
  let result = '';
  for (let i = 0; i < arraybuffer.length; i++) {
    result += String.fromCharCode(arraybuffer[i]);
  }
  return result;
};

export function concatUint8s(one, two) {
  // eslint-disable-next-line no-param-reassign
  one = new Uint8Array(one);
  // eslint-disable-next-line no-param-reassign
  two = new Uint8Array(two);

  const uint8s = new Uint8Array(one.length + two.length);

  uint8s.set(one);
  uint8s.set(two, one.length);

  return uint8s;
}

export function str2uint8s(str) {
  if (!str) {
    return null;
  }

  const uint8s = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    uint8s[i] = str.charCodeAt(i);
  }

  return uint8s;
}

export function utf8ArrayToStr(array) {
  let out;
  let i;
  const len = array.length;
  let c;
  let char2;
  let char3;

  out = '';

  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0),
        );
        break;
      default:
        break;
    }
  }
  return out;
}

export function rawStringToBuffer(str) {
  let idx;
  const len = str.length;
  const arr = new Array(len);
  for (idx = 0; idx < len; ++idx) {
    arr[idx] = str.charCodeAt(idx) & 0xff;
  }
  // You may create an ArrayBuffer from a standard array (of values) as follows:
  return new Uint8Array(arr);
}

export function newSafeUrlEncode(str) {
  // return window.btoa(str);

  return btoa(unescape(encodeURIComponent(str)));
}

/* function utf8_to_b64( str ) {
  return window.btoa(encodeURIComponent( str ));
}

function b64_to_utf8( str ) {
  return decodeURIComponent(escape(window.atob( str )));
}
 */

export function formatDate(date, fmt) {
  let result = '';
  const o = {
    'M+': date.getMonth() + 1, //
    'd+': date.getDate(), //
    'h+': date.getHours(), //
    'm+': date.getMinutes(), //
    's+': date.getSeconds(), //
    'q+': Math.floor((date.getMonth() + 3) / 3), //
    S: date.getMilliseconds(), //
  };
  if (/(y+)/.test(fmt)) {
    result = fmt.replace(
      RegExp.$1,
      `${date.getFullYear()}`.substr(4 - RegExp.$1.length),
    );
  }
  Object.keys(o).forEach((k) => {
    if (new RegExp(`(${k})`).test(result)) {
      result = result.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length),
      );
    }
  });
  return result;
}

export const moveTextCursorToEnd = (input, endIndex) => {
  if (input.setSelectionRange) {
    input.focus();
    input.setSelectionRange(endIndex, endIndex);
  } else if (input.createTextRange) {
    const range = input.createTextRange();
    range.moveEnd('character', endIndex);
    range.moveStart('character', endIndex);
    range.select();
  }
};

function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  }
  // eslint-disable-next-line
  return x !== x && y !== y;
}

export function shallowEqual(objA, objB) {
  if (is(objA, objB)) return true;

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  for (let i = 0; i < keysA.length; i++) {
    if (
      !Object.prototype.hasOwnProperty.call(objB, keysA[i]) ||
      !is(objA[keysA[i]], objB[keysA[i]])
    ) {
      return false;
    }
  }

  return true;
}

const isSupportVideoTrackReader = () =>
  JsMediaSDK_Instance &&
  JsMediaSDK_Instance.util &&
  JsMediaSDK_Instance.util.isSupportVideoTrackReader();

export const isSupportMediaStreamTrackProcessor = () =>
  JsMediaSDK_Instance &&
  JsMediaSDK_Instance.util &&
  JsMediaSDK_Instance.util.isSupportMediaStreamTrackProcessor();

export const isGoogleNestChrome = () =>
  JsMediaSDK_Instance &&
  JsMediaSDK_Instance.util &&
  JsMediaSDK_Instance.util.isGoogleNestChrome();

export const isHighVersionGoolgeNestChrome = () =>
  JsMediaSDK_Instance &&
  JsMediaSDK_Instance.util &&
  JsMediaSDK_Instance.util.isHighVersionGoolgeNestChrome();

const isAndroidBrowser = () =>
  JsMediaSDK_Instance &&
  JsMediaSDK_Instance.util &&
  JsMediaSDK_Instance.util.isAndroidBrowser();

// export const isSupportOffscreenCanvas = () =>
//   JsMediaSDK_Instance &&
//   JsMediaSDK_Instance.util &&
//   JsMediaSDK_Instance.util.isSupportOffscreenCanvas();

const isSupportImageCapture = () =>
  JsMediaSDK_Instance &&
  JsMediaSDK_Instance.util &&
  JsMediaSDK_Instance.util.isSupportImageCapture();

export const isSupportSharingTrackReader = () => {
  return isSupportVideoTrackReader();
};

// export const DetectAndroidBrowser = () => {
//   try {
//     const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//     if (/android/i.test(userAgent)) {
//       return true;
//     }
//     return false;
//   } catch (e) {
//     return false;
//   }
// };

// export const DetectIsGoogleNestChrome = () => {
//   return navigator.userAgent.indexOf('CrKey') !== -1;
// };

// export const AndroidNotGoogleNest = () => {
//   return DetectAndroidBrowser() && !DetectIsGoogleNestChrome();
// };

export const isSingleVersionGoogleNest = () => {
  return (
    (isGoogleNestChrome() && !isHighVersionGoolgeNestChrome()) ||
    (isAndroidBrowser() && isSupportImageCapture())
  );
  // if (!isSupportVideoTrackReader() && !isSupportMediaStreamTrackProcessor()) {
  //   return false;
  // }
  // return !AndroidNotGoogleNest();
};

export const requestBrowserNotificationPermission = () => {
  if (Notification.permission === 'granted') {
    return true;
  }
  if (Notification.permission === 'denied') {
    return false;
  }
  Notification.requestPermission();
  return false;
};

export const getCurrentClientCap = () => {
  return isSupportAudioWorklet() && isSupportAV()
    ? WEBINAR_CLIENT_CAP_CAN_MUTE |
        WEBINAR_CLIENT_CAP_SUPPORT_FETCH_MEETING_TOKEN |
        WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL
    : WEBINAR_CLIENT_CAP_SUPPORT_FETCH_MEETING_TOKEN |
        WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL;
};

export const isBitSet = (data, bit) => {
  return (data & bit) === bit;
};

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function createDebounceThunk(action, timeout, former) {
  let timer = null;
  let data = [];
  return (v) => (dispatch) => {
    if (former && timer === null) {
      dispatch(action([v]));
      timer = setTimeout(() => {
        timer = null;
      }, timeout);
    } else {
      if (timer !== null) {
        clearTimeout(timer);
      }
      data.push(v);
      timer = setTimeout(() => {
        if (data.length !== 0) {
          dispatch(action(data));
          timer = null;
        }
        data = [];
      }, timeout);
    }
  };
}

export const parseTimeStamp = (timeStampString) => {
  if (timeStampString.length === 10) {
    return Number(timeStampString) * 1000;
  }
  return Number(timeStampString);
};

export function getLocalTime(time, options) {
  const timestamp = time ? new Date(parseTimeStamp(time)) : new Date();
  try {
    if (options) {
      return timestamp.toLocaleTimeString(meetingConfig.lang, options);
    }
    return timestamp.toLocaleTimeString(meetingConfig.lang);
  } catch (e) {
    if (options) {
      return timestamp.toLocaleTimeString([], options);
    }
    return timestamp.toLocaleTimeString();
  }
}

export function allowAndCanTalkThroughVIOP(isAllowTalk) {
  return isAllowTalk && !isSafari() && !isIE();
}

export function isLandscapeMode() {
  return document.body.classList.contains('landscape');
}

export const queryBit = (value, bit) => {
  return Boolean(value & bit);
};
export const toggleBit = (value, bit) => {
  return value ^ bit;
};
export const isMSFTMode = () => {
  return false;
};
export const isExternalControlledMode = () => {
  return isGoogleNestMode() || isMSFTMode() || isTeslaMode() || isMTRAndroid();
};

export const isSelfPreviewRenderWithVideo = (initVideoEncodeStatus) => {
  if (initVideoEncodeStatus !== 'success') return false;
  return (
    JsMediaSDK_Instance &&
    JsMediaSDK_Instance.util &&
    JsMediaSDK_Instance.util.isSelfPreviewRenderWithVideo &&
    JsMediaSDK_Instance.util.isSelfPreviewRenderWithVideo()
  );
};

export const getAndroidVersion = () => {
  const ua = navigator.userAgent.toLowerCase();
  const android_ver = ua.match(/android (.*?);/);
  const version = android_ver?.[1];
  const versionArr = version?.split?.('.');
  if (versionArr?.length >= 1) {
    return Number(versionArr[0]);
  }

  return Number(android_ver?.[1]);
};

export const getIosVersion = () => {
  const ua = navigator.userAgent.toLowerCase();
  const ios_ver = ua.match(/cpu iphone os (.*?) like mac os/);
  const number = ios_ver?.[1];
  if (number) {
    const versionList = number.split('_');
    const bigNumber = Number(versionList?.[0] || 0);
    const mediumNumber = Number(versionList?.[1] || 0);
    const smallNumber = Number(versionList?.[2] || 0);
    return [bigNumber, mediumNumber, smallNumber];
  }
  return [0, 0, 0];
};

export const getIpadMobileModeVersion = () => {
  const ua = navigator.userAgent.toLowerCase();
  const ios_ver = ua.match(/(ipad).*os\s([\d_]+)/);
  const number = ios_ver?.[2];
  if (number) {
    const versionList = number.split('_');
    const bigNumber = Number(versionList?.[0] || 0);
    const mediumNumber = Number(versionList?.[1] || 0);
    const smallNumber = Number(versionList?.[2] || 0);
    return [bigNumber, mediumNumber, smallNumber];
  }
  return [0, 0, 0];
};

export const getIpadDesktopModeVersion = () => {
  const ua = navigator.userAgent.toLowerCase();
  const ver = ua.match(/version\/(\d+).(\d+).?(\d+)?/);

  const bigNumber = Number(ver?.[1] || 0);
  const mediumNumber = Number(ver?.[2] || 0);
  const smallNumber = Number(ver?.[3] || 0);
  return [bigNumber, mediumNumber, smallNumber];
};

export const isSupportedTimeZone = () => {
  if (!('Intl' in window)) {
    return false;
  }
  const timeZoneStr = new Intl.DateTimeFormat()
    .resolvedOptions()
    .timeZone?.toLocaleLowerCase(); // value of timezone property may return undefined if nothing was provided representing runtime's default time zone.

  const UNSUPPORTED_REGION = [
    'asia/beijing',
    'asia/shanghai',
    'asia/chongqing',
    'asia/urumqi',
    'asia/hong_kong',
    'asia/macau',
  ];
  if (UNSUPPORTED_REGION.indexOf(timeZoneStr) > -1) {
    return false;
  }
  return true;
};

export const isMMRSupportWRTwoWayChat = (mmrFlag) => {
  if (isWebinar()) return false;
  return (mmrFlag & (1 << 28)) !== 0;
};

export const isWRTwoWayChatWebEnable = () => {
  return !!meetingConfig.meetingOptions.isEnableTwoWayChatInWaitingRoom;
};

export const isDeviceLandscape = () => {
  return (
    window.matchMedia('(orientation: landscape)').matches ||
    window.screen.availHeight < window.screen.availWidth
  );
};

export const px2vw = (pixel, fractionDigits = 2) => {
  return (
    (100 /
      (isDeviceLandscape() ? CALC_VIEW_PORT_HEIGHT : CALC_VIEW_PORT_WIDTH)) *
    pixel
  ).toFixed(fractionDigits);
};

export const isSupportAudioBridgeAvsync = () => {
  return (
    JsMediaSDK_Instance?.util?.isSupportAudioBridgeAvsync &&
    JsMediaSDK_Instance.util.isSupportAudioBridgeAvsync()
  );
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const deepFreeze = (o) => {
  Object.freeze(o);

  Object.getOwnPropertyNames(o).forEach((prop) => {
    if (
      /* eslint-disable-next-line no-prototype-builtins */
      o.hasOwnProperty(prop) &&
      o[prop] !== null &&
      (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
      !Object.isFrozen(o[prop])
    ) {
      deepFreeze(o[prop]);
    }
  });

  return o;
};

export const getChromeBrowserVersion = () => {
  if (!isChrome()) {
    return false;
  }
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return parseInt(raw[2], 10);
};

export const getIOSOSVersion = () => {
  const IOSMobileDevice = navigator.userAgent.match(/(OS ([\d_]+))/);
  const IOSTapDevice = navigator.userAgent.match(/Version\/([\d.]+).*Safari/);

  if (IOSMobileDevice)
    return parseFloat(IOSMobileDevice[2].replace('_', '.'), 10);
  if (IOSTapDevice) return parseFloat(IOSTapDevice[1], 10);
  return 0;
};

export const showBrowserUpgradeBanner = (useWBVideo) => {
  if (isMobileDevice()) {
    if (isChrome()) {
      if (isAndroidDevice())
        return (
          getChromeBrowserVersion() < supportedMinimumChromeOSVersion.ANDROID
        );
      if (isOnIOS()) {
        return useWBVideo
          ? getIOSOSVersion() < supportedMinimumChromeOSVersion.IOSWebRTC
          : getIOSOSVersion() < supportedMinimumChromeOSVersion.IOSWASM;
      }
    }
    if (isSafari()) {
      return useWBVideo
        ? getIOSOSVersion() < supportedMinimumSafariOSVersion.WebRTC
        : getIOSOSVersion() < supportedMinimumSafariOSVersion.WASM;
    }
  }
  return false;
};

export const requestScreenWakeLock = () => {
  return (
    JsMediaSDK_Instance?.util?.requestScreenWakeLock &&
    JsMediaSDK_Instance.util.requestScreenWakeLock()
  );
};

export const isUsingAudioLevelWorklet = () => {
  return !(isOnIOS() && getIOSOSVersion() < 17);
};
export const isSupportSharedArrayBuffer = () =>
  typeof SharedArrayBuffer === 'function';
