/* eslint-disable camelcase */
import { iText } from './util';

export const UNKNOWN_ERROR = iText('Unknown Error.', 'apac.wc_unknown_error');
export const UNKNOWN_ERROR_BTN = iText('Refresh', 'apac.wc_unknown_error_btn');
export const UNKNOWN_ERROR_ACTION = iText(
  'An unknown error has occurred, please try to refresh this page to rejoin the meeting.',
  'apac.wc_unknown_error_action',
);

export const EXIT_FULL_SCREEN = iText(
  'Exit Fullscreen',
  'apac.wc_sharing_exit_full_screen',
);
export const ENTER_FULL_SCREEN = iText(
  'FullScreen',
  'apac.wc_sharing_full_screen',
);

export const RENAME = iText('Rename', 'apac.dialog.rename');
export const DELETE = iText('Delete', 'apac.common.delete');
export const CANCEL = iText('Cancel', 'apac.common.cancel');
export const YES = iText('Yes', 'apac.common.yes');
export const NO = iText('No', 'apac.common.no');

export const MEETING_DISCONNECTED = iText(
  'Meeting Disconnected',
  'apac.common.disconnected',
);
export const TRY_RECONNECT = iText(
  'Trying to reconnect',
  'apac.common.try_reconnect',
);
export const CONNECTING_TEXT = iText('Connecting', 'apac.common.connecting');
export const LEARN_MORE = iText('Learn more', 'apac.wc_learn_more');
export const DECRYPT_FAILED_TEXT = iText(
  'This is an encrypted message',
  'apac.common.decrypt_fail',
);
export const GUEST = iText('Guest', 'apac.wc_guest');
export const SEARCH = iText('Search', 'apac.wc_search');
export const YOU = iText('You', 'apac.common.you');
export const VIEW = iText('View', 'apac.common.view');
export const SPEAK_VIEW = iText('Speaker', 'apac.common.speaker_view');
export const GALLERY_VIEW = iText('Gallery', 'apac.common.gallery_view');

export const STANDARD = iText('standard', 'apac.common.standard');
export const SIDE_BY_SIDE_SPEAKER = iText(
  'side by side: speaker',
  'apac.common.side_by_side_speaker',
);
export const SIDE_BY_SIDE_GALLERY = iText(
  'side by side: gallery',
  'apac.common.side_by_side_gallery',
);

export const SHOW_SELF_VIEW = iText(
  'Show Self View',
  'apac.common.show_self_view',
);
export const SHOW_NO_VIDEO_PARTICIPANTS = iText(
  'Show Non-Video Participants',
  'apac.common.show_non_video_participants',
);
export const REMOVE_ALL_PINS = iText(
  'Remove All Pins',
  'apac.video.remove_all_pins',
);

export const REMOVE_ALL_SPOTLIGHTS = iText(
  'Remove All Spotlights',
  'apac.video.remove_all_spotlights',
);

export const RELEASE_VIDEO_ORDER = iText(
  'Release Video Order',
  'apac.video.release_video_order',
);

export const FOLLOW_HOST_VIDEO_ORDER = iText(
  "Follow Host's Video Order",
  'apac.video.follow_host_video_order',
);
export const DECLINE = iText('Decline', 'apac.wc_decline');
export const SUBMIT = iText('Submit', 'apac.common.submit');

export const UPDATE_BROWSER = iText(
  'Update browser to latest version of Chrome to experience Speaker and Gallery View',
  'apac.wc_update_browser',
);

export const MY_VIEW = iText('My View', 'apac.common.my_view');
export const ATTENDEE_VIEW = iText(
  'Attendee View',
  'apac.common.attendee_view',
);

export const FOLLOW_HOST_VIEW = iText(
  "Follow Host's View",
  'apac.common.follow_host_view',
);

export const UPDATE_BROWSER_TO_USE_MULTIVIEW = iText(
  'Update your browser to the latest version of Chrome to use Speaker/Gallery View',
  'apac.wc_update_browser_to_use_multiview',
);
export const UPDATE_BROWSER_TO_SHARE = iText(
  'Update your browser to the latest version of Chrome to use Share Screen',
  'apac.wc_update_browser_to_share',
);
export const UPDATE_BROWSER_TO_SHARE_MULTIVIEW = iText(
  'Update your browser to the latest version of Chrome to use Share Screen and Speaker/Gallery View',
  'apac.wc_update_browser_to_share_multiview',
);
export const SWAP_VIDEO_AND_SHARED_SCREEN = iText(
  'Swap Video And Shared Screen',
  'apac.common.swap_video_and_shared_screen',
);

export const REFUSE_TEXT = iText('Refuse', 'apac.common.refuse');

export const WHO_JOINED_MEETING_TEXT = (userName) =>
  iText(
    `${userName} joined the meeting`,
    'apac.common.jonned_meeting',
    userName,
  );

export const JOIN_TEXT = iText('Join', 'apac.join_btn_join');

export const ON_HOLD_MSG = iText(
  'Please wait, the meeting host will let you in soon.',
  'apac.wc_put_on_hold_msg',
);

export const ON_HOLD_MSG2 = iText(
  'The host will let you in soon',
  'apac.wc_put_on_hold_msg2',
);

export const HOST_LOST_TEXT = iText(
  'Please wait, the host lost connection',
  'apac.waiting_room.host_lost',
);

export const WAIT_FOR_ADMIT2 = iText(
  "The host will admit you when they're ready",
  'apac.wait_for_admit2',
);

export const WAIT_FOR_ADMIT3 = iText(
  "Host has joined. We've let them know you're here",
  'apac.wait_for_admit3',
);

export const WAIT_FOR_HOST_MEETING = iText(
  'Waiting for host to start the meeting',
  'apac.wait_for_host',
);

export const WAIT_FOR_HOST_WEBINAR = iText(
  'Waiting for host to start the webinar',
  'apac.wait_for_host_webinar',
);

export const WAIT_FOR_WEBINAR_BEGIN = iText(
  'Please wait for the webinar to begin',
  'apac.wait_for_webinar_begin',
);

export const WAITING_ROOM_TITLE = iText(
  'Waiting Room',
  'apac.waiting_room_title',
);

export const SCHEDULED_TEXT = iText('Scheduled', 'apac.schedueld');

const CHAT_BUTTON_1 = iText(
  'Who can see your messages?',
  'apac.disclaimer.chat_button_normal',
);
const CHAT_BUTTON_REC = iText(
  'Who can see your messages? Recording On',
  'apac.disclaimer.chat_button_onRecording',
);
const CHAT_BUTTON_ARCHIVE = iText(
  'Who can see your messages? Archiving on',
  'apac.disclaimer.chat_button_onArchiving',
);
const CHAT_BUTTON_DLP = iText(
  'Who can see your messages? DLP on',
  'apac.disclaimer.chat_button_dlp_on',
);

const CHAT_BUTTON_DLPnRec = iText(
  'Who can see your messages? Recording & DLP On',
  'apac.disclaimer.chat_button_dlp_rec_on',
);

const CHAT_BUTTON_DLPnARC = iText(
  'Who can see your messages? Archiving & DLP On',
  'apac.disclaimer.chat_button_dlp_on',
);

const CHAT_BUTTON_DLPnARCnRec = iText(
  'Who can see your messages? Recording, Archiving & DLP On',
  'apac.disclaimer.chat_button_dlp_arc_on',
);

const CHAT_CONTENT_DLP = iText(
  'One or more meeting participants is subject to data loss prevention (DLP). Their account owner(s) can see and save your messages - including direct messages with these participants - and share them with apps and others. Any messages that conflict with policies defined by the account owner will be deleted',
  'apac.disclaimer.chat_content_dlp',
);
// case 9
const CHAT_BUTTON_AnR = iText(
  'Who can see your messages? Recording & Archiving On',
  'apac.disclaimer.chat_button_onArchivingRecording',
);
const CHAT_CONTENT_RECORDING_PREFIX = iText(
  'Recording is on.',
  'apac.disclaimer.chat_content_recording_on',
);
const CHAT_CONTENT_1 = iText(
  'Everyone in the meeting can see and save your messages with Everyone - and can share them with apps and others.',
  'apac.disclaimer.chat_content_1',
);

const CHAT_CONTENT_PMC = iText(
  'Everyone in the meeting can see and save your messages sent to Meeting Group Chat – and share them with apps and others. These messages will also be posted in the dedicated Meeting Group Chat in Team Chat, and everyone, including those not in the meeting, can see, save and share them.',
  'apac.disclaimer.chat_content_pmc2',
);

const CHAT_CONTENT_2 = iText(
  'Only you and those you chat with can save your direct messages and share them with apps and others.',
  'apac.disclaimer.chat_content_2',
);
const CHAT_CONTENT_3 = iText(
  'Recording is on, so the account owner and everyone in the meeting can see and save messages sent to Everyone — and can share them with apps and others.',
  'apac.disclaimer.chat_content_3',
);
const CHAT_CONTENT_4 = iText(
  'Recording is on, so everyone in the meeting can see and save messages sent to Everyone — and can share them with apps and others.',
  'apac.disclaimer.chat_content_4',
);
// archiving without direct message
const CHAT_CONTENT_ARC_NO_PRIVATE_CHAT = iText(
  'One or more meeting participants is subject to archiving. Their account owner(s) can see and save your messages - not including direct messages with these participants - and share them with apps and others.',
  'apac.disclaimer.chat_content_archive_no_chat',
);
const CHAT_CONTENT_ARC_CHAT = iText(
  'One or more meeting participants is subject to archiving. Their account owner(s) can see and save your messages - including direct messages with these participants - and share them with apps and others.',
  'apac.disclaimer.chat_content_archive_chat',
);

const CHAT_CONTENT_ARC_DLP_NO_PRIVATE_CHAT = iText(
  'One or more meeting participants is subject to archiving. The host account has also enabled data loss prevention (DLP). These account owner(s) can see and save your messages - not including direct messages with these participants - and share them with apps and others. Any messages that conflict with DLP policies defined by the host account owner will be deleted.',
  'apac.disclaimer.chat_content_dlp_arc_no_private_chat',
);

const CHAT_CONTENT_ARC_DLP_PRIVATE_CHAT = iText(
  'One or more meeting participants is subject to archiving. The host account has also enabled data loss prevention (DLP). The account owner(s) can see and save your messages - including direct messages with these participants - and share them with apps and others. Any messages that conflict with DLP policies defined by the host account owner will be deleted.',
  'apac.disclaimer.chat_content_dlp_arc_private_chat',
);

const CHAT_CONTENT_NO_OWNER = iText(
  'Everyone in the meeting can see and save your messages with Everyone.',
  'apac.disclaimer.chat_content_no_owner',
);

const CHAT_CONTENT_NO_OWNER_RECORD = iText(
  'Recording is on, so everyone in the meeting can see and save messages sent to Everyone — and can share them with apps and others',
  'apac.disclaimer.chat_content_with_record',
);

const CHAT_CONTENT_OWNER_RECORD = iText(
  'Recording is on, so the account owner and everyone in the meeting can see and save messages sent to Everyone — and can share them with apps and others.',
  'apac.disclaimer.chat_content_with_cloudrecord',
);

const CHAT_CONTENT_WITH_OWNER = iText(
  'Everyone in the meeting and the account owner can see and save your messages with Everyone.',
  'apac.disclaimer.chat_content_with_owner',
);
// const CHAT_CONTENT_7 = iText(
//   'Archiving is on, so the account owner can see all your messages - not including your direct messages - and share them with apps and others.',
//   'apac.disclaimer.chat_content_7',
// );
const LT_BUTTON_1 = iText(
  'Who can see this transcript?',
  'apac.disclaimer.LT_button_1',
);
const LT_CONTENT_1 = iText(
  'The account owner and everyone in the meeting can save this transcript and share it with apps and others.',
  'apac.disclaimer.LT_content_1_1',
);
const LT_CONTENT_2 = iText(
  'Live Transcription (Closed Captioning) has been enabled',
  'apac.disclaimer.LT_content_2',
);

export const WAITING_ROOM_CHAT_CONTENT_1 = iText(
  'Only the meeting host and co-hosts can see your messages while you are in the waiting room.',
  'apac.disclaimer.waiting_room_chat_content_1',
);

export const WAITING_ROOM_CHAT_CONTENT_2 = iText(
  'Only you and those you chat with can save your direct messages and share them with apps and others.',
  'apac.disclaimer.waiting_room_chat_content_2',
);

export const disclaimerText = {
  chat: {
    buttonText: {
      normal: CHAT_BUTTON_1,
      onRecording: CHAT_BUTTON_REC,
      archivingNoChat: CHAT_BUTTON_1,
      archivingOn: CHAT_BUTTON_ARCHIVE,
      archivingRec: CHAT_BUTTON_AnR,
      DLP: CHAT_BUTTON_DLP,
      DLPRec: CHAT_BUTTON_DLPnRec,
      DLPnARC: CHAT_BUTTON_DLPnARC,
      DLPnARCnREC: CHAT_BUTTON_DLPnARCnRec,
    },
    content: {
      pmc: [CHAT_CONTENT_PMC, CHAT_CONTENT_2],
      normal: [CHAT_CONTENT_1, CHAT_CONTENT_2], // case1
      onRecording: [CHAT_CONTENT_3, CHAT_CONTENT_2], // case2
      localRecording: [CHAT_CONTENT_4, CHAT_CONTENT_2], // case3
      archivingNoChat: [CHAT_CONTENT_1, CHAT_CONTENT_2], // case4 same as 1
      // archivingNoChat with local record same as case2
      // archivingNoChat with cloud record same as case3
      archivingNoPrivateChat: [
        CHAT_CONTENT_ARC_NO_PRIVATE_CHAT,
        CHAT_CONTENT_NO_OWNER,
      ], // case6
      archivingNoPrivateChatLocalRecord: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_NO_PRIVATE_CHAT].join(
          ' ',
        ),
        CHAT_CONTENT_NO_OWNER,
      ], // case 9
      archivingNoPrivateChatCloudRecord: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_NO_PRIVATE_CHAT].join(
          ' ',
        ),
        CHAT_CONTENT_WITH_OWNER,
      ], // case 10
      archivingIncludePrivateChat: [
        CHAT_CONTENT_ARC_CHAT,
        CHAT_CONTENT_NO_OWNER,
      ], // case5
      archivingIncludePrivateChatLocalRec: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_CHAT].join(' '),
        CHAT_CONTENT_NO_OWNER,
      ], // case7
      archivingIncludePrivateChatCloudRec: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_CHAT].join(' '),
        CHAT_CONTENT_WITH_OWNER,
      ], // case8
      waitingRoomChat: [
        WAITING_ROOM_CHAT_CONTENT_1,
        WAITING_ROOM_CHAT_CONTENT_2,
      ],
      dlp: [CHAT_CONTENT_DLP, CHAT_CONTENT_NO_OWNER], // case 11
      dlpLocalRecord: [CHAT_CONTENT_NO_OWNER_RECORD, CHAT_CONTENT_DLP], // case12
      dlpCloudRecord: [CHAT_CONTENT_OWNER_RECORD, CHAT_CONTENT_DLP], // case13
      dlpArcNoPriChat: [
        CHAT_CONTENT_ARC_DLP_NO_PRIVATE_CHAT,
        CHAT_CONTENT_NO_OWNER,
      ], // case15
      dlpArcNoPriChatLocalRec: [
        [
          CHAT_CONTENT_RECORDING_PREFIX,
          CHAT_CONTENT_ARC_DLP_NO_PRIVATE_CHAT,
        ].join(' '),
        CHAT_CONTENT_NO_OWNER,
      ], // case18
      dlpArcNoPriChatCloudRec: [
        [
          CHAT_CONTENT_RECORDING_PREFIX,
          CHAT_CONTENT_ARC_DLP_NO_PRIVATE_CHAT,
        ].join(' '),
        CHAT_CONTENT_WITH_OWNER,
      ], // case 19
      // case 14
      dlpArcPriChat: [CHAT_CONTENT_ARC_DLP_PRIVATE_CHAT, CHAT_CONTENT_NO_OWNER],
      dlpArcPriChatLocalRec: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_DLP_PRIVATE_CHAT].join(
          ' ',
        ),
        CHAT_CONTENT_NO_OWNER,
      ], // case16
      dlpArcPriChatCloudRec: [
        [CHAT_CONTENT_RECORDING_PREFIX, CHAT_CONTENT_ARC_DLP_PRIVATE_CHAT].join(
          ' ',
        ),
        CHAT_CONTENT_WITH_OWNER,
      ], // case17
    },
  },
  LT: {
    besideContent: LT_CONTENT_2,
    buttonText: LT_BUTTON_1,
    content: LT_CONTENT_1,
  },
  // poll: {
  //   buttonText: POLL_BUTTON_1,
  //   content: {
  //     noAnonymous: POLL_CONTENT_1,
  //     bAnonymous: POLL_CONTENT_2,
  //   },
  // },
  // QA: {
  //   buttonText: QA_BUTTON_1,
  //   content: {
  //     noAllNoAnonymous: [qaContent1(), qaContent2()],
  //     noAllBeAnonymous: [qaContent3(), qaContent2()],
  //     bAllNoAnonymous: [QA_CONTENT_4, qaContent1()],
  //     bAllBAnonymous: [QA_CONTENT_4, qaContent1(), QA_CONTENT_5],
  //   },
  // },
};

export const deviceChangedText = (deviceType, deviceName) => {
  return iText(
    `Your default ${deviceType} has changed to ${deviceName} and will now be used`,
    'apac.wc_device_change_tip',
    [deviceType, deviceName],
  );
};

export const PANELIST_PROMOTION_IS_NOT_SUPPORTED = iText(
  'You are being promoted to a Panelist. Please rejoin from a desktop or a native client to be a panelist on this Webinar.',
  'apac.panelist_promotion_is_not_supported',
);

export const SAME_AS_SYSTEM = iText('Same as System', 'apac.same_as_system');

export const USER_DISABLED_VB_LINK2 = iText(
  'Meeting Settings',
  'apac.settings.user_disabled_vb_link2',
);

export const SERVICE_TERMS = iText(
  'Terms of Service',
  'apac.common.service_terms',
);
export const PRIVACY_POLICY = iText(
  'Privacy Statement',
  'apac.common.privacy_policy',
);

export const LOWER_HAND_TITLE = iText('Lower Hand', 'apac.wc_lower_hand');
export const RAISE_HAND_TITLE = iText('Raise Hand', 'apac.wc_raise_hand');
export const UPGRADE_BROWSER_FOR_BETTER_EXPERIENCE_TEXT = iText(
  'Please upgrade your browser for the best experience.',
  'apac.common.browse_upgrade_for_better_experience',
);

export const WEB_RTC_NOT_AVAILABLE = iText(
  'WebRTC service is not available now',
  'apac.web_rtc_not_available',
);

export const AUTO_CHANGE_TO_WEB_RTC = iText(
  'Video rendering method is auto changed to WebRTC due to your device performance',
  'apac.auto_change_to_web_rtc',
);

export const NEXT_TEXT = iText('Next', 'apac.common.next');

export const TAP_TO_RESTORE_AUDIO = iText(
  'Don’t hear anything? Tap anywhere.',
  'apac.tap_to_restore_audio',
);

export const VIDEO_STREAM_FAILED_PERMISSION_RESET_TEXT = iText(
  'Your browser is preventing access to your microphone. Learn how to allow access to your microphone.',
  'apac.common.audio_stream_failed_permission_reset_text',
);

export const VIDEO_STREAM_FAILED_EXCEPTION_TEXT = iText(
  'Can’t access your camera. Please refresh your browser to try again.',
  'apac.common.audio_stream_failed_exception_text',
);

export const AUDIO_STREAM_FAILED_PERMISSION_RESET_TEXT = iText(
  "Enable camera access in your browser's address bar.",
  'apac.common.audio_stream_failed_permission_reset_text',
);

export const VIDEO_WEBSOCKET_BROKEN_TEXT = iText(
  'Connection to remote video has failed. Please refresh your browser to try again.',
  'apac.common.video_websocket_broken_text',
);

export const AUDIO_WEBSOCKET_BROKEN_TEXT = iText(
  'Audio connection failed. Please refresh your browser to reconnect.',
  'apac.common.audio_websocket_broken_text',
);

export const WEBGL_CONTEXT_INVALID_TEXT = iText(
  'Oops! Something went wrong. Please close all browsers and rejoin the meeting.',
  'apac.common.webgl_context_invalid_text',
);

export const WASM_MEMORY_FAIL_TEXT = iText(
  'Uh-oh! You’re running low on memory. Please close all browsers and rejoin the meeting.',
  'apac.common.wasm_memory_fail_text',
);

export const MEDIA_HEALTH_CHECK_FAILED_TEXT = iText(
  'Oops! Something went wrong. Please close all browsers and rejoin the meeting.',
  'apac.common.media_health_check_failed',
);

export const NOTIFY_UI_FAILOVER_TEXT = iText(
  'Oops! Something went wrong. Reconnecting your video...',
  'apac.common.notify_ui_failover_text',
);
