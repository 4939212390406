export * from './main';

export const sdkIvValKeyEnum = {
  0: 'VIDEO_ENCODE',
  1: 'VIDEO_DECODE',
  2: 'AUDIO_ENCODE',
  3: 'AUDIO_DECODE',
  4: 'SHARING_ENCODE',
  5: 'SHARING_DECODE',
};

export const sdkIvTypeKeyEnum = {
  VIDEO_ENCODE: '0',
  VIDEO_DECODE: '1',
  AUDIO_ENCODE: '2',
  AUDIO_DECODE: '3',
  SHARING_ENCODE: '4',
  SHARING_DECODE: '5',
};

export const ivType = {
  RWG_CHAT: {
    gcmType: 1,
    sessionType: 'RWG_CHAT',
  },
  XMPP_CHAT: {
    gcmType: 1,
    sessionType: 'XMPP_CHAT',
  },
  CC_MESSAGE: {
    gcmType: 3,
    sessionType: 'CC_MESSAGE',
  },
  BO_BROADCAST: {
    gcmType: 1,
    sessionType: 'BO_BROADCAST',
  },
};

export const encryptType = {
  NONE: 0,
  AES256: 1,
  AES_GCM: 2,
};
