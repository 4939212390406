import { easyStore, storeType } from '../easy-store';
import { isSinglePageFlowEnabled, isWebinar } from '../service';
import meetingConfig, { updateMeetingConfig } from '../meetingConfig';
import { setMeetingTokens } from './meetingToken';
import { loadWebIM } from '../../task/loadWebIM';

export const isMeetingConfigReady = () => {
  if (!isSinglePageFlowEnabled()) {
    return true;
  }
  if (meetingConfig.isStart) {
    return true;
  }
  return easyStore.easyGet('isMeetingConfigReady');
};

export const setMeetingConfigReady = () => {
  return easyStore.easySet('isMeetingConfigReady', true, storeType.memory);
};

export const handleMeetingConfig = (data) => {
  updateMeetingConfig(data);
  setMeetingConfigReady();
  setMeetingTokens(data);

  if (isWebinar()) {
    loadWebIM();
  }
};
