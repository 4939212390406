import { Laplace, LaplaceInterface } from '@zoom/laplace';
import AutoCleanLogPlugin from '@zoom/laplace/build/plugins/auto-clean-log-plugin';
import AutoLogWebVitalsPlugin from '@zoom/laplace/build/plugins/auto-log-web-vitals-plugin';
import AutoLogErrorPlugin from '@zoom/laplace/build/plugins/auto-log-error-plugin';
import { escapeSensitiveContent } from './escapeSensitiveContent';
import meetingConfig from 'meetingConfig';

import { LOCALSTORAGE_KEYS } from '../constant';
import { generateUUID, isWebinar } from '../service';
import { decodeBase64, encodeBase64 } from '../utils/base64';
import {
  getBaseUrl,
  getMediasdkPackageVersion,
} from '../../task/global/service';
import { reportAction } from './log-service/avs-laplace-telemetry';
import { directReport } from './log-service/direct-report';
import { LogLevel } from '@zoom/laplace/build/interface';
import { globalVariable } from '../global-variable';

export const trackingId = generateUUID();
export const getTrackingId = () => trackingId;
export const region = `region_${meetingConfig.userRegion}`;
const MAXIMUM_MAX_SIZE = 1024 * 1024 * 47;
const DATABASE_NAME = 'laplace-web-client-database';

let logConfigDefault = {
  enable: false,
  gatewayEndPoint: '',
  logLevel: {
    debug: false,
    log: false,
    info: false,
    print: false,
    warn: false,
    error: false,
  },
  performanceReportRatio: 1,
  errorReportRatio: 1,
  trackingCount: 7,
  enableAutoLog: true,
};

try {
  if (meetingConfig?.logConfig) {
    logConfigDefault = JSON.parse(meetingConfig?.logConfig);
  }
} catch (error) {
  // eslint-disable-next-line no-console
  console.error('parse log config error');
}

export const logConfig = logConfigDefault;
export const shouldReportPerformance =
  Math.random() < (logConfig?.performanceReportRatio ?? 0);

const ignoredErrorMsgKeywords = [
  'table index is out of bounds',
  'ResizeObserver loop completed with undelivered notifications',
];

const makeReportUrl = () => {
  const { gatewayEndPoint } = logConfig;
  const LOG_GATEWAY_API_ROUTE = '/pwa/webclient';
  if (gatewayEndPoint) {
    return gatewayEndPoint + LOG_GATEWAY_API_ROUTE;
  }
  return '';
};

export const reportUrl = makeReportUrl();

const weekTimestamp = 7 * 24 * 60 * 60 * 1000;

const expireBefore = new Date().getTime() - weekTimestamp;
const noop = async () => {};
export let laplace = {
  log: noop,
  deleteLog: noop,
  directReport: noop,
  reportByFilter: noop,
};
export const getCodeVersion = () => {
  let webClientVersion = 'unknown';
  const webClientVersionMatches = getBaseUrl().match(/web_client_m\/([\w]+)/);
  if (webClientVersionMatches?.length === 2) {
    webClientVersion = webClientVersionMatches[1];
  }

  let mediaSDKVersion = getMediasdkPackageVersion();

  return `${webClientVersion}/${mediaSDKVersion}`;
};

const plugins = [
  new AutoCleanLogPlugin({
    debug: false,
    cleanAfterReport: true,
    expireBefore,
  }),
  new AutoLogWebVitalsPlugin({
    enable: shouldReportPerformance,
    publicTags: ['PERFORMANCE'],
  }),
  new AutoLogErrorPlugin({
    ignoredErrorMsgKeywords,
    maxErrorCount: 223,
  }),
];

const laplaceConfig = {
  databaseName: DATABASE_NAME,
  maxSize: MAXIMUM_MAX_SIZE,
  reportUrl,
  plugins,
  publicTags: [
    'MOBILE WEB CLIENT',
    region,
    trackingId,
    isWebinar() ? 'Webinar' : 'Meeting',
  ],
  publicAttributes: {
    userAgent: navigator.userAgent,
    codeVersion: getCodeVersion(),
    accountId: escapeSensitiveContent(meetingConfig.accountId),
  },
};

let historyTrackingId =
  localStorage.getItem(encodeBase64(LOCALSTORAGE_KEYS.webClient_trackingIds)) ??
  [];
if (typeof historyTrackingId === 'string') {
  try {
    historyTrackingId = JSON.parse(decodeBase64(historyTrackingId));
  } catch (error) {
    historyTrackingId = [];
  }
}

const checkLaplace = () => {
  const isLaplaceExisted = Boolean(laplace.log);
  return isLaplaceExisted;
};

const mergeTags = (tag1, tag2) => {
  return [tag1, tag2]
    .flatMap((v) => v)
    .filter((x) => Boolean(x))
    .map((v) => {
      if (typeof v === 'function') {
        return v();
      }
      return v;
    });
};

const log = async (message, aspectTags, tags) => {
  if (!(logConfig?.logLevel?.log ?? false) || !checkLaplace()) {
    return;
  }
  return laplace.log(message, mergeTags(aspectTags, tags));
};

const warn = (message, aspectTags, tags) => {
  if (!(logConfig?.logLevel?.warn ?? false)) {
    return;
  }
  if (!checkLaplace()) {
    return;
  }
  return laplace.log(
    message,
    mergeTags(aspectTags, tags),
    LaplaceInterface.LogLevel.warn,
  );
};

const error = async (message, aspectTags, tags) => {
  if (!logConfig?.logLevel?.error || !checkLaplace()) {
    return;
  }

  const logResult = laplace
    .log(message, mergeTags(aspectTags, tags), LogLevel.error)
    .then(() => {
      reportToGlobalTracing({
        filter: (logItem) =>
          logItem.logLevel === LaplaceInterface.LogLevel.error,
      });
    });

  return logResult;
};

export const reportToGlobalTracing = (config, isManualReport) => {
  if (!checkLaplace() || !reportUrl) {
    return Promise.resolve();
  }
  if (isManualReport) {
    reportAction(true);
  }

  const { filter } = config || {};
  if (CLIENT_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn('[report filter]: ', filter);
  }
  return laplace.reportByFilter(filter);
};

const print = (message) => {
  if (!(logConfig?.logLevel?.print ?? false)) {
    return;
  }
  // eslint-disable-next-line no-console
  console.log(message);
};
export const makeLogger = (tags) => {
  const constantTags = [];
  const aspectTags = mergeTags(constantTags, tags);
  return {
    log: (message, tags) => log(message, aspectTags, tags),
    error: (message, tags) => error(message, aspectTags, tags),
    warn: (message, tags) => warn(message, aspectTags, tags),
    print: (message) => print(message),
    directReport: (message, tags) => directReport(message, { tags }),
  };
};

export const reportPerformance = () => {
  if (shouldReportPerformance) {
    return reportToGlobalTracing({ filter: 'First Screen Performance Event' });
  }
  return Promise.resolve();
};

export const reportWithNotificationToGlobalTracing = ({
  problem,
  description,
  timeOfOccurrence,
  ticketId,
  isIncludeLogFile,
  userId = '',
  confId = '',
  conID = '',
  svcUrl = '',
  res = '',
}) => {
  const product = 'Meeting';

  const configForGlobalTracing = {
    userId,
    confId,
    conID,
    svcUrl,
    userEmail: meetingConfig?.userEmail,
    userName: meetingConfig?.userName,
    filter: isIncludeLogFile ? () => isIncludeLogFile : 'REPORT_NOTIFICATION',
  };

  const imNotification = `product: ${product}\n${'problem'}: ${problem}\n${'timeOfOccurrence'}: ${timeOfOccurrence}\n${'description'}: ${description}\n${'ticketID'}: ${ticketId}\n${'userName'}: ${
    meetingConfig?.userName
  }\n${'userEmail'}: ${meetingConfig?.userEmail}\n${'isLogIncluded'}: ${
    isIncludeLogFile ? 'Logs included' : ''
  }\nnodeId: ${userId}\nconfId: ${confId}\nconID: ${conID}\nsvcUrl: ${svcUrl}\n4098 res: ${res}`;

  if (!checkLaplace()) {
    return Promise.resolve();
  }
  globalVariable.avSocket?.socketInstance?.beforeReportToGlobalTracing?.();
  return laplace
    .log('', [trackingId, 'REPORT_NOTIFICATION'], {
      IM_NOTIFICATION: `${imNotification}\nTrackingId: ${trackingId}`,
    })
    .then(() => {
      const isManualReport = true;
      reportToGlobalTracing(configForGlobalTracing, isManualReport);
    });
};

const handleInitLaplaceSuccess = () => {
  if (historyTrackingId.length >= logConfig?.trackingCount ?? 7) {
    historyTrackingId.shift();
  }
  const trackingDict = {};
  historyTrackingId.forEach((trackingId) => {
    trackingDict[trackingId] = true;
  });

  const filterNoTrackingLog = (logItem) => {
    return !logItem.tags.some((tag) => trackingDict[tag]);
  };
  laplace.deleteLog(filterNoTrackingLog);

  historyTrackingId.push(trackingId);

  localStorage.setItem(
    encodeBase64(LOCALSTORAGE_KEYS.webClient_trackingIds),
    encodeBase64(JSON.stringify(historyTrackingId)),
  );

  laplace.log('Laplace init success! New Laplace session', [
    trackingId,
    'LAPLACE_NEW_SESSION',
  ]);
};

export let logLimitationPool = {};

export const initLaplace = () => {
  setInterval(() => {
    logLimitationPool = {};
  }, (logConfig?.cleanLimitationInterval ?? 600) * 1000);
  return Laplace.init(laplaceConfig)
    .then((laplaceInstance) => {
      if (laplaceInstance) {
        laplace = laplaceInstance;
        handleInitLaplaceSuccess();
      }
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.error(`initLaplace ERROR:`, e);
    });
};
