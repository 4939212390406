import { iText } from '../../global/util';

export const INTERPRETATION = iText('Interpretation', 'apac.interpretation');
export const INTERPRETER = iText('Interpreter', 'apac.interpreter');
export const MUTE_ORIGINAL_AUDIO_TEXT = iText(
  'Mute Original Audio',
  'apac.interpretation.mute_original_audio',
);
export const UNMUTE_ORIGINAL_AUDIO_TEXT = iText(
  'Unmute Original Audio',
  'apac.interpretation.unmute_original_audio',
);
export const ORIGINAL_AUDIO_TEXT = iText(
  'Original Audio',
  'apac.interpretation.original_audio',
);

const INTERPRETATION_LANG0 = iText('English', 'apac.interpretation.en');
const INTERPRETATION_LANG1 = iText('Chinese', 'apac.interpretation.cn');
const INTERPRETATION_LANG2 = iText('Japanese', 'apac.interpretation.jp');
const INTERPRETATION_LANG3 = iText('German', 'apac.interpretation.de');
const INTERPRETATION_LANG4 = iText('French', 'apac.interpretation.fr');
const INTERPRETATION_LANG5 = iText('Russian', 'apac.interpretation.ru');
const INTERPRETATION_LANG6 = iText('Portuguese', 'apac.interpretation.pt');
const INTERPRETATION_LANG7 = iText('Spanish', 'apac.interpretation.es');
const INTERPRETATION_LANG8 = iText('Korean', 'apac.interpretation.kr');

export const INTERPRETATION_LANG = {
  INTERPRETATION_LANG0,
  INTERPRETATION_LANG1,
  INTERPRETATION_LANG2,
  INTERPRETATION_LANG3,
  INTERPRETATION_LANG4,
  INTERPRETATION_LANG5,
  INTERPRETATION_LANG6,
  INTERPRETATION_LANG7,
  INTERPRETATION_LANG8,
  'INTERPRETATION_LANG-1': INTERPRETATION,
};

export const INTERPRETATION_AVAILABEL_TIP1 = (lang1, lang2) =>
  iText(
    `${lang1} and ${lang2} interpretation is available`,
    'apac.interpretation.available_tip1',
    [lang1, lang2],
  );

export const INTERPRETATION_AVAILABEL_TIP2 = (lang, number) =>
  iText(
    `${lang} and ${number} other language interpretations are available`,
    'apac.interpretation.available_tip2',
    [lang, number],
  );

export const INTERPRETATION_END_TIP = iText(
  'Language interpretation has been ended by host',
  'apac.interpretation.end_tip',
);

export const INTERPRETATION_NOT_SUPPORT_TIP = iText(
  'Language Interpretation isn’t supported in your browser. Please use the desktop app to join.',
  'apac.interpretation.not_support_tip',
);
