import axios from 'axios';
import qs from 'qs';
import meetingConfig from 'meetingConfig';
import { getMeetingTokens } from './meetingToken';
import { getJoinMeetingLog } from '../util';
import {makeLogger} from "../logger/laplace";

const logger = makeLogger(['Join Meeting Flow']);

// currently, it only fetch the DLP
const doFetchProfile = () => {
  const { isLogin, baseUrl } = meetingConfig;
  const { zak } = getMeetingTokens();

  return new Promise((resolve, reject) => {
    if (!isLogin) {
      logger.log(getJoinMeetingLog('fetch profile'), ['FETCH_PROFILE_UNLOGIN']);
      resolve();
      return;
    }
    const url = `${baseUrl}/wc/profile/get`;
    axios.post(url, qs.stringify({ zak })).then(({ data }) => {
      if (data.result) {
        logger.log(getJoinMeetingLog('fetch profile'), [
          'FETCH_PROFILE_SUCCESS',
        ]);
        resolve(data.result);
      } else {
        logger.log(
          getJoinMeetingLog(
            `fetch profile error code: ${data.errorCode}, message: ${data.errorMessage}`,
          ),
          ['FETCH_PROFILE_FAILED'],
        );
        reject(Error('empty profile'));
      }
    });
  });
};

let promise = null;

export const fetchProfile = () => {
  if (!promise) {
    promise = doFetchProfile();
  }
  return promise;
};
