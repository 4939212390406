import {
  handleMeetingConfig,
  isMeetingConfigReady,
} from '../../global/service/meetingConfig';
import { getMeetingInfo } from '../../global/service/meetingInfo';
import meetingConfig from 'meetingConfig';
import axios from 'axios';
import { getJoinUrlParams } from '../../features/preview/service';
import { getBaseUrl } from '../global/service';
import { watchReduxInMeeting } from '../../global/service/what-redux';
import { PERFORMANCE_MARK, performanceMark } from '../../performance';

const checkMeetingConfigReady = () => {
  return new Promise((resolve) => {
    if (isMeetingConfigReady()) {
      return resolve();
    }
    let data = getMeetingInfo();
    if (meetingConfig.webinarToken) {
      data.tk = meetingConfig.webianrToken;
    }
    axios
      .post(
        `${meetingConfig.baseUrl}/wc/${
          meetingConfig.meetingNumber
        }/join${getJoinUrlParams()}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        },
      )
      .then((res) => {
        const { status, result } = res.data;
        if (status && result) {
          handleMeetingConfig(result.MeetingConfig);
        }
      })
      .then(resolve);
  });
};
const mainClientJob = ({ ctx, taskProps }) => {
  return checkMeetingConfigReady().then(() => {
    // eslint-disable-next-line camelcase, no-undef
    __webpack_public_path__ = `${getBaseUrl()}/`;
    return import(
      /* webpackMode: "lazy" */
      /* webpackChunkName: "main-client" */
      '../../main-client'
    ).then(({ runMainClient, configureStore }) => {
      performanceMark(
        taskProps?.fromPreview
          ? PERFORMANCE_MARK.start_InMeetingJS_fromPreview
          : PERFORMANCE_MARK.start_InMeetingJS,
      );
      const store = configureStore();
      ctx({
        getState: store.getState,
        dispatch: store.dispatch,
        reduxStore: store,
        fromPreview: taskProps?.fromPreview,
      });
      return runMainClient(store);
    });
  });
};

export const taskRunMainClient = [mainClientJob, watchReduxInMeeting];
