import meetingConfig from 'meetingConfig';
import { easyStore } from '../../global/easy-store/index';
import { Task } from '@zoom/task-core';

export function createFullResourceUrl(...paths) {
  return paths.reduce((url, path) => {
    if (!path) {
      return url;
    }
    if (!url) {
      return path;
    }
    if (url.endsWith('/') && path.startsWith('/')) {
      return url + path.slice(1);
    }
    if (!url.endsWith('/') && !path.startsWith('/')) {
      return `${url}/${path}`;
    }
    return url + path;
  }, '');
}

export function getBaseUrl() {
  if (meetingConfig.cdnPath) {
    return meetingConfig.cdnPath;
  }
  if (meetingConfig.baseStaticUrl) {
    return `${meetingConfig.baseStaticUrl}${meetingConfig.baseStaticDirectory}`;
  }

  const scripts = document.getElementsByTagName('script');
  for (let i = 0; i < scripts.length; i++) {
    const src = scripts[i].src;
    if (src.indexOf('vendors.webclient.min.js') > -1) {
      return src.split('/js/')[0];
    }
  }
}

export const MEDIA_SDK_LOG_VERSION_ENABLE = 'MEDIA_SDK_LOG_VERSION_ENABLE';

function getNewMediasdkUrl() {
  const enableMediasdkLogVersionByUser = easyStore.easyGet(
    MEDIA_SDK_LOG_VERSION_ENABLE,
  );
  const { mediaCDNPath, mediaCDNPathForLogVersion } = meetingConfig;

  if (enableMediasdkLogVersionByUser && mediaCDNPathForLogVersion) {
    return mediaCDNPathForLogVersion;
  }

  if (mediaCDNPath) {
    return mediaCDNPath;
  }
  return false;
}

export function getMediasdkBaseUrl() {
  return getNewMediasdkUrl();
}

export function getVbResourceUrl() {
  let mediasdkUrl = getNewMediasdkUrl();
  return createFullResourceUrl(mediasdkUrl, 'vb-resource');
}

export function getMediasdkPackageVersion() {
  const url = getMediasdkBaseUrl();
  const regex = /web-media\/([^/]+)/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  return 'Not support';
}

// the dispatch come from ctx
// Strictly speaking, this is not clean, but it is very useful for some logic currently used for preview and in-meeting.
// This is a transition method before preview can use redux.
export const globalDispatch = (action) => {
  const { dispatch } = Task.checkInstance().ctx();
  if (dispatch) {
    dispatch(action);
  } else if (CLIENT_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error('no globalDispatch when call', action);
  }
};
