import meetingConfig from 'meetingConfig';
import { LoadJs } from '../../global/load-js';
import { getMediasdkBaseUrl } from '../../global/service';
import { isSinglePageFlowEnabled, isWebinar } from '../../../global/service';
import { isTPEnabledByOP } from '../../../global/service/tp-service';

let width = window.innerWidth;
let height = window.innerHeight;
function initCalcVH() {
  function calculateVh() {
    const currentHeight = window.innerHeight;
    const currentWidth = window.innerWidth;
    if (height !== currentHeight && width !== currentWidth) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    width = currentWidth;
    height = currentHeight;
  }

  // Initial calculation
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // Re-calculate on resize
  window.addEventListener('resize', calculateVh);

  // Re-calculate on device orientation change
  window.addEventListener('orientationchange', calculateVh);
}

export function loadScriptOnWebClientStart() {
  initCalcVH();
  return LoadJs.start(meetingConfig)
    .then((v) => {
      const collection = [];
      if (
        !isSinglePageFlowEnabled() &&
        (meetingConfig.meetingOptions.isPollingEnabled || isWebinar())
      ) {
        collection.push(v.add('js/lib/webim.min.js'));
      }
      collection.push(v.add('js/icons.webclient.min.js'));

      return Promise.all(collection);
    })
    .catch(console.error); // eslint-disable-line no-console
}

export function loadMeidiasdkScriptOnWebClientStart() {
  return LoadJs.start(meetingConfig)
    .then((v) => {
      const collection = [];
      collection.push(v.add(getMediasdkBaseUrl(), 'js_media.min.js'));
      if (isTPEnabledByOP()) {
        collection.push(v.add(getMediasdkBaseUrl(), 'tp.min.js'));
      }
      return Promise.allSettled(collection);
    })
    .catch(console.error); // eslint-disable-line no-console
}
