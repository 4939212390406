import { trackingId, logLimitationPool, logConfig } from '../laplace';
import { jsonStringify } from '@zoom/laplace/build/utils/helper-utils';
import {
  getBaseUrl,
  getMediasdkPackageVersion,
} from '../../../task/global/service';
import { directReport } from './direct-report';
import { escapeSensitiveContent } from '../escapeSensitiveContent';
import meetingConfig from '../../meetingConfig';
import { canAutoLog, isLaplaceEnabled } from '../is-laplace-enabled';
import md5 from '../../crypto/md5';
import _ from 'lodash';

export const getCodeVersion = () => {
  let webClientVersion = 'unknown';
  const webClientVersionMatches = getBaseUrl().match(/web_client_m\/([\w]+)/);
  if (webClientVersionMatches?.length === 2) {
    webClientVersion = webClientVersionMatches[1];
  }

  let mediaSDKVersion = getMediasdkPackageVersion();

  return `${webClientVersion}/${mediaSDKVersion}`;
};
export const AVS_TAGS = {
  mainTag: 'avs_telemetry',
  MediaSDK_Operation: 'MediaSDK_Operation', // call mediaSDK API
  user_action: 'user_action', // user action, such as click button
  MediaSDK_Callback: 'MediaSDK_Callback', // mediaSDK callback
  audio_telemetry: 'audio_telemetry',
  video_telemetry: 'video_telemetry',
  share_telemetry: 'share_telemetry',
  MediaSDK_Caps: 'MediaSDK_Caps',
};
const addZeroPlaceholder = (num, digit = 2) => {
  let str = `${num}`;
  while (str.length < digit) {
    str = `0${str}`;
  }

  return str;
};
export const timestampToTime = (timestamp) => {
  const dateBase = new Date(timestamp);
  const year = dateBase.getUTCFullYear();
  const month = addZeroPlaceholder(dateBase.getUTCMonth() + 1);
  const date = addZeroPlaceholder(dateBase.getUTCDate());
  const hour = addZeroPlaceholder(dateBase.getUTCHours());
  const minute = addZeroPlaceholder(dateBase.getUTCMinutes());
  const second = addZeroPlaceholder(dateBase.getUTCSeconds());
  const millisecond = addZeroPlaceholder(dateBase.getUTCMilliseconds(), 3);

  return `${year}-${month}-${date} ${hour}:${minute}:${second}.${millisecond}`;
};

const dataTemplate = (message, tags) => {
  return {
    time: timestampToTime(Date.now()),
    logLevel: 'log',
    message,
    tags,
    trackingId: trackingId,
    userAgent: navigator.userAgent,
    codeVersion: getCodeVersion(),
    accountId: escapeSensitiveContent(meetingConfig.accountId),
  };
};

let logDataPool = [];
const logger = (message, tags) => {
  const tmp = dataTemplate(
    message,
    tags.concat([AVS_TAGS.mainTag, 'MOBILE WEB CLIENT', trackingId]),
  );
  logDataPool.push(tmp);
};

const getStringSizeByByte = (str) => {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (let i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) {
      s += 1;
    } else if (code > 0x7ff && code <= 0xffff) {
      s += 2;
    }
    if (code >= 0xdc00 && code <= 0xdfff) {
      i -= 1;
    } //trail surrogate
  }
  return s;
};

const LOG_STR_SIZE_LIMIT_IN_KB = 30 * 1024;
export const reportAction = (isManualReport) => {
  if ((!isManualReport && !canAutoLog()) || !isLaplaceEnabled()) {
    return;
  }
  const clone = _.clone(logDataPool);
  logDataPool = [];
  return clone
    .reduce(
      (s, v) => {
        const line = jsonStringify(v);
        const lineSize = getStringSizeByByte(line);
        const group = s[s.length - 1];

        if (group.currentSize + lineSize > LOG_STR_SIZE_LIMIT_IN_KB) {
          // for next group
          s.push({ messages: [line], currentSize: lineSize });
        } else {
          group.messages.push(line);
          group.currentSize += lineSize;
        }
        return s;
      },
      [{ messages: [], currentSize: 0 }],
    )
    .filter((v) => v.currentSize > 0)
    .map((item) => item.messages.join('\n'))
    .forEach((v) => {
      directReport(v, { useRawData: true, isManualReport, isAVSLog: true });
    });
};
const report = _.debounce(reportAction, 5000);

const reportAndCheck = () => {
  if (logDataPool.length >= 50) {
    report.cancel();
    reportAction();
  } else {
    report();
  }
};
export const avsLogReport = (message, tags) => {
  if (isLaplaceEnabled()) {
    const hashCode = md5(message);
    logLimitationPool[hashCode] = logLimitationPool[hashCode] + 1 || 1;
    if (logLimitationPool[hashCode] > (logConfig.maxItemCount ?? 30)) {
      return;
    }
    logger(message, [].concat(tags));
    reportAndCheck();
  }
};
