import {
  rawStringToBuffer,
  concatUint8s,
  bytesArrayBuffer2String,
  utf8ArrayToStr,
} from '../util';
import { DECRYPT_FAILED_TEXT } from '../resource';
import { decodeBase64ToBuffer, encodeBase64 } from '../utils/base64';

function decodeUrlSafe64ViaNative(value) {
  return atob(value.replace(/_/g, '/').replace(/-/g, '+'));
}

const __CRYPTO__ = (window.crypto || window.msCrypto).subtle;

const PLAIN_TYPE_LEN = 4;

function _beginCrypto(rawSessionKey, userid, streamType) {
  const plain_text_data_stream_type_start = new Uint32Array(1);
  plain_text_data_stream_type_start[0] = 2 ^ 11;
  plain_text_data_stream_type_start[0] += Number(streamType);
  const plaintype = new Uint8Array(plain_text_data_stream_type_start.buffer);
  let salt = new Uint8Array(132);
  const useridArray = decodeBase64ToBuffer(userid);
  salt.set(useridArray, 0, useridArray.length);
  salt.set(plaintype, useridArray.length, useridArray.length + PLAIN_TYPE_LEN);

  salt = salt.subarray(0, useridArray.length + PLAIN_TYPE_LEN);

  return __CRYPTO__
    .importKey(
      'raw',
      rawSessionKey,
      {
        name: 'HMAC',
        hash: 'SHA-256',
      },
      true,
      ['sign'],
    )
    .then((hmacFinalKeyObj) => {
      return __CRYPTO__.sign(
        {
          name: 'HMAC',
        },
        hmacFinalKeyObj, // from generateKey or importKey above
        salt, // ArrayBuffer of data you want to sign
      );
    })
    .then(function (hmacSignature) {
      return hmacSignature;
    })
    .then((rawKeyData4GCM) => {
      // rawKeyData4GCM = rawStringToBuffer("11112222111122221111222211112222");
      // const hmacStr = utf8ArrayToStr(rawKeyData4GCM);
      return __CRYPTO__.importKey(
        'raw',
        rawKeyData4GCM,
        {
          name: 'AES-GCM',
          length: 256,
        },
        true,
        ['encrypt', 'decrypt'],
      );
    });
}

/**
 * public encrypt method
 */

// key = "11112222";
// iv = "33334444";
// plaintext = "55556666";

function beginEncrypt({
  text: rawMsg,
  type: typeMeta,
  aad = new Uint8Array(0),
}) {
  // rawMsg = "55556666";
  if (!easyStore.easyGet('gcmEnabled')) {
    return Promise.resolve(encodeBase64(rawMsg, true));
  }
  const tagBytes = 16;
  const IV_LEN = 12;
  // 16 bytes => 16 * 8 = 128 bit, 128 bit is default.
  const iv = easyStore.getNewIv(typeMeta.sessionType);
  const buffer = new ArrayBuffer(IV_LEN);
  const ivArrayBuffer = new Uint8Array(buffer);
  // const dataView = new DataView(buffer, 12, 4);
  const dataView = new DataView(buffer);
  dataView.setUint32(0, iv, true);

  let sessionKey = null;
  if (typeMeta.sessionType === 'BO_BROADCAST') {
    sessionKey = easyStore.getMainSessionKey();
  } else {
    sessionKey = easyStore.getRawSessionKey();
  }
  return _beginCrypto(
    sessionKey,
    easyStore.getCurrentUserId(),
    typeMeta.gcmType,
  )
    .then((gcmFinalKeyObj) => {
      const messageArrayBuffer = new TextEncoder().encode(rawMsg);
      return __CRYPTO__.encrypt(
        {
          name: 'AES-GCM',
          iv: ivArrayBuffer,
          additionalData: aad,
          tagLength: tagBytes * 8, // bit
        },
        gcmFinalKeyObj,
        messageArrayBuffer,
      );
    })
    .then((ciphertextAndTagArrayBuffer) => {
      // ciphertextArrayBuffer => cipherTextArrayBuffer concat tagArrayBuffer

      const ivLenBuffer = new ArrayBuffer(1);
      new Uint8Array(ivLenBuffer)[0] = ivArrayBuffer.length;

      // aad_len default value is 0
      const aadLenBuffer = new ArrayBuffer(2);
      new DataView(aadLenBuffer).setUint16(0, aad.length, true);

      const ciphertextLenBuffer = new ArrayBuffer(4);

      new DataView(ciphertextLenBuffer).setUint32(
        0,
        ciphertextAndTagArrayBuffer.byteLength - tagBytes,
        true,
      );

      let mergedBuffer = concatUint8s(ivLenBuffer, ivArrayBuffer);
      mergedBuffer = concatUint8s(mergedBuffer, aadLenBuffer);
      mergedBuffer = concatUint8s(mergedBuffer, aad);
      mergedBuffer = concatUint8s(mergedBuffer, ciphertextLenBuffer);
      mergedBuffer = concatUint8s(mergedBuffer, ciphertextAndTagArrayBuffer);
      const finalArrayBuffer = mergedBuffer.buffer;
      const finalAsciiString = bytesArrayBuffer2String(
        new Uint8Array(finalArrayBuffer),
      );
      // var rr = encodeBase64(finalAsciiString, true);
      return window
        .btoa(finalAsciiString)
        .replace(/_/g, '/')
        .replace(/-/g, '+')
        .replace(/\=/g, '');

      // return encodeBase64(finalAsciiString, true);
    });
}

/**
 *
 * @param ctext
 * @param streamType
 * @param sourceUserId
 * @returns {PromiseLike<{aad: Uint8Array, message: string, iv: Uint8Array}>}
 */
function beginDecrypt({
  decryptedText: ctext,
  type: typeMeta,
  userId: sourceUserId,
}) {
  if (!easyStore.easyGet('gcmEnabled')) {
    return Promise.resolve({
      message: utf8ArrayToStr(decodeBase64ToBuffer(ctext)),
    });
  }
  // const ctextUints = str2uint8s(decodeBase64(ctext), true);
  const ctextUints = rawStringToBuffer(decodeUrlSafe64ViaNative(ctext));
  /*   const ctextUints = str2uint8s(decodeBase64(ctext)); */
  const ivLengthInBytes = ctextUints[0];
  const ivUints = ctextUints.slice(1, ivLengthInBytes + 1);
  const aadLenUints = ctextUints.slice(
    1 + ivLengthInBytes,
    3 + ivLengthInBytes,
  );
  const aadLengthInBytes = new DataView(aadLenUints.buffer).getUint16(0, true);
  const additionalData = ctextUints.slice(
    1 + ivLengthInBytes + 2,
    1 + ivLengthInBytes + 2 + aadLengthInBytes,
  );
  const cipherTextLenUints = ctextUints.slice(
    1 + ivLengthInBytes + 2 + aadLengthInBytes,
    1 + ivLengthInBytes + 2 + aadLengthInBytes + 4,
  );
  const cipherTextLengthInBytes = new DataView(
    cipherTextLenUints.buffer,
  ).getUint32(0, true);
  const ciphertextUints = ctextUints.slice(
    1 + ivLengthInBytes + 2 + aadLengthInBytes + 4,
    1 + ivLengthInBytes + 2 + aadLengthInBytes + 4 + cipherTextLengthInBytes,
  );
  const tagUints = ctextUints.slice(
    1 + ivLengthInBytes + 2 + aadLengthInBytes + 4 + cipherTextLengthInBytes,
  );
  let sessionKey = null;
  if (typeMeta.sessionType === 'BO_BROADCAST') {
    sessionKey = easyStore.getMainSessionKey();
  } else {
    sessionKey = easyStore.getRawSessionKey();
  }
  return _beginCrypto(sessionKey, sourceUserId, typeMeta.gcmType)
    .then((gcmFinalKeyObj) => {
      const cipherAndTagUints = concatUint8s(ciphertextUints, tagUints);
      return __CRYPTO__.decrypt(
        {
          name: 'AES-GCM',
          iv: ivUints,
          additionalData,
          tagLength: tagUints.length * 8,
        },
        gcmFinalKeyObj,
        cipherAndTagUints.buffer,
      );
    })
    .then((decryptedBuf) => {
      const message = new TextDecoder().decode(decryptedBuf);
      return {
        message,
        iv: ivUints,
        aad: additionalData,
      };
    })
    .catch((e) => {
      return {
        message: `[${DECRYPT_FAILED_TEXT}]`,
        iv: ivUints,
        aad: additionalData,
      };
    });
}

export { beginEncrypt, beginDecrypt };
