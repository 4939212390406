import _ from 'lodash';

const meetingConfig = _.assign(
  {},
  {
    isSupportAV: true,
    isNewJoinFlow: true,
  },
  window.MeetingConfig,
  {
    debug: CLIENT_ENV === 'development',
  },
);

export const setField = (key, value) => {
  if (!key) {
    return;
  }
  meetingConfig[key] = value;
};

export const updateMeetingConfig = (config) => {
  _.extend(meetingConfig, config);
};

export default meetingConfig;
