import {
  globalInfo,
  globalError,
  globalWarn,
  globalSocket,
  globalSuccess,
  globalInfoGroup,
  globalSuccessGroup,
} from './logger';
import meetingConfig from 'meetingConfig';
import * as ZoomSocketEventTypes from '../constants/ZoomSocketEventTypes';
import * as AVNotifyAPPTypes from '../constants/AVNotifyAPPTypes';
import * as AVNotifyMediaSDKTypes from '../constants/AVNotifyMediaSDKTypes';
import _ from 'lodash';

function isElement(element) {
  return element instanceof Element || element instanceof HTMLDocument;
}

let invertZoomSocketEventTypes;
let invertAVNotifyAPPTypes;
let invertAVNotifyMediaSDKTypes;

if (meetingConfig.debug) {
  invertZoomSocketEventTypes = _.invert(
    JSON.parse(JSON.stringify(ZoomSocketEventTypes)),
  );
  invertAVNotifyAPPTypes = _.invert(
    JSON.parse(JSON.stringify(AVNotifyAPPTypes)),
  );
  invertAVNotifyMediaSDKTypes = _.invert(
    JSON.parse(JSON.stringify(AVNotifyMediaSDKTypes)),
  );
}

const getZoomSocketEventTypeName = (type) => invertZoomSocketEventTypes[type];
const getAVNotifyAPPTypesName = (type) => invertAVNotifyAPPTypes[type];
const getAVNotifyMediaSDKTypeName = (type) => invertAVNotifyMediaSDKTypes[type];

const successLog = (msg) => {
  globalSuccess(msg);
};

const errorLog = (msg) => {
  globalError(msg);
};

const warningLog = (msg) => {
  globalWarn(msg);
};

const infoLog = (msg) => {
  globalInfo(msg);
};

const infoGroupMsg = (topLevelMsg, secondLevelMsg) => {
  globalInfoGroup(topLevelMsg, secondLevelMsg);
};

const successGroupMsg = (topLevelMsg, secondLevelMsg) => {
  globalSuccessGroup(topLevelMsg, secondLevelMsg);
};

const tableLog = (msg) => {
  if (CLIENT_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.table(msg);
  }
};

const rwgInboundMsg = (msg) => {
  if (meetingConfig.debug) {
    if (!msg.evtType && msg.evt) {
      globalSocket(
        'RWG',
        'RECV',
        getZoomSocketEventTypeName(msg.evt),
        JSON.stringify(msg),
      );
    } else {
      globalSocket('RWG', 'RECV', msg.evtType, JSON.stringify(msg));
    }
  }
};

const rwgOutboundMsg = (msg, type) => {
  if (meetingConfig.debug) {
    globalSocket(
      'RWG',
      'SENT',
      getZoomSocketEventTypeName(type),
      JSON.stringify(msg),
    );
  }
};

const AVSocketInboundMsg = (msg, type) => {
  if (meetingConfig.debug) {
    globalSocket(
      'AVSocket',
      'RECV',
      getAVNotifyAPPTypesName(type),
      JSON.stringify(msg),
    );
  }
};

const AVSocketOutboundMsg = (msg, type) => {
  if (
    msg &&
    !_.find(Object.keys(msg), (e) => isElement(msg[e])) &&
    meetingConfig.debug
  ) {
    globalSocket(
      'AVSocket',
      'SENT',
      getAVNotifyMediaSDKTypeName(type),
      JSON.stringify(msg),
    );
  }
};

const XMPPLog = (msg, action) => {
  if (meetingConfig.debug) {
    globalSocket('XMPP', 'RECV', action, JSON.stringify(msg));
  }
};

export {
  successLog,
  errorLog,
  warningLog,
  infoLog,
  infoGroupMsg,
  successGroupMsg,
  rwgInboundMsg,
  rwgOutboundMsg,
  AVSocketInboundMsg,
  AVSocketOutboundMsg,
  XMPPLog,
  tableLog,
};
