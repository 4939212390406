import { unmountComponentAtNode } from 'react-dom';
import { pingRWC } from '../../global/service/rwc';
import { fetchProfile } from '../../global/service/profile';
import { isSinglePageFlowEnabled } from '../../global/service/index';
import { createRWGConnect } from '../../global/socket/command-socket/webclient-websocket';
import { getBaseUrl } from '../global/service';
import { PERFORMANCE_MARK, performanceMark } from '../../performance';

function previewJob({ cleanJobRegister }) {
  // eslint-disable-next-line camelcase, no-undef
  __webpack_public_path__ = `${getBaseUrl()}/`;
  import(
    /* webpackMode: "lazy" */
    /* webpackChunkName: "preview" */
    '../../features/preview/task'
  ).then(({ runPreview }) => {
    performanceMark(PERFORMANCE_MARK.start_PreviewJS);
    cleanJobRegister(() => {
      unmountComponentAtNode(document.getElementById('root'));
    });
    return runPreview();
  });
}

function pingRWCJob() {
  pingRWC().then((res) => {
    if (isSinglePageFlowEnabled()) {
      createRWGConnect(res);
    }
  });
}

function fetchProfileJob() {
  fetchProfile();
}

export const taskRunPreview = [previewJob, pingRWCJob, fetchProfileJob];
