import meetingConfig from 'meetingConfig';

export function initUserName() {
  const { sessionUserName, originName } = this.sessionStorageData;
  const newOriginName = meetingConfig.userName;
  let userName = null;

  if (
    !sessionUserName ||
    (originName &&
      originName !== newOriginName &&
      sessionUserName !== newOriginName)
  ) {
    userName = newOriginName;
    Object.assign(this.memory, { isOldUser: false, isOldRwgUser: false });
  } else if (sessionUserName) {
    userName = sessionUserName;
    Object.assign(this.memory, { isOldUser: true, isOldRwgUser: true });
  }

  Object.assign(this.sessionStorageData, {
    sessionUserName: userName,
    originName: newOriginName,
  });
}

export function isOldUserName() {
  return this.memory.isOldUser;
}

export function isOldRWGUserName() {
  const tmp = this.memory.isOldRwgUser;
  if (!this.memory.isOldRwgUser) {
    this.memory.isOldRwgUser = true;
  }
  return tmp;
}
