import {
  initUserName,
  isOldRWGUserName,
  isOldUserName,
} from './username-method';
import { initStorageKey } from './common-method';
import {
  gcmInit,
  getCurrentUserId,
  getIv,
  getMainSessionKey,
  getNewIv,
  getRawSessionKey,
  initGcmMemory,
  initIVState,
  setIv,
} from './gcm-method';

export const FIRST_BEFORE_READ_STORAGE = {
  initStorageKey,
};

export const FIRST_AFTER_READ_STORAGE = {
  initUserName,
  initIVState,
};

export const FAIL_OVER_BEFORE_READ_STORAGE = {
  initGcmMemory,
};

export const FAIL_OVER_AFTER_READ_STORAGE = {
  initIVState,
};

export const CUSTOMER = {
  isOldUserName,
  isOldRWGUserName,
  getNewIv,
  gcmInit,
  getIv,
  setIv,
  getRawSessionKey,
  getMainSessionKey,
  getCurrentUserId,
};

export const easyStoreConfig = {
  firstBeforeReadStorage: FIRST_BEFORE_READ_STORAGE,
  firstAfterReadStorage: FIRST_AFTER_READ_STORAGE,
  failOverBeforeReadStorage: FAIL_OVER_BEFORE_READ_STORAGE,
  failOverAfterReadStorage: FAIL_OVER_AFTER_READ_STORAGE,
  customer: CUSTOMER,
};
