import { Task } from '@zoom/task-core';
import { taskBeforeLoadMainClient } from './main-task/before-load-main-client/task.before-load-main-client';
import { taskRunMainClient } from './main-task/task.run-main-client';
import { taskLeaveMainClient } from './main-task/task.leave-main-client';
import { taskRunPreview } from './main-task/task.run-preview';
import getMainTask from './main-task/before-load-main-client/getMainTask';
import { globalVariable } from '../global/global-variable';
import { taskRequestJoinMeeting } from './main-task/task.request-join-meeting';
import { taskRunWaitingForHost } from './main-task/task.run-waiting-for-host';

globalVariable.wcCTX = (props) => {
  return Task.checkInstance().ctx(props);
};
export const initTask = () => {
  const req = require.context('./', true, /task\.[^/]+?\.js$/);
  Task.init(
    [
      {
        current: taskBeforeLoadMainClient,
        next: getMainTask,
        isEntry: true,
      },
      {
        current: taskRequestJoinMeeting,
        next: [taskRunWaitingForHost, taskRunMainClient],
      },
      {
        current: taskRunWaitingForHost,
        next: taskRunMainClient,
      },
      {
        current: taskRunPreview,
        next: taskRunMainClient,
      },
      {
        current: taskRunMainClient,
        next: taskLeaveMainClient,
      },
      {
        current: taskLeaveMainClient,
        isExit: true,
      },
    ],
    req,
    CLIENT_ENV,
  );
};
