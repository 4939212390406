import {
  BlackBoxEvent,
  blackBoxListenEvent,
  cancelBlackBoxJob,
  expectEvent,
} from '../../../global/service/black-box-service';
import { directReport } from '../../../global/logger/log-service/direct-report';

const eventList = Object.values(BlackBoxEvent);
const reportError = (v) => {
  const value = v.toString();
  if (!value.includes('canceled_event')) {
    if (!eventList.some((x) => value.includes(x))) {
      // eslint-disable-next-line no-console
      console.error(value, 'black_box');
    }
    directReport({ msg: value }, { tags: ['black_box'] });
  }
};

const additionalErrorMsg = (msg) => (v) => {
  throw new Error(`${v}, ${msg}`);
};
export const blackBoxProblemDetection = () => {
  const joinFlowBlackBox = async () => {
    let current = await expectEvent(
      BlackBoxEvent.just_start_meeting,
      BlackBoxEvent.just_start_preview,
      BlackBoxEvent.skip_preview_to_start_meeting,
      BlackBoxEvent.skip_preview_to_start_JBH,
    ).wait(15000);
    if (
      current.event === BlackBoxEvent.just_start_meeting ||
      current.event === BlackBoxEvent.skip_preview_to_start_meeting
    ) {
      await expectEvent(BlackBoxEvent.inMeeting_hideLoading)
        .wait(30000)
        .catch(additionalErrorMsg('bad when directly join meeting'));
    } else if (current.event === BlackBoxEvent.just_start_preview) {
      await expectEvent(BlackBoxEvent.preview_hideLoading).wait(15000);
      current.event = await expectEvent(
        BlackBoxEvent.preview_to_JBH,
        BlackBoxEvent.join_meeting_from_preview,
      ).wait();
      if (current.event === BlackBoxEvent.join_meeting_from_preview) {
        await expectEvent(BlackBoxEvent.inMeeting_hideLoading)
          .wait(30000)
          .catch(additionalErrorMsg('bad when join meeting from preview'));
      } else if (current.event === BlackBoxEvent.preview_to_JBH) {
        await expectEvent(BlackBoxEvent.join_meeting_from_preview).wait();
        await expectEvent(BlackBoxEvent.inMeeting_hideLoading)
          .wait(30000)
          .catch(additionalErrorMsg('bad when join meeting from JBH'));
      }
    } else if (current.event === BlackBoxEvent.skip_preview_to_start_JBH) {
      await expectEvent(BlackBoxEvent.preview_hideLoading).wait(15000);
      await expectEvent(BlackBoxEvent.join_meeting_from_preview).wait();
      await expectEvent(BlackBoxEvent.inMeeting_hideLoading)
        .wait(30000)
        .catch(
          additionalErrorMsg(
            'bad when join meeting from JBH which skip the preview',
          ),
        );
    }
  };
  const joinAudioBlackBox = async () => {
    await expectEvent(BlackBoxEvent.audio_ready_to_use).wait(20000);
    await expectEvent(BlackBoxEvent.start_joining_audio).wait();
    let current = await expectEvent(
      BlackBoxEvent.join_audio_success,
      BlackBoxEvent.join_audio_timeout,
    ).wait(30000);
    if (current.event === BlackBoxEvent.join_audio_timeout) {
      throw new Error(BlackBoxEvent.join_audio_timeout);
    }
  };
  const openVideoBlackBox = async () => {
    await expectEvent(BlackBoxEvent.video_usable).wait();
    await expectEvent(BlackBoxEvent.video_initial_ready).wait(20000);
    await expectEvent(BlackBoxEvent.start_opening_video).wait();
    await expectEvent(BlackBoxEvent.open_video_success).wait(15000);
  };
  const joinMeetingJob = async () => {
    const ret = await joinFlowBlackBox().catch((v) => {
      reportError(`${v} first_join`);
      return 'break';
    });
    if (ret !== 'break') {
      joinAudioBlackBox().catch(reportError);
      openVideoBlackBox().catch(reportError);
    }
  };

  const failOverJob = async () => {
    const ret = await expectEvent(BlackBoxEvent.inMeeting_hideLoading)
      .wait(30000)
      .catch((v) => {
        reportError(`${v} in_failOver`);
        return 'break';
      });
    if (ret !== 'break') {
      joinAudioBlackBox().catch(reportError);
      openVideoBlackBox().catch(reportError);
    }
  };
  joinMeetingJob();
  blackBoxListenEvent(BlackBoxEvent.reset, () => {
    cancelBlackBoxJob();
    failOverJob();
  });
};
