/* eslint-disable @babel/no-invalid-this */
import { isIrregularGcmBrowser } from '../service';
import { ivType } from '../crypto';
import { decodeBase64ToBuffer } from '../utils/base64';

const webClientIvTypes = Object.keys(ivType).map(
  (key) => ivType[key].sessionType,
);

export function initGcmMemory() {
  this.memory.gcmSessionKey = null;
  this.memory.currentUserId = null;
  this.memory.gcmEnabled = false;
  this.sessionStorageData.ivState = undefined;
}

export function initIVState() {
  if (this.sessionStorageData.ivState === undefined) {
    this.sessionStorageData.ivState = {};
    return;
  }
  const keys = Object.keys(this.sessionStorageData.ivState).filter(
    (key) => webClientIvTypes.indexOf(key) > -1,
  );

  if (keys.length !== 0) {
    keys.forEach((key) => {
      this.sessionStorageData.ivState[key] += 1000000;
    });
  }
}

export function getNewIv(ivKey) {
  if (this.sessionStorageData.ivState[ivKey] === undefined) {
    return this.setIv(ivKey, 0);
  }

  return this.setIv(ivKey, this.sessionStorageData.ivState[ivKey] + 1);
}

export function setIv(ivKey, ivValue = 0) {
  if (this.sessionStorageData.ivState[ivKey] === ivValue) {
    return ivValue;
  }

  this.sessionStorageData.ivState[ivKey] = ivValue;

  this.saveSessionStorage();

  return this.sessionStorageData.ivState[ivKey];
}

export function getIv(ivKey) {
  return this.sessionStorageData.ivState[ivKey];
}

export function gcmInit(currentUserId, gcmSessionKey, gcmEnabled) {
  this.memory.currentUserId = currentUserId;
  this.memory.gcmSessionKey = decodeBase64ToBuffer(gcmSessionKey);

  if (!this.memory.gcmMainSessionKey) {
    this.memory.gcmMainSessionKey = this.memory.gcmSessionKey;
  }

  if (!isIrregularGcmBrowser()) {
    this.memory.gcmEnabled = gcmEnabled;
  }
}

export function getRawSessionKey() {
  return this.memory.gcmSessionKey;
}

export function getMainSessionKey() {
  return this.memory.gcmMainSessionKey;
}

export function getCurrentUserId() {
  return this.memory.currentUserId;
}
