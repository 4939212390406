import { Base64 } from 'js-base64';
import URLSafeBase64 from 'urlsafe-base64';

export function encodeUnsafeBase64(value) {
  return Base64.encode(value, false);
}
export function decodeUnsafeBase64(value) {
  return Base64.decode(value, false);
}

/* url safe mode of base64 is required for any system-wide data encoding/decoding */
export function encodeBase64(value) {
  return Base64.encode(value, true);
}
/* url safe mode of base64 is required for any system-wide data encoding/decoding */
export function decodeBase64(value) {
  return Base64.decode(value, true);
}

export function decodeBase64ToBuffer(value) {
  return URLSafeBase64.decode(value);
}

window.decodeBase64 = decodeBase64;
window.encodeBase64 = encodeBase64;
window.encodeUnsafeBase64 = encodeUnsafeBase64;
window.decodeUnsafeBase64 = decodeUnsafeBase64;
