import {
  use_webRTC_strategy_auto,
  use_webRTC_strategy_disabeld,
  use_webRTC_strategy_enabled,
} from './resource';

export const USE_WEBRTC_STRATEGY = {
  DISABLED: 0,
  ENABLED: 1,
  AUTO: 2,
};

export const useWebRTCStrategValueMap = {
  [USE_WEBRTC_STRATEGY.ENABLED]: use_webRTC_strategy_enabled,
  [USE_WEBRTC_STRATEGY.DISABLED]: use_webRTC_strategy_disabeld,
  [USE_WEBRTC_STRATEGY.AUTO]: use_webRTC_strategy_auto,
};
