/* eslint-disable no-console */
/* eslint-disable prefer-spread */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import log from 'loglevel';
import meetingConfig from 'meetingConfig';
import _ from 'lodash';

(function (wcLog) {
  const logLevels = wcLog.levels;
  Object.keys(logLevels).reduce((logger, key) => {
    const originalFuncName = key.toLowerCase();
    logger[`${originalFuncName}Group`] = function (groupLabel, logMsg) {
      const isLogEnabled = logger.getLevel() <= logLevels[key];

      if (isLogEnabled) {
        console.groupCollapsed.apply(console, groupLabel);
      }
      logger[originalFuncName].apply(logger, logMsg);
      if (isLogEnabled) {
        console.groupEnd();
      }
    };
    return logger;
  }, wcLog);
})(log);

if (meetingConfig.debug) {
  log.setLevel('trace');
} else {
  log.setLevel('error');
}

const boldRedStyle = 'color: #ff0000; font-weight: bold;';
const boldGreenStyle = 'color: #00bb44; font-weight: bold;';
const blueStyle = 'color: #aabdf0';
const boldBlueStyle = 'color: #aabdf0; font-weight: bold;';
const boldHeavyBlueStyle = 'color: #84affa; font-weight: bold;';
const boldYellowStyle = 'color: #b3b300; font-weight: bold;';

const multiIncludes = (text, values) => {
  const re = new RegExp(values.join('|'));
  return re.test(text);
};

const globalError = (msg) => {
  log.error(`%c${msg}`, boldRedStyle);
};

const globalWarn = (msg) => {
  log.warn(`%c${msg}`, boldYellowStyle);
};

const globalInfo = (msg) => {
  log.info(`%c${msg}`, boldBlueStyle);
};

const globalSuccess = (msg) => {
  log.info(`%c${msg}`, boldGreenStyle);
};

const globalSocket = (socketName, socketLogType, messageType, res) => {
  if (!res) return;
  let messageTypeColor = boldHeavyBlueStyle;
  if (multiIncludes(messageType, ['SUCCESS'])) {
    messageTypeColor = boldGreenStyle;
  } else if (multiIncludes(messageType, ['FAILED', 'BROKEN', 'ERROR'])) {
    messageTypeColor = boldRedStyle;
  }
  try {
    log.infoGroup(
      [
        `%c${_.padEnd(socketName, 10)} %c${socketLogType} %c${_.padEnd(
          ' message, type: ',
          8,
        )}%c${messageType}`,
        boldHeavyBlueStyle,
        boldHeavyBlueStyle,
        boldBlueStyle,
        messageTypeColor,
      ],
      [`%c${res}`, blueStyle],
    );
  } catch (error) {
    globalError(error.message);
  }
};

const globalInfoGroup = (topLevel, secondLevel) => {
  log.infoGroup(
    [`%c${topLevel}`, boldBlueStyle],
    [`%c${secondLevel}`, blueStyle],
  );
};

const globalSuccessGroup = (topLevel, secondLevel) => {
  log.infoGroup(
    [`%c${topLevel}`, boldGreenStyle],
    [`%c${secondLevel}`, boldGreenStyle],
  );
};

export {
  globalInfo,
  globalError,
  globalWarn,
  globalSocket,
  globalSuccess,
  globalInfoGroup,
  globalSuccessGroup,
};
