import * as socketTypes from '../../constants/ZoomSocketEventTypes';
import {
  CLIENT_CAPS_SUPPORT_PREVIEW,
  CLIENT_CAPS_SUPPORT_SHARE_MULTI_STREAM,
  CLIENT_CAPS_SUPPORT_VIDEO_LTR,
  CLIENT_CAPS_WEBRTC_AUDIO_RTCP_TIME,
  CLIENT_CAPS_SUPPORT_VIDEO_SHARE,
  CLIENT_CAPS_WEBINAR_NO_NEED_TYPE_EMAIL,
} from '../constant';
import { isSinglePageFlowEnabled, isAudioBridge } from './index';
import meetingConfig from 'meetingConfig';
import { isInPreview } from '../../task/service';
import { isSupportAudioBridgeAvsync } from '../util';
import meetingField from 'meetingField';
import { CLIENT_CAPS_EX_SUPPORT_ProcessFalseInitialbHoldValue } from '../clientCapsEx';
export const sendLaunchParams = (params, sendFn) => {
  sendFn({
    evt: socketTypes.WS_CONF_LAUNCH_PARAMS_REQ,
    body: params,
  });
};

function setCaps(caps, currentCap) {
  return caps | currentCap;
}

export const getClientCap = () => {
  let caps = 0;
  if (isSinglePageFlowEnabled() && !meetingConfig.isStart && isInPreview()) {
    caps = caps | CLIENT_CAPS_SUPPORT_PREVIEW;
  }

  if (isAudioBridge && isSupportAudioBridgeAvsync()) {
    caps = setCaps(caps, CLIENT_CAPS_WEBRTC_AUDIO_RTCP_TIME);
  }

  if (JsMediaSDK_Instance.util.isSupportShareMultiStream?.()) {
    caps = setCaps(caps, CLIENT_CAPS_SUPPORT_SHARE_MULTI_STREAM);
  }
  if (JsMediaSDK_Instance.util.isSupportVideoLTR?.()) {
    caps = setCaps(caps, CLIENT_CAPS_SUPPORT_VIDEO_LTR);
  }
  if (JsMediaSDK_Instance?.util?.isSupportVideoShareReceive?.()) {
    caps = setCaps(caps, CLIENT_CAPS_SUPPORT_VIDEO_SHARE);
  }
  if (!meetingConfig.enableCustomEmailByUser && meetingField.emailVal === '') {
    caps = setCaps(caps, CLIENT_CAPS_WEBINAR_NO_NEED_TYPE_EMAIL);
  }
  return caps;
};

export const getClientCapsEx = () => {
  let capEx = CLIENT_CAPS_EX_SUPPORT_ProcessFalseInitialbHoldValue;
  return capEx;
};
