export const START_MEDIA = 0;
export const ADD_RENDER_VIDEO = 1;
export const STOP_RENDER_VIDEO = 2;
export const START_CAPTURE_VIDEO = 3;
export const STOP_CAPTURE_VIDEO = 4;
export const ADD_RENDER_AUDIO = 5;
export const ADD_CAPTURE_AUDIO = 7;
export const STOP_CAPTURE_AUDIO = 8;
export const CHANGE_FRAME_RATE = 9;
export const CHANGE_VIDEO_RESOLUTION = 10;
export const CHANGE_AUDIO_SPEAKER = 11;
export const CHANGE_VIDEO_CAPTURE_DEVICE = 12;
export const UPDATE_CANVAS_SIZE = 46;
export const CLEAR_ALL_VIDEO_IN_MAIN_CANVAS = 47;
export const CHANGE_CURRENT_ACTIVE_SSRC = 13;
export const LEAVE_MEETING = 15;
export const MEETING_FAIL_OVER = 16;
export const END_MEDIA = 17;
export const CHANGE_AUDIO_MIC = 18;
export const WEBRTC_RESTART = 19;
export const LEAVE_COMPUTER_AUDIO = 21;
export const JOIN_COMPUTER_AUDIO = 22;
export const START_SHARING = 23;
export const STOP_SHARING = 24;
export const SWITCH_CANVAS_FOR_VIDEO_CAPTURE = 25;
export const START_REMOTE_CONTROL = 26;
export const UPDATE_REMOTE_CONTROL_PROPERTIES = 27;
export const CANCEL_REMOTE_CONTROL = 28;
export const CHANGE_CURRENT_SHARING_ACTIVE_SSRC = 36;
export const COMMAND_SOCKET_MESSAGE_NOTIFY = 40;
export const AES_GCM_IV_VALUE = 42;
export const USER_NODE_LIST = 43;
export const PAUSE_OR_RESUME_AUDIO_DECODE = 45;
export const ZOOM_RENDER = 48;
export const USER_NODE_AUDIO_STATUS_LIST = 51;
export const UPDATE_MASK_CANVAS_SIZE = 52;
export const AUDIO_CC_SELECT_LANGUAGE = 57;

export const VIDEO_MASK_SETTING = 62;
export const FINISH_MASK_SETTING = 65;

export const USER_NODE_LIST_IN_MAIN_SESSION = 80;
export const UPDATE_MEDIA_PARAMS = 81;
export const SHARING_ADD_REV_CHANNEL_TYPE = 82;
export const SHARING_REMOVE_REV_CHANNEL_TYPE = 83;
export const BUILD_MS_CHANNEL_IN_BO = 84;
export const BUILD_MA_CHANNEL_IN_BO = 85;
/** This is for notifying media sdk 4098 rwg signal received, media sdk can send message to rwg now */
export const NOTIFY_SDK_JOIN_RWG_SUCCESS = 113;

export const WEBGL_LOST_REPLACE_CANVAS = 77;

export const SWITCH_WATER_MARK_FLAG = 90;

export const NEW_ACTIVE_SPEAKER_SSRC = 110;

export const CANCEL_NEW_ACTIVE_SPEAKER_BEFORE_CALL_BACK = 112;
export const ENABLE_VIDEO_OBSERVER = 99;
export const UPDATE_VIDEOHD_VALUE = 'update_videohd_value';
export const UPDATE_VIDEOFULLHD_VALUE = 'update_videofullhd_value';
export const VIDEO_ENABLE_HW_RECEIVING = 70; // hardware acceleration for receiving video
export const VIDEO_ENABLE_HW_SENDING = 71;
export const PRESET_MEDIA_CONSTRAINTS = 'PRESET_MEDIA_CONSTRAINTS';
export const SET_CODEC_MODE = 122;
export const MOBILE_ROTATE = 124;
export const RWG_COMMAND_BYPASS_TO_WCL = 128;
