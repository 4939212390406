import { Task } from '@zoom/task-core';
import { taskBeforeLoadMainClient } from './main-task/before-load-main-client/task.before-load-main-client';
import { taskLeaveMainClient } from './main-task/task.leave-main-client';
import getMainTask from './main-task/before-load-main-client/getMainTask';
import { taskHandleRouter } from './optional-task/task.handle-router';
import { taskInitLogManager } from './optional-task/task.init-log-manager';

export const runTaskBeforeLoadMainClient = () => {
  return Task.run({
    taskProps: '',
    expectMainTask: taskBeforeLoadMainClient,
  });
};

export const runTaskLoadMainClient = () => {
  return Task.run({
    taskProps: '',
    expectMainTask: getMainTask(),
  });
};

export const runTaskLeaveMainClient = () => {
  return Task.run({
    taskProps: '',
    expectMainTask: taskLeaveMainClient,
  });
};

export function handleRouter() {
  return Task.run({
    optionalTask: taskHandleRouter,
  });
}

export function initLogManager() {
  return Task.run({
    optionalTask: taskInitLogManager,
  });
}
