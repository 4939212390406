import meetingConfig from 'meetingConfig';
import _ from 'lodash';
import axios from 'axios';
import ConnectService from '@zoom/connect-service';
import { getMeetingTokens } from './meetingToken';
import { easyStore, storeType } from '../easy-store';
import { getJoinMeetingLog } from '../util';
import { makeLogger, reportToGlobalTracing } from '../logger/laplace';
import { makeQuery } from '../socket/command-socket/url-utils';
import { getRWCOption } from './web-RTC-service';

const logger = makeLogger(['Join Meeting Flow']);

function constructRWCUrl(rwcBaseUrl) {
  return `https://${rwcBaseUrl}/wc/ping/${meetingConfig.meetingNumber}`;
}

export const getPingRWCUrls = (boConfId) => {
  const meetingTokens = getMeetingTokens();
  const { encryptedGEORWC, encryptedRWC, ts, auth, tid } = meetingTokens;
  const { isEnableGeoFenceRWC } = meetingConfig?.meetingOptions ?? {};
  const aid = meetingConfig.enableHybridRWG ? meetingConfig.accountId : '';

  const query = (rwcToken) =>
    makeQuery([
      ['ts', ts],
      ['auth', encodeURIComponent(auth)],
      [boConfId && 'confID', boConfId],
      ['rwcToken', rwcToken],
      ['dmz', 1],
      ['option', getRWCOption()],
      ['tid', encodeURIComponent(tid)],
      [aid && 'aid', aid],
    ]);
  if (isEnableGeoFenceRWC) {
    if (!_.isEmpty(encryptedGEORWC)) {
      return encryptedGEORWC.map((rwc) => ({
        url: `${constructRWCUrl(rwc.rwc)}?${query(rwc.rwcToken)}`,
        domain: rwc.rwc,
        rwcToken: rwc.rwcToken,
        dl: rwc.dl,
        dc: rwc.dc,
      }));
    }
  } else if (!_.isEmpty(encryptedRWC)) {
    return Object.keys(encryptedRWC).map((domain) => ({
      url: `${constructRWCUrl(domain)}?${query(encryptedRWC[domain])}`,
      domain,
      rwcToken: encryptedRWC[domain],
    }));
  }
  let log = isEnableGeoFenceRWC
    ? JSON.stringify({ encryptedGEORWC })
    : JSON.stringify({ encryptedRWC });
  logger.log(getJoinMeetingLog(log), ['PING_RWC_DOMAIN_EMPTY']);
  reportToGlobalTracing({ filter: ['PING_RWC_DOMAIN_EMPTY'] });
  return [];
};

const rwcResponseKey = 'WEB-CLIENT-RWC-RESPONSE';
export const getRWCResponse = () => {
  const valInStore = easyStore.easyGet(rwcResponseKey);
  if (valInStore == null) {
    return [];
  }
  return valInStore;
};

export const appendRWCResponse = (rwcResponse) => {
  const arr = getRWCResponse();
  if (arr.findIndex((item) => item.rwg === rwcResponse.rwg) === -1) {
    arr.push(rwcResponse);
    easyStore.easySet(rwcResponseKey, arr, storeType.memory);
  }
};
function doPingPWC(boConfId) {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    const { meetingOptions } = meetingConfig;
    const { isEnableGeoFenceRWC } = meetingOptions;

    const pingRWCUrls = getPingRWCUrls(boConfId);
    if (_.isEmpty(pingRWCUrls)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return reject(Error('ping rwc url'));
    }
    if (isEnableGeoFenceRWC) {
      ConnectService.ping(pingRWCUrls)
        .then(({ response, request, log }) => {
          let data = {};
          if (response) {
            data = { ...response };
          } else {
            data = {
              rwg: request.domain,
              rwcToken: request.rwcToken,
            };
          }
          logger.log(getJoinMeetingLog(log), ['PING_RWC_SUCCESS']);
          reportToGlobalTracing({ filter: ['PING_RWC_SUCCESS'] });
          return resolve({ data, log });
        })
        .catch((error) => {
          logger.log(getJoinMeetingLog(error), ['PING_RWC_FAILED']);
          reportToGlobalTracing({ filter: ['PING_RWC_FAILED'] });
          return reject(error);
        });
    } else {
      let failCount = 0;
      pingRWCUrls.forEach((rwc) => {
        axios
          .get(rwc.url)
          .then(({ data }) => {
            let res = {};
            if (data) {
              res = { ...data };
            } else {
              res = {
                rwg: rwc.domain,
                rwcToken: rwc.rwcToken,
              };
            }
            appendRWCResponse(res);
            return resolve({ data: res });
          })
          // eslint-disable-next-line consistent-return
          .catch((error) => {
            failCount += 1;
            if (failCount === pingRWCUrls.length) {
              return reject(error);
            }
          });
      });
    }
  });
}

let promise = null;
export function pingRWC(isReconnect, boConfId) {
  if (isReconnect || !promise) {
    promise = doPingPWC(boConfId);
  }
  return promise;
}

export function clearPingRWC() {
  promise = null;
}
