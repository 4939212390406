import { shallowEqual } from 'react-redux';
import { Task } from '@zoom/task-core';
let handlersPool = {};
let count = 0;
const getUniqHandlerKey = (key) => {
  if (key) {
    return key;
  }
  count += 1;
  return `handler_${count}`;
};
const watchHandlers = (state) => {
  Object.values(handlersPool)
    .filter((v) => v)
    .forEach((handler) => {
      handler(state);
    });
};

export const watchReduxInMeeting = ({ ctx, cleanJobRegister }) => {
  const CTX = ctx();
  const { reduxStore, getState } = CTX;
  const unsubscribe = reduxStore.subscribe(() => {
    setTimeout(() => {
      watchHandlers(getState());
    });
  });
  cleanJobRegister(() => {
    unsubscribe();
    handlersPool = [];
  });
};

export const stopReduxSubscribe = (key) => {
  if (handlersPool[key]) {
    handlersPool[key]?.reject();
  }
};
export const reduxSubscribeRegister = ({
  selector,
  handler = (v) => v.value,
  timeout,
  times,
  enable = () => true,
  key: _key,
  waitFor,
  timeoutMessage = 'timeout',
  start = () => {}, // get the current value of selector
  leading = false,
}) => {
  return new Promise((resolve, reject) => {
    let resolved = false;
    let timeoutHandler = 0;
    const key = getUniqHandlerKey(_key);
    let count = [];
    const myResolve = (v) => {
      handlersPool[key] = null;
      if (timeout) {
        clearTimeout(timeoutHandler);
      }
      if (!resolved) {
        resolved = true;
        resolve(v);
      }
    };
    const myReject = () => {
      handlersPool[key] = null;
      if (timeout) {
        clearTimeout(timeoutHandler);
      }
      reject('stop');
    };
    const { getState } = Task.checkInstance().ctx();
    let previousValue = selector(getState());
    const startReturnValue = start?.(previousValue, getState);
    handlersPool[key] = (state) => {
      if (!enable(getState)) {
        return false;
      }
      const value = selector(state);
      let isEqual;
      if (typeof waitFor === 'function') {
        isEqual = !waitFor(value, previousValue);
      } else if (typeof value !== 'object') {
        isEqual = previousValue === value;
      } else {
        isEqual = shallowEqual(previousValue, value);
      }
      if (!isEqual) {
        const ret = handler({ previousValue, value, startReturnValue });
        previousValue = value;
        count.push(ret);
        if (times && times > 0) {
          if (count.length === times) {
            myResolve(count);
          }
        } else {
          myResolve(count);
        }
      }
    };
    handlersPool[key].reject = myReject;
    if (leading) {
      handlersPool[key](getState());
    }
    if (timeout) {
      timeoutHandler = setTimeout(() => {
        myResolve(timeoutMessage);
      }, timeout);
    }
  }).catch((e) => e);
};
