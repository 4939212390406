const SHOW_WINDOW_TYPE_LIMIT = 800;

export const CALC_VIEW_PORT_WIDTH = 414;
export const CALC_VIEW_PORT_HEIGHT = 896;
const bitSet = (n) => {
  return 0b1 << (n - 1);
};

const SOCKET_NAME_ENUM = Object.freeze({
  COMMAND: Symbol('COMMAND'),
  XMPP: Symbol('XMPP'),
  BO: Symbol('BO'),
});

const LOCALSTORAGE_KEYS = {
  webClient_Bo_HasShowWhereCanJoinRoomTip:
    'WEB-CLIENT_BO_HAS-SHOW-WHERE-CAN-JOIN-ROOM-TIP',
  webClient_SIMD_Flag: 'WEB-CLIENT_SIMD_Flag',
  webClient_first_enter_flag: 'WEB-CLIENT-FIRST-ENTER-FLAG',
  webClient_AutoHideSuspensionEnabled:
    'WEB-CLIENT_AUTO-HIDE-SUSPENSION-ENABLED',
  webClient_meetingUqiueId: 'WEB-CLIENT_MeetingUqiueId',
  webClient_update_browser_to_share_notification_flag:
    'WEB-CLIENT-UPDATE-BROWSER-TO-SHARE-NOTIFICATION-FLAG',
  video_render_method_type: 'video_render_method_type',
  use_webRTC_rendering: 'use_webRTC_rendering',
  webClient_sharingEncryptKey: 'webClient_sharingEncryptKey',
  use_webRTC_strategy: 'use_webRTC_strategy',
};

const SESSIONSTORAGE_KEYS = {
  webClient_opt: 'WEB-CLIENT_OPT_TOKEN',
  // When users refresh the page,we need join the bo again auto
  // or give just a confirm dialog to join;
  // We sholud store the progress of joining the bo meeting.
  // We sholud remove it when the user returned back to the main session.
  // The value looks like "CF7CB9EB-F81C-4350-83B8-6C747CB7BE39;in room;boid;botoken;isHost"
  webClient_Bo_HasJoinBoMeeting: 'WEB-CLIENT_BO_HAS-JOIN-BO-MEETING',
  // key : WEB-CLIENT_BO_HAS-RECEIVE-JOIN-CMD;zoomId
  // value: true
  webClient_Bo_HasReceiveJoinCmd: 'WEB-CLIENT_BO_HAS-RECEIVE-JOIN-CMD',
  webClient_isHost: 'WEB-CLIENT_IS-HOST',
  webClient_BCoHost: 'WEB-CLIENT_B-COHOST',
  webClient_RemovedParticipants: 'WEB-CLIENT_REMOVED-PARTICIPANTS',
  webClient_webinarToken: 'WEB-CLIENT_WEBINAR-TOKEN',
  webClient_meetingToken: 'WEB-CLIENT_MEETING-TOKEN',
  webClient_hasRecordingReminderForHost:
    'WEB-CLIENT_HAS-RECORDING-REMINDER-FOR-HOST',
  webClient_freeMeetingHasAutoUpgraded: 'webClient_freeMeetingHasAutoUpgraded',
  webClient_freeMeetingBeginToConutdown:
    'webClient_freeMeetingBeginToConutdown',
  callMeStatusCode: 'callMeStatusCode',
  callMePhoneNumber: 'callMePhoneNumber',
  inMeeting: 'inMeeting',
  // if user be put on hold upon entry, hasBeenInMeeting is false.
  hasBeenInMeeting: 'hasBeenInMeeting',
  PO: 'preview_options',
  avStatus: 'avStatus',
};

const MEMORYSTORAGE_KEYS = {
  webclient_meeting_tokens: 'WEB-CLIENT-MEETING-TOKENS',
  webclient_rwc_response: 'rwc.oldResponse',
  rwcGeoFenceResponse: 'rwc.geoFenceResponse',
  rwgUrl: 'rwg.url',
  currentRwcIsGood: 'rwc.isGood',
  meetingInfo: 'meetingInfo_memory',
  surveyUrl: 'surveyUrl',
};

const RESET_WEBSOCKET_EVENTS = {
  BO_JOIN: 'BOJoin',
  BO_LEAVE: 'BOLeave',
  IN_BO: 'INBO',
  PROMOTE_DEPROMOTE: 'PROMOTE_DEPROMOTE',
};

// You can use this string to replace the placeholder when a sentence contains a component or other dom
const ELEMENT_PLACEHOLDER = '!@#$%%$#@!';

export {
  SHOW_WINDOW_TYPE_LIMIT,
  SOCKET_NAME_ENUM,
  LOCALSTORAGE_KEYS,
  SESSIONSTORAGE_KEYS,
  RESET_WEBSOCKET_EVENTS,
  ELEMENT_PLACEHOLDER,
  MEMORYSTORAGE_KEYS,
};

export const WEBINAR_CLIENT_CAP_CAN_MUTE = 0x0001;
export const WEBINAR_CLIENT_CAP_SUPPORT_FETCH_MEETING_TOKEN = 0x0002;
export const WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL = 0x0008; // ZOOM-199247
export const WEBNIAR_CLIENT_CAP_SUPPORT_ENDPOINT_ENCRYPTED = 0x00000010; // clientCap
export const WEBNIAR_CLIENT_CAP_SUPPORT_ENDPOINT_IS_H323 = 0x00000020; // clientCap
export const DataCenterRegionType = Object.freeze({
  HYBRID: 'HYBRID',
  ONPREM: 'ONPREM',
});
export const ZOOM_DOMAIN_TYPE = Object.freeze({
  ZOOM_DOMAIN_TYPE_NONE: 0,
  /** @description zoom  production(zoom.us) */
  ZOOM_DOMAIN_TYPE_PROD: 1,
  /** @description zoom  dev(zoomdev.us) */
  ZOOM_DOMAIN_TYPE_DEV: 2,
  /** @description zoom gov production(zoomgov.com) */
  ZOOM_DOMAIN_TYPE_GOV: 3,
  /** @description zoom gov dev(zoomgovdev.com) */
  ZOOM_DOMAIN_TYPE_GOVDEV: 4,
  /** @description zoom CN poruction(zoom.com.cn) */
  ZOOM_DOMAIN_TYPE_CN: 5,
  /** @description zoom dev CN (dev.zoom.com.cn) */
  ZOOM_DOMAIN_TYPE_DEVCN: 6,
});
export const EncryptionType = Object.freeze({
  NONE: 0,
  ECB: 1,
  GCM: 2,
});

export const PARTICIPANT_NEED_ASK_UNMUTE = 0x0001;
export const PARTICIPANT_CAN_BE_ASSIGNED_HUGE_BO = 0x0004;

export const MAX_COMCURRENY_VIDEO_THREAD = {
  SIZE_1: 1,
  SIZE_4: 4,
  SIZE_9: 9,
};

export const MAX_VIDEO_BAR_SIZE = {
  SIZE_1: 1,
  SIZE_4: 3,
  SIZE_9: 5,
};

export const CAPS_OPTION_SUPPORT_CHOOSE_ROOM = 0x00000008;
// Current co-host user, host and target user should all has this cap.
export const CAPS_OPTION_SUPPORT_FORCE_JOIN_LEAVE_BO = 0x00000010;
// Current co-host user and host should both has this cap.
export const CAPS_OPTION_SUPPORT_COHOST_START_STOP_BO = 0x00000020;

export const OPTION_SUPPORT_TG_MOVE_TO_MAIN_SESSION = 0x00000040; // For phone users only.
export const CAPS_OPTION_SUPPORT_COHOST = 0x00000100;
export const CAPS_OPTION_SUPPORT_ZOOM_PRESENCE = 0x00000080;
export const CLIENT_CAPS_SUPPORT_SHARE_MULTI_STREAM = 0x00020000;
export const CLIENT_CAPS_SUPPORT_VIDEO_LTR = 0x00040000;
export const CLIENT_CAPS_SUPPORT_VIDEO_SHARE = bitSet(10); // support video share ZOOM-412275 (0b1000000000/512)
export const CLIENT_CAPS_WEBINAR_NO_NEED_TYPE_EMAIL = bitSet(31);

export const ICON_TYPES = {
  DISABLE_VIDEO: 'disable_video',
  SWITCH_CAMERA: 'switch_camera',
  VIDEO_STARTED: 'video_started',
  VIDEO_STOPED: 'video_stoped',
  VIDEO_DISABLED: 'video_disabled',
  ENCRYPT_MEETING: 'encrypt_meeting',
  UNENCRYPT_MEETING: 'unencrypt_meeting',
  DROP_DOWN: 'drop_down',
  WARNING_ALERT: 'warning_alert',
  REC: 'REC',
  CLOSE: 'close',
  SHOW_MORE: 'show_more',
  AUDIO_MUTED: 'audio_muted',
  AUDIO_UNMUTED: 'audio_unmuted',
  PHONE_MUTED: 'phone_muted',
  PHONE_UNMUTED: 'phone_unmuted',
  AUDIO_JOIN: 'audio_join',
  AUDIO_DISALLOW: 'audio_disallow',
  AUDIO_DISABLED: 'audio_disabled',
  AUDIO_LOADING: 'audio_loading',
  VOIP_ACTIVE: 'voip_active',
  ZOOM_LOGO: 'zoom_logo',
  LINK: 'link',
  ABOUT_INFO: 'about_info',
  ARROW_LEFT: 'arrow_left',
  ATTENTION_MODE: 'attention_mode',
  SPINNER: 'spinner',
  NAVIGATOR_BACK: 'navigator_back',
  SELECT_MORE: 'select_more',
  AUDIO_DISCONNECT: 'audio-disconnect',
  SELECT_ITEM: 'selected_item',
  NETWORK_BAD: 'network_bad',
  NETWORK_NORMAL: 'network_normal',
  NETWORK_GOOD: 'network_good',
  MORE: 'more_icon',
  CHECKMARK: 'checkmark',
  CHEVRON: 'chevron',
  CHAT: 'chat',
  CHAT_CLOSE: 'chat-close',
  COPY: 'copy',
  STATISTICS: 'statistics',
};

export const VIDEO_FACING_MODE = Object.freeze({
  FRONT: 'user',
  BACK: 'environment',
});

export const AUDIO_ANIMATION_TIMEOUT = 3000;

export const MAX_CONCURRENCY_VIDEO_THREADS = {
  SIZE_1: 1,
  SIZE_4: 4,
  SIZE_5: 5,
  SIZE_10: 10,
  SIZE_26: 26,
};

export const MAX_VIDEO_ACTIVE_SIZE = {
  SIZE_1: 1,
  SIZE_5: 2,
  SIZE_10: 4,
  SIZE_26: 9,
};

export const CHAT_CONSTANTS = {
  SYS: -1,
  DUMMY_HEADER: -2,
  DELETED: -3,
  INPUT: 1,
};

export const CHAT_THREAD = {
  NONE: -1,
  EXPAND: 1,
  COLLAPSE: 2,
  MORE: 3,
};
export const SEND_CHAT_WARNING = {
  NONE: 0,
  NO_PRIVILEGE: -1,
  ABSENT: -2,
  RESTRICTED: -3,
  IMPROPER_RECEIVER: -4,
  EXCEPTION_WR: -5,
  UNSUPPORTED_TYPE: -6,
  EDIT_DM_ON_HOLD: -7,
};
export const IQ_FROM_IM = 'IQ_FROM_IM';

export const SESSION_TYPE = {
  SESSION_TYPE_AUDIO: 1,
  SESSION_TYPE_DESKSHARE: 2,
  SESSION_TYPE_VIDEO: 3,
};

export const JOIN_MEETING_POLICY = Object.freeze({
  CHAT: 'chat',
  CC: 'closeCaption',
  REMOTE_CONTROL: 'remoteControl',
  SHARING: 'screenShare',
  MEETING_POLL: 'meetingPolls',
  WEBINAR_POLL: 'webinarPolls',
  QA: 'webinarQnA',
  FILE_TRANSFER: 'fileTransfer', // not support
  REACTIONS: 'meetingReactions',
  NON_VERBAL_FEEDBACK: 'nonVerbalFeedback',
  HIDE_AVATAR: 'participantAvatar',
  DISABLE_COMPUTER_AUDIO: 'computerAudio',
  DISABLE_DIRECT_MESSAGE: 'directMessage',
  DISABLE_NEW_LT: 'liveTranscription',
  DISABLE_WEBINAR_REACTIONS: 'webinarReactions',
  DISABLE_IN_MEETING_WHITEBOARD: 'inMeetingWhiteBoard', // not support
  WEBINAR_SURVEY: 'webinarSurvey',
  JOIN_WEBINAR: 'joinWebinar', // 4098 error 79
  MEETING_SURVEY: 'meetingSurvey', // rwg handle this, doesn't return surveyUrl
  SAVE_CAPTION: 'saveCaptions',
  ZOOM_APP: 'inMeetingZoomApps',
});

export const CHAT_MSG_FROM = {
  CHAT_FROM_CLIENT: '0',
  CHAT_FROM_PMC_IM: '1',
  CHAT_FROM_DLP: '2',
};

export const CONF_SESSION_STATUS = {
  READY: 5,
};

export const CLIENT_CAPS_SUPPORT_PREVIEW = bitSet(6); //preview flow cap
export const CLIENT_CAPS_WEBRTC_AUDIO_RTCP_TIME = 0x00080000; // for audiobrage avsync

export const STY_API_USER = 99;
export const STY_NORMAL_USER = 100;

export const supportedMinimumChromeOSVersion = {
  ANDROID: 102,
  IOSWebRTC: 15.4,
  IOSWASM: 16.4,
};

export const supportedMinimumSafariOSVersion = { WASM: 16.4, WebRTC: 15.4 };

export const WEBRTC_ACTION_TYPE = {
  DISABLED: 0,
  ENABLED: 1,
  AUTO: 2,
};

export const NOTIFY_UI_ERROR_TIP_NAMES = {
  AUDIO_STREAM_FAILED_EXCEPTION: 'audio_stream_failed_exception',
  VIDEO_STREAM_FAILED_EXCEPTION: 'video_stream_failed_exception',
  WASM_MEMORY_FAIL: 'wasm_memory_fail',
  MEDIA_HEALTH_CHECK_FAILED: 'media_health_check_failed',
  WEBGL_CONTEXT_INVALID: 'webgl_context_invalid',
  AUDIO_WEBSOCKET_BROKEN: 'audio_websocket_broken',
  VIDEO_WEBSOCKET_BROKEN: 'video_websocket_broken',
  USER_FORBIDDED_CAPTURE_VIDEO: 'user_forbidden_capture_video',
  AUDIO_AUTO_PLAY_FAILED: 'audio_auto_play_failed',
  AUDIO_CAPTURE_FAILED_UNKONWN_REASON: 'audio_capture_failed_unknown_reason',
  AUDIO_CAPTURE_FAILED_USER_DENIED: 'audio_capture_failed_user_denied',
  AUDIO_CAPTURE_FAILED_SYSTEM_DENIED: 'audio_capture_failed_system_denied',
  AUDIO_CAPTURE_FAILED_NO_DEVICE: 'audio_capture_failed_no_device',
  AUDIO_CAPTURE_FAILED_DEVICE_IN_USE: 'audio_capture_failed_device_in_use',
  AUDIO_TAG_ERROR: 'audio_tag_error',
};

export const VIDEO_ERROR_TIP_NAMES = [
  NOTIFY_UI_ERROR_TIP_NAMES.VIDEO_STREAM_FAILED_EXCEPTION,
  NOTIFY_UI_ERROR_TIP_NAMES.USER_FORBIDDED_CAPTURE_VIDEO,
];

export const VIDEO_ERROR_PRIORITY_GROUP = [
  [
    NOTIFY_UI_ERROR_TIP_NAMES.VIDEO_STREAM_FAILED_EXCEPTION,
    NOTIFY_UI_ERROR_TIP_NAMES.VIDEO_WEBSOCKET_BROKEN,
  ],
  [NOTIFY_UI_ERROR_TIP_NAMES.WEBGL_CONTEXT_INVALID],
];

export const VIDEO_ERROR_PRIORITY_MAP = {
  [NOTIFY_UI_ERROR_TIP_NAMES.VIDEO_STREAM_FAILED_EXCEPTION]: 0,
  [NOTIFY_UI_ERROR_TIP_NAMES.VIDEO_WEBSOCKET_BROKEN]: 0,
  [NOTIFY_UI_ERROR_TIP_NAMES.WEBGL_CONTEXT_INVALID]: 1,
};

export const ALL_ERROR_PRIORITY_MAP = {
  [NOTIFY_UI_ERROR_TIP_NAMES.VIDEO_STREAM_FAILED_EXCEPTION]: 0,
  [NOTIFY_UI_ERROR_TIP_NAMES.VIDEO_WEBSOCKET_BROKEN]: 0,
  [NOTIFY_UI_ERROR_TIP_NAMES.AUDIO_STREAM_FAILED_EXCEPTION]: 0,
  [NOTIFY_UI_ERROR_TIP_NAMES.AUDIO_WEBSOCKET_BROKEN]: 0,
  [NOTIFY_UI_ERROR_TIP_NAMES.WASM_MEMORY_FAIL]: 1,
  [NOTIFY_UI_ERROR_TIP_NAMES.WEBGL_CONTEXT_INVALID]: 1,
  [NOTIFY_UI_ERROR_TIP_NAMES.MEDIA_HEALTH_CHECK_FAILED]: 1,
};

export const ALL_ERROR_PRIORITY_GROUP = [
  [
    NOTIFY_UI_ERROR_TIP_NAMES.VIDEO_STREAM_FAILED_EXCEPTION,
    NOTIFY_UI_ERROR_TIP_NAMES.VIDEO_WEBSOCKET_BROKEN,
    NOTIFY_UI_ERROR_TIP_NAMES.AUDIO_STREAM_FAILED_EXCEPTION,
    NOTIFY_UI_ERROR_TIP_NAMES.AUDIO_WEBSOCKET_BROKEN,
  ],
  [
    NOTIFY_UI_ERROR_TIP_NAMES.WASM_MEMORY_FAIL,
    NOTIFY_UI_ERROR_TIP_NAMES.WEBGL_CONTEXT_INVALID,
    NOTIFY_UI_ERROR_TIP_NAMES.MEDIA_HEALTH_CHECK_FAILED,
  ],
];
