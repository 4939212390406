import meetingConfig from 'meetingConfig';
import { SESSIONSTORAGE_KEYS } from '../../global/constant';
import { easyStore } from '../../global/easy-store';
import meetingField from 'meetingField';
import {
  WAIT_FOR_HOST_MEETING,
  WAIT_FOR_HOST_WEBINAR,
} from '../../global/resource';
import {
  isNewJoinFlowEnabled,
  isSimulive,
  getCookieDomain,
  isSupportAV,
  isWebinar,
} from '../../global/service';
import {
  CACHE_KEY,
  COOKIE_REMEMBERED_NAME,
  DEFAULT_PREVIEW_OPTIONS,
  ERROR_CODE,
  EXPIRE_DAYS_REMEMBERED_NAME,
  MEETING_INFO_TEXT_LIMIT,
  PREVIEW_AUDIO_STATUS,
  PREVIEW_VIDEO_STATUS,
  PREVIEW_OPTIONS,
} from './consts';
import {
  loadCookie,
  queryBit,
  removeCookie,
  saveCookie,
} from '../../global/util';
import { setSimulivePlayInfo } from '../../global/service/simulive';
import { getDefaultUserRole } from '../../global/service/user-role';
import { getAVStatus, setAVStatus } from '../../global/service/avStatus';
import { setPreviewOptions } from './utils';
import _ from 'lodash';
import { isViewOnly } from '../../global/service/user-types';
import {
  handleMeetingConfig,
  isMeetingConfigReady,
} from '../../global/service/meetingConfig';
import {
  getDefaultMeetingInfo,
  getMeetingInfo,
} from '../../global/service/meetingInfo';
import axios from 'axios';
import previewI18n from './resource';

const isPreviewOptionsExist = () => meetingConfig.previewOptions !== 0;

export const getPreviewOptions = () => {
  const poInSession = easyStore.easyGet(SESSIONSTORAGE_KEYS.PO);
  const previewOptions = isPreviewOptionsExist()
    ? meetingConfig.previewOptions
    : DEFAULT_PREVIEW_OPTIONS;
  return poInSession == null ? previewOptions : poInSession;
};

export const minRefreshInterval = () => {
  const { refreshInterval, zmkRefreshInterval } = meetingConfig;
  if (typeof zmkRefreshInterval !== 'undefined') {
    return Math.min(refreshInterval, zmkRefreshInterval) * 1000;
  }
  return refreshInterval * 1000;
};

export const handleMeetingStatusRes = ({ status, errorCode, result }) => {
  const simulivePlayInfo = result?.simulivePlayInfo;
  if (isSimulive() && simulivePlayInfo != null) {
    setSimulivePlayInfo(simulivePlayInfo);
  }
  if (status) {
    return { status: true, simulivePlayInfo };
  }
  if (errorCode === ERROR_CODE.webinar_in_practice_session) {
    if (meetingConfig.isPanelist || meetingConfig.isHost) {
      // in mobile wcl this case should never happen
      return { status: true, simulivePlayInfo };
    } else {
      return { status: false, tip: previewI18n.PracticeSessionTip };
    }
  } else {
    return {
      status: false,
      tip: isWebinar() ? WAIT_FOR_HOST_WEBINAR : WAIT_FOR_HOST_MEETING,
    };
  }
};

export function getRidFromTid(tid) {
  if (!tid) return '';
  const regex = /rid=(.*)/;
  const match = tid.match(regex);
  if (!match) return '';
  if (match) {
    return match[1];
  }
}

export function fetchMeetingStatus() {
  const rid = getRidFromTid(meetingConfig.tid);
  const params = {
    rspwd: meetingConfig.rspwd,
    uniqueId: rid,
    role: meetingConfig.userRole,
  };
  const searchParams = {
    mn: meetingConfig.meetingNumber,
  };
  if (meetingConfig.webinarToken) {
    searchParams.tk = meetingConfig.webinarToken;
  }
  return fetch(`/wc/rs?` + new URLSearchParams(searchParams), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then((res) => res.json())
    .then(({ status, errorCode, result }) => {
      return handleMeetingStatusRes({ status, errorCode, result });
    });
}

export function requestJoinMeeting(data = null) {
  const { baseUrl, meetingNumber, webinarToken } = meetingConfig;
  if (!data) {
    data = getMeetingInfo();
  }
  if (webinarToken) {
    data.tk = webinarToken;
  }
  return axios.post(
    `${baseUrl}/wc/${meetingNumber}/join${getJoinUrlParams()}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
    },
  );
}

export function shouldRenderNewWR() {
  return isNewJoinFlowEnabled() && isSupportAV();
}

export function isViewOnlyInPreview() {
  return isViewOnly(getDefaultUserRole()) || isSimulive();
}

export function isWebinarHostOrPanelistInPreview() {
  return isWebinar() && !isViewOnlyInPreview();
}

export function getAudioStatusFromCache(cacheKey) {
  const avStatus = getAVStatus();
  const audioStatus = _.get(avStatus, 'audio');
  const audioJoinStatus = _.get(avStatus, 'audioJoin');
  if (
    cacheKey === CACHE_KEY.PREVIEW_OPTIONS ||
    (!audioStatus && !audioJoinStatus)
  ) {
    const previewOptions = getPreviewOptions();
    let autoJoin = queryBit(previewOptions, PREVIEW_OPTIONS.AUTO_JOIN_AUDIO);
    if (!autoJoin) {
      return PREVIEW_AUDIO_STATUS.NOT_CONNECTED;
    }
    const audioOn = queryBit(previewOptions, PREVIEW_OPTIONS.AUDIO_ON);
    return audioOn ? PREVIEW_AUDIO_STATUS.UNMUTED : PREVIEW_AUDIO_STATUS.MUTED;
  } else {
    if (audioJoinStatus === 'joined') {
      if (audioStatus === 'unmute') {
        return PREVIEW_AUDIO_STATUS.UNMUTED;
      } else {
        return PREVIEW_AUDIO_STATUS.MUTED;
      }
    } else {
      return PREVIEW_AUDIO_STATUS.NOT_CONNECTED;
    }
  }
}

export function cacheAudioStatus(cacheKey, audioStatus) {
  if (cacheKey === CACHE_KEY.PREVIEW_OPTIONS) {
    const previewOptions = getPreviewOptions();
    switch (audioStatus) {
      case PREVIEW_AUDIO_STATUS.NOT_CONNECTED:
        setPreviewOptions(
          previewOptions,
          PREVIEW_OPTIONS.AUTO_JOIN_AUDIO,
          false,
        );
        break;
      case PREVIEW_AUDIO_STATUS.MUTED:
        setPreviewOptions(previewOptions, PREVIEW_OPTIONS.AUDIO_ON, false);
        break;
      case PREVIEW_AUDIO_STATUS.UNMUTED:
        setPreviewOptions(
          previewOptions,
          PREVIEW_OPTIONS.AUTO_JOIN_AUDIO,
          true,
          PREVIEW_OPTIONS.AUDIO_ON,
          true,
        );
        break;
      default:
        break;
    }
  } else {
    switch (audioStatus) {
      case PREVIEW_AUDIO_STATUS.NOT_CONNECTED:
        setAVStatus({ audioJoin: 'left' });
        break;
      case PREVIEW_AUDIO_STATUS.MUTED:
        setAVStatus({ audioJoin: 'joined', audio: 'mute' });
        break;
      case PREVIEW_AUDIO_STATUS.UNMUTED:
        setAVStatus({ audioJoin: 'joined', audio: 'unmute' });
        break;
      default:
        break;
    }
  }
}

export function getVideoStatusFromCache(cacheKey) {
  const avStatus = getAVStatus();
  const videoStatus = _.get(avStatus, 'video');
  if (cacheKey === CACHE_KEY.PREVIEW_OPTIONS || !videoStatus) {
    const previewOptions = getPreviewOptions();
    const videoOn = queryBit(previewOptions, PREVIEW_OPTIONS.VIDEO_ON);
    return videoOn ? PREVIEW_VIDEO_STATUS.OPEN : PREVIEW_VIDEO_STATUS.CLOSED;
  } else {
    if (videoStatus === 'unmute') {
      return PREVIEW_VIDEO_STATUS.OPEN;
    } else {
      return PREVIEW_AUDIO_STATUS.CLOSED;
    }
  }
}

export function cacheVideoStatus(cacheKey, videoStatus) {
  if (cacheKey === CACHE_KEY.PREVIEW_OPTIONS) {
    const previewOptions = getPreviewOptions();
    if (videoStatus === PREVIEW_VIDEO_STATUS.OPEN) {
      setPreviewOptions(previewOptions, PREVIEW_OPTIONS.VIDEO_ON, true);
    } else {
      setPreviewOptions(previewOptions, PREVIEW_OPTIONS.VIDEO_ON, false);
    }
  } else {
    if (videoStatus === PREVIEW_VIDEO_STATUS.OPEN) {
      setAVStatus({ video: 'unmute' });
    } else {
      setAVStatus({ video: 'mute' });
    }
  }
}

export function getAvatarUrl() {
  if (!meetingConfig.profilePic) {
    return null;
  }
  return `${meetingConfig.profilePic}?type=large`;
}

export function needTypeMeetingInfo() {
  const { name, email, pwd } = meetingField;
  return name || email || pwd;
}

export function getRememberedName() {
  return _.toString(loadCookie(COOKIE_REMEMBERED_NAME));
}

export function setRememberedName(userName) {
  removeRememberedName();
  const daytime = 1000 * 60 * 60 * 24;
  saveCookie(
    daytime * EXPIRE_DAYS_REMEMBERED_NAME,
    COOKIE_REMEMBERED_NAME,
    userName,
    { secure: true, domain: getCookieDomain() },
  );
}

export function removeRememberedName() {
  removeCookie(COOKIE_REMEMBERED_NAME, { domain: getCookieDomain() });
}

export function getSkipPreview() {
  return !needTypeMeetingInfo() && isViewOnlyInPreview();
}

export function getJoinUrlParams() {
  const search = window.location.search;
  if (!search) {
    return '';
  }

  const params = search.substring(1).split('&'); // delete '?'
  const filteredParams = _.filter(
    params,
    (param) => !param.includes('wpk=') && !param.includes('pwd='),
  );
  return filteredParams.length > 0 ? `?${filteredParams.join('&')}` : '';
}

export function getOptionwr() {
  const audioStatus = getAudioStatusFromCache(CACHE_KEY.AVSTATUS);
  const videoStatus = getVideoStatusFromCache(CACHE_KEY.AVSTATUS);

  let option = 0;
  const audioOn = 0x1 << 1;
  const videoOn = 0x1 << 2;
  if (audioStatus === PREVIEW_AUDIO_STATUS.UNMUTED) {
    option += audioOn;
  }
  if (videoStatus === PREVIEW_VIDEO_STATUS.OPEN) {
    option += videoOn;
  }
  return option;
}

export function getRememberedEmail() {
  const currentValue = window.localStorage.getItem('pwa_col_el_map');
  const { ecypAid, ecypUid } = meetingField;
  if (_.isEmpty(currentValue) || _.isEmpty(ecypAid) || _.isEmpty(ecypUid)) {
    return '';
  }
  try {
    const parentObject = JSON.parse(currentValue);
    const email = parentObject[ecypAid][ecypUid];
    return email;
  } catch {
    return '';
  }
}

export function setRememberedEmail(email) {
  const { ecypAid, ecypUid } = meetingField;
  const currentValue = window.localStorage.getItem('pwa_col_el_map');
  if (_.isEmpty(ecypAid) || _.isEmpty(ecypUid)) {
    return;
  }
  let parentObject = {};
  if (currentValue && currentValue.length) {
    try {
      parentObject = JSON.parse(currentValue);
    } catch (e) {
      parentObject = {};
    }
  }
  const childObject = parentObject[ecypAid] || {};
  childObject[ecypUid] = email;
  parentObject[ecypAid] = childObject;

  window.localStorage.setItem('pwa_col_el_map', JSON.stringify(parentObject));
}

export const checkMeetingConfigReady = () => {
  return new Promise((resolve) => {
    if (isMeetingConfigReady()) {
      return resolve();
    }
    let data = getMeetingInfo();
    if (!data) {
      data = getDefaultMeetingInfo();
    }
    if (meetingConfig.webinarToken) {
      data.tk = meetingConfig.webinarToken;
    }
    axios
      .post(
        `${meetingConfig.baseUrl}/wc/${
          meetingConfig.meetingNumber
        }/join${getJoinUrlParams()}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        },
      )
      .then((res) => {
        const { status, result } = res.data;
        if (status && result) {
          handleMeetingConfig(result.MeetingConfig);
        }
      })
      .then(resolve);
  });
};

export const checkFieldLength = (field) => {
  if (!_.isString(field)) {
    return false;
  }
  return field.length <= MEETING_INFO_TEXT_LIMIT;
};

export function needCompareToLoginEmail() {
  if (isWebinar()) {
    return meetingConfig.requireWebinarAttendeeAuth;
  } else {
    return true;
  }
}

export function getCachedAudioOn(cacheKey) {
  const avStatus = getAVStatus();
  const audioStatus = _.get(avStatus, 'audio');
  if (cacheKey === CACHE_KEY.PREVIEW_OPTIONS || !audioStatus) {
    return true;
  } else {
    return audioStatus === 'unmute';
  }
}
