import meetingConfig from 'meetingConfig';
import { LoadJs } from '../global/load-js';
import { errorLog } from '../../global/web-client-logger';

function loadWebClient() {
  return LoadJs.start(meetingConfig)
    .then((v) => {
      return v.add('js/main-client.webclient.min.js');
    })
    .catch(errorLog);
}

export const taskLoadWebclient = [loadWebClient];
