import meetingConfig from 'meetingConfig';
import {
  isNewJoinFlowEnabled,
  isSimulive,
  isSupportPOSetting,
  isSupportAV,
} from '../../../global/service';
import { easyStore } from '../../../global/easy-store';
import { SESSIONSTORAGE_KEYS } from '../../../global/constant';
import { taskRunMainClient } from '../task.run-main-client';
import { taskRunPreview } from '../task.run-preview';
import { getSkipPreview } from '../../../features/preview/service';
import { taskRequestJoinMeeting } from '../task.request-join-meeting';
import {
  blackBoxEmitEvent,
  BlackBoxEvent,
} from '../../../global/service/black-box-service';

export default function () {
  const needPreview =
    isNewJoinFlowEnabled() &&
    !meetingConfig.isHost &&
    !easyStore.easyGet(SESSIONSTORAGE_KEYS.inMeeting) &&
    !isSimulive() &&
    isSupportAV() &&
    isSupportPOSetting();

  const skipPreview = getSkipPreview();
  if (needPreview) {
    if (skipPreview) {
      return taskRequestJoinMeeting;
    } else {
      blackBoxEmitEvent(BlackBoxEvent.just_start_preview);
      return taskRunPreview;
    }
  } else {
    blackBoxEmitEvent(BlackBoxEvent.just_start_meeting);
    return taskRunMainClient;
  }
}
