/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */
import zlib from 'browserify-zlib';
import { Buffer } from 'buffer';
import {
  CONTROL_STATUS,
  ATTENDEE_STATUS,
  BO_COMMAND_TYPES,
  ROOMS_SIZE,
} from './constant';
import { storeType } from '../../global/easy-store';
import {
  SESSIONSTORAGE_KEYS,
  CAPS_OPTION_SUPPORT_FORCE_JOIN_LEAVE_BO,
  CAPS_OPTION_SUPPORT_COHOST_START_STOP_BO,
  OPTION_SUPPORT_TG_MOVE_TO_MAIN_SESSION,
} from '../../global/constant';
import { isRwgPhoneUser } from '../../global/service/index';
import { decodeUnsafeBase64 } from '../../global/utils/base64';
import _ from 'lodash';

/**
 *
const data = [{
  userRole: 4, userId: 16778240, participantId: 27, displayName: 'Kunkka Jia',
},
{
  userRole: 0, userId: 16789504, participantId: 56, displayName: 'Firefox',
}];
const res = dataFormatFactory(data)({
  avatar: {
    value: 'icon',
    default: '/src/assets/image/attendee.png',
  },
  userId: 'value',
  displayName: 'text',
});

output:
[{
  icon: '/src/assets/image/attendee.png',
  value: 16778240,
  text: 'Kunkka Jia',
},
{
  icon: '/src/assets/image/attendee.png',
  value: 16789504,
  text: 'Firefox',
}];
 * @param {*} srcData
 */
function dataFormatFactory(srcData) {
  return (model, externalFn) => {
    if (_.isNil(srcData)) return '';
    if (_.isNil(model)) return srcData;
    function convert(plainObject) {
      const newObj = {};
      Object.keys(model).forEach((key) => {
        let modelKey = model[key];
        let defaultValue = plainObject[key];
        if (_.isObject(modelKey) && modelKey.hasOwnProperty('value')) {
          defaultValue = defaultValue || modelKey.default;
          modelKey = modelKey.value;
        }
        newObj[modelKey] = defaultValue;
        if (externalFn) {
          externalFn(newObj);
        }
      });
      return newObj;
    }
    if (_.isPlainObject(srcData)) {
      return convert(srcData);
    }
    if (_.isArray(srcData)) {
      return srcData.map(convert);
    }
    return srcData;
  };
}

function isRoomInProgress(status) {
  return status === CONTROL_STATUS.IN_PROGRESS;
}
function isRoomNerverStarted(status) {
  return status === CONTROL_STATUS.NOT_STARTED;
}
function isRoomNotStarted(status) {
  return (
    status === CONTROL_STATUS.NOT_STARTED || status === CONTROL_STATUS.CLOSED
  );
}
function isRoomClosing(status) {
  return status === CONTROL_STATUS.CLOSING;
}
function isRoomClosed(status) {
  return status === CONTROL_STATUS.CLOSED;
}

function isInitial(status) {
  return status === ATTENDEE_STATUS.INITIAL;
}
function isInRoom(status) {
  return status === ATTENDEE_STATUS.IN_ROOM;
}
function isBeInvited(status) {
  return status === ATTENDEE_STATUS.BE_INVITED;
}
function isJoiningRoom(status) {
  return status === ATTENDEE_STATUS.JOINING;
}
function isReturningToMainSession(status) {
  return status === ATTENDEE_STATUS.RETURNING;
}
function isReturnedToMainSession(status) {
  return status === ATTENDEE_STATUS.RETURN_MAIN_SESSION;
}
function isRoomTimeUpForAttendee(status) {
  return status === ATTENDEE_STATUS.TIME_UP;
}
function isNotInRoom(status) {
  return (
    !isInRoom(status) &&
    !isJoiningRoom(status) &&
    !isReturningToMainSession(status)
  );
}

function isBeMovedToRoom(cmd) {
  return cmd === BO_COMMAND_TYPES.SWITCH;
}

function changeRoomDataFromServerToLocal(roomDataFromServer) {
  return {
    boId: roomDataFromServer.BID,
    name: roomDataFromServer.MeetingTitle,
    boToken: roomDataFromServer.MeetingToken,
    boStatus: roomDataFromServer.Status,
    hostId: roomDataFromServer.HostID,
    attendeeIdList: roomDataFromServer.ParticipantList || [],
  };
}

function boAttributeIndicationAdapter(data, hugeBo) {
  let boRoomData = '';
  if (hugeBo) {
    const gunzipData = zlib.gunzipSync(Buffer.from(data, 'base64'));
    boRoomData = JSON.parse(decodeUnsafeBase64(gunzipData));
  } else {
    boRoomData = JSON.parse(decodeUnsafeBase64(data));
  }
  const {
    ControlStatus,
    NameIndex,
    IsAutoJoinEnabled,
    IsBackToMainSessionEnabled,
    IsParticipantsChooseRoomEnabled,
    IsTimerEnabled,
    TimerDuration,
    IsTimerAutoEndEnabled,
    WaitSeconds,
    StartTimeOnMMR,
    ItemList,
    IsPreAssignmentEnabled,
    WhoStart,
    WhoStop,
  } = boRoomData;
  return {
    autoJoin: IsAutoJoinEnabled,
    backToMainSession: IsBackToMainSessionEnabled,
    participantsChooseRoom: IsParticipantsChooseRoomEnabled,
    timer: IsTimerEnabled,
    timerAutoEnd: IsTimerAutoEndEnabled,
    timerDuration: TimerDuration,
    waitseconds: WaitSeconds,
    controlstatus: ControlStatus,
    nameindex: NameIndex,
    update: ItemList,
    startTimeOnMMR: StartTimeOnMMR,
    preAssignmentEnabled: IsPreAssignmentEnabled,
    whoStart: WhoStart,
    whoStop: WhoStop,
  };
}

function parseBOPreAssignData(proto) {
  try {
    const data = JSON.parse(decodeUnsafeBase64(proto));
    return data.PreAssignBoList || [];
  } catch (e) {
    throw new Error('parse pre assign data failed');
  }
}

function getUserGuidsFromUserEmails(emails, attendeesList) {
  const attendeeIdList = [];
  if (emails) {
    for (let i = 0, len = emails.length; i < len; i++) {
      const email = emails[i];
      const targetAttendee = attendeesList.find(
        (attendee) => attendee.userEmail === email,
      );
      if (targetAttendee) {
        attendeeIdList.push(targetAttendee.userGUID);
      }
    }
  }
  return attendeeIdList;
}

/**
 *
 * @param {*} roomList has two different data structures
 * 1.roomList in redux 2. the original data from rwg
 */
function isMeShouldJoinBo(roomList, userGUID) {
  if (!roomList) {
    return false;
  }
  for (let i = 0; i < roomList.length; i++) {
    const room = roomList[i];
    if (room.attendeeIdList) {
      const me = room.attendeeIdList.find(
        (attendeeId) => attendeeId === userGUID,
      );
      if (me) {
        return room;
      }
    } else if (room.ParticipantList) {
      const me = room.ParticipantList.find(
        (attendeeId) => attendeeId === userGUID,
      );
      if (me) {
        return room;
      }
    }
  }
  return false;
}

function whichRoomTargetUserIn(user, roomList) {
  return isMeShouldJoinBo(roomList, user.userGUID);
}

/**
 *
 * @param {*} targetUserId
 * @param {*} movingFlag you can refer to the shape isMovingAttendee
 */
/* function isMovingTargetAttendee(targetUserId, movingFlag) {
  if (!movingFlag) {
    return false;
  }
  const [userId, isReceiveRemoveIndication, isReceiveUpdateIndication] = movingFlag.split(';');
  if (userId !== targetUserId) {
    return false;
  }
  if (!isReceiveRemoveIndication || !isReceiveUpdateIndication) {
    return true;
  }
  return false;
} */

function getTargetRoomAttendeeListInLive({
  isMeInRoom,
  mainSessionAttendeeList,
  boId,
  hostZoomId,
  attendeesList,
  myZoomId,
  isHost,
  attendeeIdList,
  controlStatus,
}) {
  let currentRoomAttendeeList = [];
  if (isMeInRoom) {
    currentRoomAttendeeList = mainSessionAttendeeList.filter((attendee) => {
      if (attendee.bid === boId) {
        // this maybe include the host in the mainSessionAttendeeList
        return true;
      }
      // exclude the host in the attendeeIdList
      if (attendee.zoomID === hostZoomId) {
        return false;
      } // so we can show the status of the host in bo correctly
      return attendeeIdList.indexOf(attendee.userGUID) > -1;
    });
  } else {
    currentRoomAttendeeList = attendeesList.filter((attendee) => {
      // exclude myself when I'm the host
      if (attendee.zoomID === myZoomId && isHost) {
        return false;
      }
      if (attendee.bHold && isRoomNotStarted(controlStatus)) {
        return false;
      }
      if (attendee.bHold && isRoomInProgress(controlStatus)) {
        return false;
      }
      return (
        attendeeIdList.indexOf(attendee.userGUID) > -1 || boId === attendee.bid
      );
    });
  }
  return currentRoomAttendeeList;
}

function getTargetRoomAttendeeSize(roomSizeList, boId) {
  const targetRoom = roomSizeList.find((room) => room.boId === boId);
  return targetRoom ? targetRoom.size : 0;
}

function setUserJoinBoInfoIntoStorage({ status, boId }) {
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.webClient_Bo_HasJoinBoMeeting,
    `${status};${boId}`,
    storeType.sessionStorage,
  );
}

function clearUserJoinBoInfoInStorage() {
  easyStore.easyRemove(SESSIONSTORAGE_KEYS.webClient_Bo_HasJoinBoMeeting);
}

function setUserReceiveJoinBoCmdStorage() {
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.webClient_Bo_HasReceiveJoinCmd,
    true,
    storeType.sessionStorage,
  );
}

function clearUserReceiveJoinBoCmdStorage() {
  easyStore.easyRemove(SESSIONSTORAGE_KEYS.webClient_Bo_HasReceiveJoinCmd);
}

function setBCoHostStorage() {
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.webClient_BCoHost,
    true,
    storeType.sessionStorage,
  );
}

function clearBCoHostInStorage() {
  easyStore.easyRemove(SESSIONSTORAGE_KEYS.webClient_BCoHost);
}

function isBoMainWebsocket(url) {
  if (url && url.indexOf('&bid=') > -1 && url.indexOf('&botk=') > -1) {
    return true;
  }
  return false;
}

function getMaxRoomSize(hugeBo) {
  return hugeBo ? ROOMS_SIZE.hugeBoMax : ROOMS_SIZE.max;
}
function getMaxRoomInputLength(hugeBo) {
  return hugeBo ? ROOMS_SIZE.hugeBoMaxLength : ROOMS_SIZE.maxLength;
}

const checkIsSupportForceJoinOrLeaveBoByCaps = (caps) =>
  !!(caps & CAPS_OPTION_SUPPORT_FORCE_JOIN_LEAVE_BO);

const checkIsSupportCoHostStartOrStopBoByCaps = (caps) =>
  !!(caps & CAPS_OPTION_SUPPORT_COHOST_START_STOP_BO);

const checkIsTargetSupportMoveToMainSession = (attendee) => {
  if (isRwgPhoneUser(attendee)) {
    return !!(attendee.caps & OPTION_SUPPORT_TG_MOVE_TO_MAIN_SESSION);
  }
  return checkIsSupportForceJoinOrLeaveBoByCaps(attendee.caps);
};

const logHostNotFound = () => {
  console.log('Host not found when attempting to operate breakout room.');
};

export {
  dataFormatFactory,
  isRoomInProgress,
  isRoomNerverStarted,
  isRoomNotStarted,
  isRoomClosing,
  isRoomClosed,
  isInRoom,
  isBeInvited,
  isJoiningRoom,
  isReturningToMainSession,
  isNotInRoom,
  isReturnedToMainSession,
  clearUserJoinBoInfoInStorage,
  isRoomTimeUpForAttendee,
  changeRoomDataFromServerToLocal,
  isBeMovedToRoom,
  isMeShouldJoinBo,
  whichRoomTargetUserIn,
  getTargetRoomAttendeeListInLive,
  getTargetRoomAttendeeSize,
  boAttributeIndicationAdapter,
  setUserJoinBoInfoIntoStorage,
  setUserReceiveJoinBoCmdStorage,
  clearUserReceiveJoinBoCmdStorage,
  setBCoHostStorage,
  clearBCoHostInStorage,
  isBoMainWebsocket,
  getMaxRoomSize,
  getMaxRoomInputLength,
  parseBOPreAssignData,
  getUserGuidsFromUserEmails,
  isInitial,
  checkIsSupportForceJoinOrLeaveBoByCaps,
  checkIsSupportCoHostStartOrStopBoByCaps,
  checkIsTargetSupportMoveToMainSession,
  logHostNotFound,
};
