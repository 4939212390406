import meetingConfig from 'meetingConfig';
import {
  receiveSharingByJPG,
  isUISelfPaintSharing,
  getDeviceId,
  isOnZoomMeeting,
  isAudioBridge,
} from '../../service';
import {
  isOnIOS,
  loadCookie,
  getBrowserVersion,
  getJoinMeetingLog,
} from '../../util';
import { SESSIONSTORAGE_KEYS } from '../../constant';
import { isJoiningRoom } from '../../../features/breakout-room/utils';
import { currentVersion } from '../../../../version';
import { getClientCap, getClientCapsEx } from '../../service/rwg';
import { escapeSensitiveContent } from '../../logger/escapeSensitiveContent';
import { encodeBase64 } from '../../utils/base64';
import { makeLogger, reportToGlobalTracing } from '../../logger/laplace';
import { makeQuery } from './url-utils';
import { getFinalUseWebRTC } from '../../service/web-RTC-service';
import { easyStore } from '../../easy-store';
import { globalVariable } from '../../global-variable';
const logger = makeLogger(['Join Meeting Flow']);

export function getJoinRWGURL({
  rwcData,
  zak,
  ts,
  auth,
  trackAuth,
  tid,
  isOnHold,
  boId,
  boToken,
  userName,
  boRoomAttendeeStatus,
  boConfId = '',
  isInPreview,
}) {
  if (!rwcData) return null;
  const zmMtkGuid = loadCookie('_zm_mtk_guid');
  const deviceId = getDeviceId();
  const webinarTokenInStorage = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_webinarToken,
  );
  const tk = webinarTokenInStorage || meetingConfig.webinarToken;
  const forBO = isJoiningRoom(boRoomAttendeeStatus) && boId && boToken;
  const ccEditorOpt = easyStore.easyGet(SESSIONSTORAGE_KEYS.webClient_opt);
  const jmak = meetingConfig.onZoomOptions && meetingConfig.onZoomOptions.jmak;
  const meetingTokenInStorage = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_meetingToken,
  );
  const oldUserEmail = easyStore.easyGet('userEmail');
  const participantId = easyStore.easyGet('participantId');
  const zoomId = easyStore.easyGet('zoomId');
  const isPrevUser = meetingConfig.userEmail
    ? meetingConfig.userEmail === oldUserEmail
    : easyStore.isOldRWGUserName();
  const isdjExist =
    (isPrevUser && meetingTokenInStorage) || (isOnZoomMeeting() && jmak);
  const makeUrls = (forLog) => {
    globalVariable.useWBVideo = getFinalUseWebRTC();
    const args = [
      ['islch', zak || isInPreview ? 1 : 0],
      [!isInPreview && 'dn2', encodeURIComponent(encodeBase64(userName))],
      ['ts', ts],
      ['auth', encodeURIComponent(auth)],
      ['trackAuth', encodeURIComponent(trackAuth)],
      ['mid', encodeURIComponent(meetingConfig.mid)],
      ['tid', encodeURIComponent(tid)],
      ['ZM-CID', encodeURIComponent(deviceId)],
      [meetingConfig.lang && 'lang', encodeURIComponent(meetingConfig.lang)],
      ['_ZM_MTG_TRACK_ID', encodeURIComponent(zmMtkGuid)],
      ['mcv', encodeURIComponent(currentVersion.version)],
      [
        !meetingConfig.isHost && meetingConfig.password && 'mpwd',
        encodeURIComponent(meetingConfig.password),
      ],
      [meetingConfig.trackId && 'track_id', meetingConfig.trackId],
      [meetingConfig.jmfCode && 'jmf_code', meetingConfig.jmfCode],
      [
        meetingConfig.meetingResult && 'meeting_result',
        meetingConfig.meetingResult,
      ],
      [rwcData.rwcAuth && 'rwcAuth', rwcData.rwcAuth],
      [rwcData.rwcToken && 'rwcToken', rwcData.rwcToken],
      [meetingConfig.platform && 'from', meetingConfig.platform],
      ['as_type', receiveSharingByJPG() ? 1 : isUISelfPaintSharing() ? 0 : 2],
      [
        meetingConfig.userEmail && 'email',
        encodeURIComponent(meetingConfig.userEmail),
      ],
      [tk && 'tk', encodeURIComponent(tk)],
      [isdjExist && 'isdj', 1],
      [isPrevUser && zoomId && 'zoomid', zoomId],
      [isPrevUser && 'participantID', participantId],
      [forBO && 'bid', encodeBase64(boId)],
      [forBO && 'botk', boToken],
      [forBO && 'confID', boConfId],
      [ccEditorOpt && 'opt', ccEditorOpt],
      [
        getBrowserVersion() && 'browser',
        encodeURIComponent(getBrowserVersion()),
      ],
      ['cfs', isPrevUser ? Number(isOnHold) : 0],
      ['clientCaps', getClientCap()],
      ['clientCapsEx', getClientCapsEx()],
      [isAudioBridge && 'useABAudio', 1],
      ['clientOS', isOnIOS() ? 'ios' : 'android'],
      ['audioFeature', JsMediaSDK_Instance.util.getAudioFeatureFlags?.()],
      [globalVariable.useWBVideo && 'useWBVideo', 1],
      [meetingConfig.cid2 && 'ZM-CID2', encodeURIComponent(meetingConfig.cid2)],
    ];

    let rwg = rwcData.rwg;
    if (CLIENT_ENV === 'development') {
      const urlParams = new URLSearchParams(window.location.search);
      const rwg_from_url = urlParams.get('rwg');
      if (
        typeof rwg_from_url === 'string' &&
        /^.*\.zoomdev\.us$/.test(rwg_from_url)
      ) {
        rwg = rwg_from_url;
      }
    }

    const domain = `wss://${rwg}/${isOnZoomMeeting() ? 'onzoom' : 'mobile'}`;
    const meetingNumber = forLog
      ? escapeSensitiveContent(meetingConfig.meetingNumber)
      : meetingConfig.meetingNumber;
    const query = makeQuery(args);
    return `${domain}/${meetingNumber}?${query}`;
  };

  logger.log(
    getJoinMeetingLog(
      `RWG_URL:${makeUrls(true)},\nfixVersion:${currentVersion.fixVersion}`,
    ),
    ['RWG_URL', `tid: ${tid}`],
  );

  if (
    (meetingTokenInStorage || zoomId || ccEditorOpt || isdjExist) &&
    !(meetingTokenInStorage && zoomId && ccEditorOpt && isdjExist)
  ) {
    let failoverLog = [];
    if (!meetingTokenInStorage) {
      failoverLog.push('Meeting_Token_Not_Exist');
    }
    if (!zoomId) {
      failoverLog.push('ZoomId_Not_Exist');
    }
    if (!ccEditorOpt) {
      failoverLog.push('Opt_Not_Exist');
    }
    if (!isdjExist) {
      failoverLog.push('Isdj_Not_Exist');
    }
    logger.log(failoverLog.join(','), ['FAILOVER_PARAMS']);
    reportToGlobalTracing({ filter: ['FAILOVER_PARAMS'] });
  }

  return makeUrls();
}

export function getJoinBORWGURL(svcUrl, conID) {
  const url = `wss://${svcUrl}/wc/media/${meetingConfig.meetingNumber}?type=m&cid=${conID}&mode=2`;
  const escapedUrl = `wss://${svcUrl}/wc/media/${escapeSensitiveContent(
    meetingConfig.meetingNumber,
  )}?type=m&cid=${conID}&mode=2`;
  logger.log(getJoinMeetingLog(`RWG_URL_BO:${escapedUrl}`), ['RWG_URL_BO']);
  return url;
}
