import { globalVariable } from '../../global-variable';

export const isAVSocketExist = () => {
  return globalVariable.avSocket && !globalVariable.avSocket.isDestroyed;
};
export const sendAVSocketMessage = (...args) => {
  if (globalVariable.notifyMediaSdkMsgCache && args) {
    globalVariable.notifyMediaSdkMsgCache.push(args);
  }
  return globalVariable.avSocket?.sendSocket?.(...args);
};

export const mediaSDKInstance = () => globalVariable.avSocket?.socketInstance;
