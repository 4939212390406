import { iText } from '../../global/util';

export default {
  VideoPreview: iText('Video Preview', 'apac.video_preview'),
  Scheduled: iText('Scheduled', 'apac.scheduled'),
  RecurringMeeting: iText(
    'This is a recurring meeting',
    'apac.recurring_meeting',
  ),
  RecurringWebinar: iText(
    'This is a recurring webinar',
    'apac.recurring_webianr',
  ),
  PracticeSessionTip: iText(
    'Please wait. The webinar will begin soon',
    'apac.practise_session_tip',
  ),
  EnterMeetingInfo: iText(
    'Enter Meeting Info',
    'apac.preview.enter_meeting_info',
  ),
  MeetingPasscode: iText('Meeting Passcode', 'apac.preview.meeting_passcode'),
  MeetingID: iText('Meeting ID', 'apac.preview.meeting_id'),
  MeetingEmail: iText('Email', 'apac.preview.meeting_email'),
  YourName: iText('Your Name', 'apac.preview.your_name'),
  EmailRequired: iText(
    'Your email cannot be empty',
    'apac.preview.email_required',
  ),
  IncorrectPassword: iText(
    'Incorrect Password',
    'apac.preview.incorrect_password',
  ),
  PasswordTooLong: iText(
    'Your password is too long',
    'apac.preview.password_too_long',
  ),
  PasswordRequired: iText(
    'Your password cannot be empty',
    'apac.preview.passwrod_required',
  ),
  NameRequired: iText(
    'Your name cannot be empty',
    'apac.preview.name_required',
  ),
  NameTooLong: iText('Your name is too long', 'apac.preview.name_too_long'),
  EmailInvalid: iText(
    'Please enter a valid email address.',
    'apac.preview.email_invalid',
  ),
  RememberName: iText(
    'Remember my name for future meetings',
    'apac.preview.remember_name',
  ),
  Download: iText('download the Zoom Client', 'apac.preview.download'),
  FailedToJoinWebinar: iText(
    'Failed to join Webinar',
    'apac.preview.failed_to_join_webinar',
  ),
  rejectionFn: (download) =>
    iText(
      `You are a host or panelist for this webinar. Please ${download} to join.`,
      'apac.preview.reject_panelist_join'[download],
    ),
  agreementFn: (terms, privacy) =>
    iText(
      `By clicking "Join", you agree to our ${terms} and ${privacy}.`,
      'apac.preview.agreement',
      [terms, privacy],
    ),
  EnterAccountEmailTip: iText(
    'Please enter the email address associated with your Zoom account',
    'apac.wc_meeting_incorrect_email',
  ),
};
