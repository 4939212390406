export const ROOMS_DISTRIBUTION_PATTERN = {
  auto: 1,
  manually: 2,
  selfSelect: 3,
};

export const ROOMS_SIZE = {
  min: 1,
  max: 50,
  hugeBoMax: 100,
  maxLength: 2,
  hugeBoMaxLength: 3,
};

export const DEFAULT_ROOM_LOCAL_ID = 1;

export const ROOM_NAME_MAX_LENGTH = 32;
export const BROADCAST_MESSAGE_MAX_LENGTH = 500;

export const CONTROL_STATUS = Object.freeze({
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  CLOSING: 3,
  CLOSED: 4,
});

export const ATTENDEE_STATUS = Object.freeze({
  INITIAL: 'initial',
  BE_INVITED: 'be invited',
  JOINING: 'joining',
  IN_ROOM: 'in room',
  RETURNING: 'returning',
  NOT_JOINED: 'not joined',
  TIME_UP: 'time up',
  RETURN_MAIN_SESSION: 'return main session',
});

export const BO_ROOM_STATUS = {
  NO_TOKEN: 1,
  GOT_TOKEN: 2,
  STARATED: 3,
  STOPPING: 4,
  ENDED: 5,
};

export const BO_COMMAND_TYPES = {
  JOIN: 'joinRequest',
  SWITCH: 'switchRequest',
  LEAVE: 'leaveRequest',
  BROADCAST: 'messageText',
  HELP_REQ: 'helpRequest',
  HELP_RES: 'helpResponse',
  CO_HOST_WANT_JOIN_THIS_BO: 'wantJoinThisBO',
  BO_ACTIVITY: 'BOActivity',
};

export const BO_HOST_HELP_REQ_TYPES = {
  HAS_RECEIVED: 0,
  BUSY: 1,
  IGNORE: 2,
  ALREADY_IN: 3,
};

export const batchCreateRoomsConstants = {
  renderRoomSize: 25,
  renderRoomSizeEachBatch: 50,
};

export const BO_JOIN_METHODS = {
  NORMAL_JOIN: 0,
  FORCE_JOIN: 1,
};

export const BO_JOIN_REASONS = {
  JOIN: 0,
  START: 1,
};
