const DOM_KEY_CODE = {
  ESC: 27,
  ENTER: 13,
  TAB: 9,
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  CANCEL: 3,
  SPACE: 32,
  BACKSLASH: 220,
  BACKSAPCE: 8,
  DELETE: 46,
};

const A11Y_FOCUS_WALKER_POLICY = {
  LIST_ITEM_POLICY: 0,
  LIST_POLICY: 1,
  TAB_POLICY: 2,
  LIST_ITEM_CHILD_NODE_POLICY: 3,
  NORMAL_NODE_POLICY: 4,
  PREV_TRIGGER_POLICY: 5,
  NEXT_TRIGGER_POLICY: 6,
  LIST_CHECKBOX_ITEM_POLICY: 7,
  TAB_TO_FIRST_CHILD_POLICY: 8,
  LIST_TREE_VIEW_POLICY: 9,
  PP_NEXT_CHILD_POLICY: 10,
  LIST_POLICY_2: 11,
  PP_NEXT_CHILD_POLICY_2: 12,
  NORMAL_NODE_POLICY_2: 14,
  QA_CONTENT_LIST_POLICY: 102,
  QA_ANSWER: 103,
};

const MEETING_FAIL_REASON = {
  tryRWCError: 50001,
  refreshTokenFailed: 50002,
  noRWGAvailable: 50003,
};

const PLATFORM_TYPE_CODE = {
  WEBCLIENT: 7,
};

const GLOBAL_POPOVER_PATTERN_ENUM = {
  DEFAULT: 0,
  PATTERN1: 1,
  PATTERN2: 2,
};

const COMPOSITE_CHECKBOX_MASK_ENUM = {
  PATTERN1: 1,
};

const COMPOSITE_CHECKBOX_ALLY_BORDER_PATTERN_ENUM = {
  PATTERN1: 1,
};

const WC_LOCAL_EVT_ENUM = {
  BLUR: 0,
  FOCUS_OUT: 1,
};

const USER_ROLE_ENUM = {
  ATTENDEE: 0,
  HOST: 1,
  OWNER: 4,
  VIEWONLY: 8,
};

const RWG_USER_TYPE = Object.freeze({
  CLIENT: 9,
  PHONE: 5,
  H323: 15,
});

const RECORDING_STATUS = Object.freeze({
  STOP: 0,
  RECORDING: 1,
  PAUSE: 2,
});

const CONF_STATUS = Object.freeze({
  DEFAULT: 0,
  FREE_USER_30MIN_OUT_NOTIFY: 1,
  PAID_MEETING_START: 2,
  JBH_MEETING_START: 3,
  FREE_MEETING_START: 4,
});

const PREVIEW_OPTIONS = Object.freeze({
  AUTO_JOIN_AUDIO: 1,
  AUDIO_ON: 1 << 1,
  VIDEO_ON: 1 << 2,
});

const WORKER_TYPE = {
  VIDEO_ENCODE: '0',
  VIDEO_DECODE: '1',
  AUDIO_ENCODE: '2',
  AUDIO_DECODE: '3',
  SHARING_ENCODE: '4',
  SHARING_DECODE: '5',
};

const FAILOVER_REASON = {
  NORMAL_CASE: -1, // just like enter/leave BO, promote/depromote, admit/put in waiting room
  NO_HEARTBEAT: 0,
  NO_HEARTBEAT_M_CHANNEL: 1, // type = m
  NETWORK_CHANGE: 2,
  NOTIFY_UI_FAILOVER: 3,
  NOTIFY_UI_WMSC_FAILOVER: 3.1,
  NOTIFY_UI_WMSC_WSS_DISCONNECTED: 3.2,
  INVALID_PARAMETERS: 4,
  ON_ERROR: 5,
  ON_ERROR_M_CHANNEL: 6,
  DISCONNECT: 7,
  DISCONNECT_M_CHANNEL: 8,
  DISCONNECT_XMPP: 9,
  REFRESH_PAGE: 10,
  NO_RWG_RESPONSE: 11,
  CONSOLE_CLOSED: 12, // closed using a command in devtools
  DEAD_IN_BACKGROUND: 13,
};

export {
  DOM_KEY_CODE,
  PLATFORM_TYPE_CODE,
  GLOBAL_POPOVER_PATTERN_ENUM,
  COMPOSITE_CHECKBOX_MASK_ENUM,
  COMPOSITE_CHECKBOX_ALLY_BORDER_PATTERN_ENUM,
  A11Y_FOCUS_WALKER_POLICY,
  WC_LOCAL_EVT_ENUM,
  USER_ROLE_ENUM,
  RWG_USER_TYPE,
  RECORDING_STATUS,
  CONF_STATUS,
  PREVIEW_OPTIONS,
  WORKER_TYPE,
  FAILOVER_REASON,
  MEETING_FAIL_REASON,
};
