/* eslint-disable no-prototype-builtins */
/* eslint-disable @babel/new-cap */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import meetingConfig from 'meetingConfig';
import qs from 'qs';
import {
  SESSIONSTORAGE_KEYS,
  LOCALSTORAGE_KEYS,
  WEBINAR_CLIENT_CAP_CAN_MUTE,
  PARTICIPANT_NEED_ASK_UNMUTE,
  PARTICIPANT_CAN_BE_ASSIGNED_HUGE_BO,
  WEBNIAR_CLIENT_CAP_SUPPORT_ENDPOINT_IS_H323,
  WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL,
  CAPS_OPTION_SUPPORT_ZOOM_PRESENCE,
} from '../constant';
import { RWG_USER_TYPE, RECORDING_STATUS, PREVIEW_OPTIONS } from '../enum';
import {
  iText,
  inIframe,
  isBitSet,
  isSupportAudioWorklet,
  isOnIOS,
  isExternalControlledMode,
  queryBit,
  isUsingAudioLevelWorklet,
} from '../util';
import * as userTypes from '../../constants/UserRoles';
import { globalVariable } from '../global-variable';
import { isInRoom } from '../../features/breakout-room/utils';
import { storeType } from '../easy-store';
import { GUEST } from '../resource';
import { GLOBAL_FOOTER_TOOLBAR_HEIGHT } from '../../features/sharing/enum';
import { ATTENDEE_STATUS } from '../../features/breakout-room/constant';
import { INTERPRETER } from '../../features/interpretation/resource';
import { FEATUTRE_FLAG, opAudioBridgeFlag } from '../op-feature-option';
import {
  getPreviewOptions,
  isViewOnlyInPreview,
} from '../../features/preview/service';
import { getBaseUrl, getMediasdkBaseUrl } from '../../task/global/service';
import _ from 'lodash';

export function isH323User(user) {
  return user && user.userType === RWG_USER_TYPE.H323;
}

export function isRwgPhoneUser(user) {
  return user && user.userType === RWG_USER_TYPE.PHONE;
}
// helper function: check if user is RMC Admin
export function isRmcAdmin(user) {
  return !!user.isRMC;
}

export function isQAEnabled() {
  return meetingConfig.isSupportQA;
}

export function isBreakoutRoomEnabled() {
  return meetingConfig.meetingOptions.isEnableBreakoutRoom;
}

export function isMySelf(currentUserId, user) {
  return currentUserId === user.userId;
}

export function isHost(role) {
  return (role & userTypes.USER_ROLE_HOST) === userTypes.USER_ROLE_HOST;
}

export function isWebinar() {
  return meetingConfig.isWebinar === 1;
}

const isShowGuestLabelVisible = (user) => user.isGuest == null || user.isGuest;

const dnSuffixGeneratorFunc = [
  [
    (currentUserId, user) => isHost(user.userRole),
    iText('Host', 'apac.wc_host_1'),
  ],
  [
    (currentUserId, user) => !isHost(user.userRole) && user.bCoHost,
    iText('Co-host', 'apac.wc_co_host_2'),
  ],
  [
    (currentUserId, user) => isMySelf(currentUserId, user),
    iText('Me', 'apac.wc_chat.me'),
  ],
  [
    (currentUserId, user, isCurrentUserGuest) =>
      isShowGuestLabelVisible(user) &&
      !isMySelf(currentUserId, user) &&
      user.role !== userTypes.WEBINAR_ATTENDEES &&
      !isCurrentUserGuest,
    GUEST,
  ],
  [(currentUserId, user) => user.isInterpreter, INTERPRETER],
];

export function getDisplayNameLabel(currentUserId, user, isCurrentUserGuest) {
  const tempSuffix = dnSuffixGeneratorFunc.reduce(
    (suffix, [testFunc, showStr]) =>
      suffix +
      (testFunc(currentUserId, user, isCurrentUserGuest)
        ? suffix
          ? `, ${showStr}`
          : showStr
        : ''),
    '',
  );
  return tempSuffix ? `(${tempSuffix})` : '';
}

export function isViewOnly(role) {
  return (role & userTypes.USER_ROLE_VIEWONLY) === userTypes.USER_ROLE_VIEWONLY;
}

export function isAllowOnlyCanTalk(role) {
  return (
    (role & userTypes.USER_ROLE_VIEWONLY_CANTALK) ===
    userTypes.USER_ROLE_VIEWONLY_CANTALK
  );
}
export function isWebinarClientSupportAllowToTalk(clientCap) {
  return (
    (clientCap & WEBINAR_CLIENT_CAP_CAN_MUTE) === WEBINAR_CLIENT_CAP_CAN_MUTE
  );
}

export function isWebinarAttendeeH323User(clientCap) {
  return (
    (clientCap & WEBNIAR_CLIENT_CAP_SUPPORT_ENDPOINT_IS_H323) ===
    WEBNIAR_CLIENT_CAP_SUPPORT_ENDPOINT_IS_H323
  );
}

export function isWebinarClientSupportCMDChat(clientCap) {
  return (
    (clientCap & WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL) ===
    WEBNIAR_CLIENT_CAP_SUPPORT_CONF_CHAT_CHANNEL
  );
}

export function isPanelist(role) {
  return !isHost(role) && !isViewOnly(role) && isWebinar();
}

// get user according user id
export function getUserById(users, userId) {
  return _.find(users, (user) => user.userId === userId);
}

export function getUserByJid(users, userJid) {
  return _.find(users, (user) => user.jid === userJid);
}

export function getXmppUserById(xmppUsers, userId) {
  return _.find(xmppUsers, (user) => user.node === userId);
}

export function getUserByXmppUserNode(meetingAttendees, node) {
  return _.find(meetingAttendees, (a) => a.userId === node);
}

export function getInMeetingParticipantsFromList(users) {
  return _.filter(users, (user) => !user.bHold);
}

export function getPanelistsAndHostFromList(users) {
  return _.filter(users, (user) => {
    const { userRole } = user;
    return isPanelist(userRole) || isHost(userRole);
  });
}

export const isHostGroup = (role) =>
  _.includes(
    [
      userTypes.USER_ROLE_HOST,
      userTypes.USER_ROLE_OWNER,
      userTypes.UESR_ROLE_OWNERHOST,
    ],
    role,
  );

export function isOwner(role) {
  return (role & userTypes.USER_ROLE_OWNER) === userTypes.USER_ROLE_OWNER;
}

export const isCoHost = (user) => user.bCoHost;

export const isCoOrHost = (user) =>
  isHost(user.userRole || user.role) || user.bCoHost;

export const isZRUserByCaps = (caps) =>
  !!(caps & CAPS_OPTION_SUPPORT_ZOOM_PRESENCE);

export const isZRMultiStreamVideoUser = ({ caps, bMultiStreamVideoUser }) => {
  return isZRUserByCaps(caps) && bMultiStreamVideoUser !== undefined;
};

export const isZRMultiStreamVideoParentUser = ({
  caps,
  bMultiStreamVideoUser,
}) => {
  return (
    isZRMultiStreamVideoUser({ caps, bMultiStreamVideoUser }) &&
    !bMultiStreamVideoUser
  );
};

export const isZRMultiStreamVideoChildUser = ({
  caps,
  bMultiStreamVideoUser,
}) => {
  return (
    isZRMultiStreamVideoUser({ caps, bMultiStreamVideoUser }) &&
    bMultiStreamVideoUser
  );
};

// export const filterZRChildUser = (attendeesList, participant) => {
//   if (!isZRMultiStreamVideoChildUser(participant)) return false;
//   const parent = attendeesList.find(
//     (user) => user.userId === participant.parentUserID,
//   );
//   return parent ? parent.zrCollapse : true;
// };

export function sortParticipantsList(users, currentUser) {
  let group = 'other';
  const participants = users.reduce(
    (tmp, user) => {
      if (isZRMultiStreamVideoChildUser(user)) {
        tmp[group].push(user);
      } else if (user.userId === currentUser.userId) {
        tmp.me.push(user);
        group = 'me';
      } else if (isHost(user.userRole || user.role)) {
        tmp.host.push(user);
        group = 'host';
      } else if (isCoHost(user)) {
        tmp.coHost.push(user);
        group = 'coHost';
      } else if (user.bRaiseHand) {
        tmp.raiseHands.push(user);
        group = 'raiseHands';
      } else {
        tmp.other.push(user);
        group = 'other';
      }
      return tmp;
    },
    {
      me: [],
      host: [],
      coHost: [],
      raiseHands: [],
      other: [],
    },
  );

  return Object.keys(participants).reduce(
    (tmp, userType) => tmp.concat(participants[userType]),
    [],
  );
}

export function sortParticipantsListWithoutMe(users, currentUser) {
  const participants = users.reduce(
    (tmp, user) => {
      if (user.userId === currentUser.userId) {
        tmp.me.push(user);
      } else if (isHost(user.userRole || user.role)) {
        tmp.host.push(user);
      } else if (isCoHost(user)) {
        tmp.coHost.push(user);
      } else if (user.bRaiseHand) {
        tmp.raiseHands.push(user);
      } else {
        tmp.other.push(user);
      }
      return tmp;
    },
    {
      me: [],
      host: [],
      coHost: [],
      raiseHands: [],
      other: [],
    },
  );
  delete participants.me;
  return Object.keys(participants).reduce(
    (tmp, userType) => tmp.concat(participants[userType]),
    [],
  );
}

export function orderByCountryCode(codes) {
  return _.orderBy(codes, 'value', 'asc');
}

export function getDisplayName(participant, currentUserId, isCurrentUserGuest) {
  const name = participant.displayName || participant.name;
  const label = getDisplayNameLabel(
    currentUserId,
    participant,
    isCurrentUserGuest,
  );
  return `${name} ${label}`;
}

export function isZoomDomain(url) {
  const urlStrings = url.split('/');
  if (urlStrings.length < 3) {
    return false;
  }
  const domain = urlStrings[2];
  if (domain.indexOf('zoom') > -1) {
    return true;
  }
  return false;
}

export function getDefaultAvatar() {
  return `${getBaseUrl()}/image/default-avatar.png`;
}

export function getAvatarText(displayName = '') {
  const paths = displayName.toUpperCase().trim().split(/\s+/);

  if (paths.length === 1) {
    return paths[0][0];
  }

  if (paths.length >= 1) {
    const text = paths[0][0] + paths[1][0];
    if (/[\u4E00-\u9FA5]+/.test(text)) {
      return paths[0][0];
    }
    return text;
  }

  return '';
}

export function getAvatarBKColor(displayName = '', grayBK = false) {
  const arrayAvatarBK = [
    '#27ae60',
    '#16a085',
    '#2980b9',
    '#8e44ad',
    '#34495e',
    '#f39c12',
    '#d35400',
    '#c0392b',
  ];

  const arrayAvatarBKGray = [
    '#3327ae60',
    '#3316a085',
    '#332980b9',
    '#338e44ad',
    '#3334495e',
    '#33f39c12',
    '#33d35400',
    '#33c0392b',
  ];

  let index = 0;
  for (let i = 0; i < displayName.length; i++) {
    index += displayName.charCodeAt(i);
    index %= 8;
  }

  return grayBK ? arrayAvatarBKGray[index] : arrayAvatarBK[index];
}

export function getParticipantsListItemAvatarNew(
  participant,
  h323Avatar,
  defaultAvatar,
  bAllowedAvatar,
  isLarge,
) {
  if (!defaultAvatar) {
    defaultAvatar = getDefaultAvatar();
  }
  if (!h323Avatar) {
    h323Avatar = meetingConfig.h323Avatar;
  }
  if (!bAllowedAvatar) {
    return defaultAvatar;
  }
  if (isH323User(participant)) {
    return h323Avatar;
  }
  if (participant.avatar) {
    const urls = participant.avatar.split('?');
    if (isZoomDomain(urls[0])) {
      if (isLarge) {
        return urls.length > 1
          ? participant.avatar
          : `${participant.avatar}?type=large`;
      }
      return participant.avatar;
    }
  }
  return defaultAvatar;
}

export function getWorkerPath() {
  const jsMediaSdkFullPath = getMediasdkBaseUrl();
  return {
    audioWorkerPath: `${jsMediaSdkFullPath}/js_audio_process.min.js`,
    audioWorkletPath: `${jsMediaSdkFullPath}/js_audio_worklet.min.js`,
    audioWorkletSIMDPath: `${jsMediaSdkFullPath}/js_audio_worklet_simd.min.js`,
    audioWorkletProcessPath: `${jsMediaSdkFullPath}/js_audio_worklet_process.min.js`,
    audioLevelWorkletPath: isUsingAudioLevelWorklet()
      ? `${jsMediaSdkFullPath}/js_audio_level_worklet_process.min.js`
      : '',
    audioWasm: `${jsMediaSdkFullPath}/audio.encode.wasm`,
    videoWorkerPath: `${jsMediaSdkFullPath}/video_s.min.js`,
    videoMtWorkerPath: `${jsMediaSdkFullPath}/video_m.min.js`,
    videoWasm: `${jsMediaSdkFullPath}/video.decode.wasm`,
    videoMtWasm: `${jsMediaSdkFullPath}/video.mt.wasm`,
    sharingWorkerPath: `${jsMediaSdkFullPath}/sharing_s.min.js`,
    sharingMtWorkerPath: `${jsMediaSdkFullPath}/sharing_m.min.js`,
    videoSIMDWorkerPath: `${jsMediaSdkFullPath}/video_simd.min.js`,
    videoSIMDWasm: `${jsMediaSdkFullPath}/video.simd.wasm`,
    sharingSIMDWorkerPath: `${jsMediaSdkFullPath}/sharing_simd.min.js`,
    videoMSIMDWasm: `${jsMediaSdkFullPath}/video.mtsimd.wasm`,
    sharingMSIMDWorkerPath: `${jsMediaSdkFullPath}/sharing_mtsimd.min.js`,
    videoMSIMDWorkerPath: `${jsMediaSdkFullPath}/video_mtsimd.min.js`,
    audioSIMDWorkletPath: `${jsMediaSdkFullPath}/audio_simd.min.js`,
    audioSIMDWasm: `${jsMediaSdkFullPath}/audio.simd.wasm`,
    vsmiworkerpath: `${jsMediaSdkFullPath}/video_share_mtsimd.min.js`,
    netThreadPath: `${jsMediaSdkFullPath}/net_thread.min.js`,
  };
}

export function getRWGFromURL(commandSocketUrl) {
  if (!_.isEmpty(commandSocketUrl)) {
    const end = commandSocketUrl.indexOf('/webclient');
    return commandSocketUrl.substring(0, end).replace('wss://', '').trim();
  }
  return '';
}

export function getAVMeetingParams(meetingState) {
  const { meetingJoinStatus, meetingNumber, conID, svcUrl } = meetingState;
  if (meetingJoinStatus !== 'joined' || !svcUrl) {
    return {};
  }

  const videoRwcIpAddress = `wss://${svcUrl}/wc/media/${meetingNumber}?type=v&cid=${conID}`;

  const audioRwcIpAddress = `wss://${svcUrl}/wc/media/${meetingNumber}?type=a&cid=${conID}`;
  const sharingRwcIpAddress = `wss://${svcUrl}/wc/media/${meetingNumber}?type=${
    isSupportAV() ? 's' : 'j'
  }&cid=${conID}`;
  return {
    videoRwcIpAddress,
    audioRwcIpAddress,
    sharingRwcIpAddress,
    ...getWorkerPath(),
  };
}

export function isSupportAV() {
  // return false;
  return (
    meetingConfig.isSupportAV &&
    typeof WebAssembly === 'object' &&
    typeof Worker === 'function'
  );
}

export function isSimulive() {
  return meetingConfig.isSimuliveWebinar;
}

export function isSimulivePhase1() {
  return (
    meetingConfig.isSimuliveWebinar &&
    !meetingConfig.meetingOptions.isEnableSimulivePhase2
  );
}

export function isMeetingSupportVoIPOnly() {
  if (meetingConfig.optionAudioType === 'voip' && isSupportAV()) {
    return true;
  }
  return false;
}

export function isMeetingSupportTelAndVoIP() {
  if (meetingConfig.optionAudioType === 'both') {
    return true;
  }
  if (isMeetingSupportVoIPOnly() && !isSupportAV()) {
    return true;
  }
  return false;
}

export function isMeetingSupportTelOnly() {
  if (meetingConfig.optionAudioType === 'telephony') {
    return true;
  }
  return false;
}

export function isMeetingSupportCallout() {
  if (meetingConfig.optionAudioType === 1) {
    return true;
  }
  return false;
}

export function isJoinAudio(user) {
  if (!_.isEmpty(user)) {
    if (
      user.audio === 'phone' ||
      (user.audio === 'computer' &&
        globalVariable.avSocket?.getJoinVoIPTimes?.() > 0)
    ) {
      return true;
    }
  }
  return false;
}

export function isJoinVoIP(user) {
  if (!_.isEmpty(user)) {
    if (
      user.audio === 'computer' &&
      globalVariable.avSocket?.getJoinVoIPTimes?.() > 0
    ) {
      return true;
    }
  }
  return false;
}

export function isJoinByPhone(user) {
  return !_.isEmpty(user) && user.audio === 'phone';
}

export function isJoinByCallMe(user, isDialoutSucces) {
  if (!_.isEmpty(user)) {
    if (user.audio === 'phone' && isDialoutSucces) {
      return true;
    }
  }
  return false;
}

export function isIE() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isOpera = /opera|opr\/[\d]+/.test(userAgent);
  return !isOpera && /(msie|trident)/.test(userAgent);
}

export function shouldRenderNewCanvas() {
  return isSupportAV() || isIE();
}

export function canConnectAVSocket() {
  return isSupportAV() || isIE();
}

export function getInitHoldVal() {
  const flagByEnableWaitingRoom = false;
  const oldUserEmail = easyStore.easyGet('userEmail');
  const flagByCookie = `${easyStore.easyGet('isOnHold')}` === '1';
  const result = (
    meetingConfig.userEmail
      ? meetingConfig.userEmail === oldUserEmail
      : easyStore.isOldUserName()
  )
    ? flagByCookie
    : flagByEnableWaitingRoom;

  return result;
}

export function isMac() {
  return navigator.platform.indexOf('Mac') > -1;
}

export function getCountryCode(callOutCountry) {
  if (_.isEmpty(callOutCountry)) return [];
  let countryCode;
  const options = [];

  callOutCountry.forEach((item) => {
    countryCode = {
      value: item.name,
      label: `${item.name} (${item.code})`,
      code: item.code,
    };
    options.push(countryCode);
  });
  return orderByCountryCode(options);
}

export function getParticipantItemTabIndex(isMySelf) {
  return isMySelf ? 0 : -1;
}

export const receiveSharingByJPG = () => isIE();

export const isUISelfPaintSharing = () => !isIE() && !isSupportAV();

export const isIrregularGcmBrowser = () => isIE() || !isSupportAV();

export const getAttendeeType = (jid) => {
  if (/^wu_/.test(jid)) {
    return userTypes.WEBINAR_ATTENDEES_WEB;
  }
  if (/^tu_/.test(jid)) {
    return userTypes.WEBINAR_ATTENDEES_PHONE;
  }
  return null;
};

export const isTelephoneBoundAtteendee = (attendee) => {
  return attendee.bindPhoneUid;
};

export function isTSP() {
  const isTSPEnbaled =
    meetingConfig.tsp &&
    meetingConfig.tsp.hasOwnProperty('isTSP') &&
    meetingConfig.tsp.isTSP;
  const hasValidTSPCallinInfo =
    meetingConfig.tsp &&
    meetingConfig.tsp.hasOwnProperty('callInfo') &&
    _.startsWith(meetingConfig.tsp.callInfo, 'MC') &&
    meetingConfig.tsp.callInfo.split(';').length > 1;
  return isTSPEnbaled && hasValidTSPCallinInfo;
}

export function isVOIPSupportPaltformAndBrowser() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isOpera = /opera|opr\/[\d]+/.test(userAgent);
  const isEdge = /edge\/(\d+)/.test(userAgent);
  const isChrome =
    !isOpera && !isEdge && /chrome/.test(userAgent) && /webkit/.test(userAgent);
  const isFirefox = /firefox/.test(userAgent);
  const isAndroid = /android/.test(userAgent);
  const isLinux = !isAndroid && /linux (i686|x86_64)/.test(userAgent);
  return isOpera || isChrome || (isLinux && isFirefox);
  // return true || isLinux && isFirefox;
}

export function hideJoinLoading() {
  const wcLoadingNode = document.getElementById('wc-loading');
  if (wcLoadingNode) {
    wcLoadingNode.style.display = 'none';
  }
}
export function showJoinLoading() {
  const wcLoadingNode = document.getElementById('wc-loading');
  if (wcLoadingNode) {
    wcLoadingNode.style.display = '';
  }
}
export function getWebinarUserIdFromJid(jid, meetingNumber) {
  let userId = '';
  if (!_.isEmpty(jid)) {
    const jidPre = `wu_${meetingNumber}_`;
    if (_.startsWith(jidPre, jidPre)) {
      const newJid = jid.replace(jidPre, '');
      const flag = newJid.indexOf('@');
      if (flag !== -1) {
        const arr = newJid.substring(0, flag).split('#');
        userId = arr[0];
      }
    }
  }
  return userId;
}

export function launchFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
}

export function exitFullscreen() {
  if (document.exitFullscreen) {
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
}

export function setIsHostAtLocal(bHost) {
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.webClient_isHost,
    bHost,
    storeType.sessionStorage,
  );
}

export function getDefaultIsHostValue() {
  const isHost = easyStore.easyGet(SESSIONSTORAGE_KEYS.webClient_isHost);
  if (!_.isNil(isHost)) {
    return isHost === true;
  }
  return meetingConfig.isHost;
}

export function storeLeaveParticipant(state, message, isFirstWSChannel) {
  const {
    body: { remove },
  } = message;
  const {
    attendeesList: { attendeesList },
    breakoutRoom: {
      attendee: { status },
      mainSessionAttendeeList,
    },
  } = state;
  if (
    isWebinar() ||
    (isFirstWSChannel && isInRoom(status)) ||
    _.isEmpty(remove)
  ) {
    return;
  }
  let allAttendeeListInMeeting = attendeesList;
  if (!isFirstWSChannel && isInRoom(status)) {
    allAttendeeListInMeeting = mainSessionAttendeeList;
  }
  const currentRemoveList = remove.filter((item) => item.nUserStatus !== 1);
  if (currentRemoveList.length === 0) {
    return;
  }
  const allParticipants = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_RemovedParticipants,
  );
  const removeList = [];
  currentRemoveList.forEach((item) => {
    const targetRemoveAttendee = allAttendeeListInMeeting.find(
      (attendee) => attendee.userId === item.id,
    );
    if (targetRemoveAttendee) {
      removeList.push({
        userId: item.id,
        avatar: targetRemoveAttendee.avator,
        userRole: targetRemoveAttendee.userRole,
        bCoHost: targetRemoveAttendee.bCoHost,
        displayName: targetRemoveAttendee.displayName,
        isRemoved: true,
        zoomID: targetRemoveAttendee.zoomID,
        userGUID: targetRemoveAttendee.userGUID,
        isGuest: targetRemoveAttendee.isGuest,
      });
    }
  });
  if (removeList.length === 0) {
    return;
  }
  let result = '';
  if (allParticipants) {
    let resultArray = [];
    const allParticipantsList = JSON.parse(allParticipants);
    if (_.isArray(allParticipantsList)) {
      allParticipantsList.forEach((user) => {
        // filter user which has been removed
        const targetHasBeenRemovedIndex = removeList.findIndex(
          (item) => item.zoomID === user.zoomID,
        );
        if (targetHasBeenRemovedIndex > -1) {
          removeList.splice(targetHasBeenRemovedIndex, 1);
        }
      });
      resultArray = allParticipantsList.concat(removeList);
      result = JSON.stringify(resultArray);
    }
  } else {
    result = JSON.stringify(removeList);
  }
  if (result) {
    easyStore.easySet(
      SESSIONSTORAGE_KEYS.webClient_RemovedParticipants,
      result,
      storeType.sessionStorage,
    );
  }
}

export function getLeavedParticiapnts() {
  const allParticipants = easyStore.easyGet(
    SESSIONSTORAGE_KEYS.webClient_RemovedParticipants,
  );
  if (allParticipants) {
    const allParticipantsList = JSON.parse(allParticipants);
    if (_.isArray(allParticipantsList)) {
      return allParticipantsList;
    }
    return [];
  }
  return [];
}
export const getJoinUrl = (meetingNumber) => {
  let joinURL = '';
  const text = getInviteEmail();
  if (text) {
    joinURL = text
      .split('\n')
      .find(
        (v) => v.indexOf('https') > -1 && v.indexOf(`${meetingNumber}`) > -1,
      );
  }
  return joinURL;
};

export const getNumberBadge = (value, max = 99) => {
  if (value > max) {
    return `${max}+`;
  }
  return value;
};

export function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  // Time in microseconds since page-load or 0 if unsupported
  let d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export const getDeviceId = () => {
  let deviceId = easyStore.easyGet('ZM-CID');
  if (!deviceId) {
    deviceId = generateUUID();
    easyStore.easySet('ZM-CID', deviceId, storeType.localStorage);
  }
  return deviceId;
};

export function isAskToUnmute(caps) {
  return (caps & PARTICIPANT_NEED_ASK_UNMUTE) === PARTICIPANT_NEED_ASK_UNMUTE;
}

export function canBeAssignedToHugeBo(caps) {
  return (
    (caps & PARTICIPANT_CAN_BE_ASSIGNED_HUGE_BO) ===
    PARTICIPANT_CAN_BE_ASSIGNED_HUGE_BO
  );
}

export function updateOptInStorage(message) {
  const {
    body: { opt },
  } = message;
  easyStore.easySet(
    SESSIONSTORAGE_KEYS.webClient_opt,
    opt,
    storeType.sessionStorage,
  );
}

export function isMicDeviceValid(deviceId, deviceLabel) {
  const preCondiditon = deviceId !== 'communications';

  return (
    preCondiditon &&
    !/ZoomAudioDevice/i.test(deviceLabel) &&
    !/Zoom-\S*/.test(deviceLabel) &&
    !/CubebAggregateDevice\S*/.test(deviceLabel)
  );
}

export function isSpeakerDeviceValid(deviceId, deviceLabel) {
  const preCondiditon = deviceId !== 'communications';

  return preCondiditon && !/ZoomAudioDevice/i.test(deviceLabel);
}

//! mobile has an extra command center flag, webclient_mobile_enable_enable_chat
export function isChatEnabled() {
  return (
    meetingConfig.meetingOptions.isChatEnabled &&
    meetingConfig.meetingOptions.isWCLMobileChatEnable
  );
}

export function getFooterHeight() {
  return GLOBAL_FOOTER_TOOLBAR_HEIGHT;
}

export function isEnableHostNeedConfirmRecodingReminder() {
  const {
    recordingReminderForRecorder,
    meetingOptions: { isUserEnableRecordingReminder },
  } = meetingConfig;
  if (recordingReminderForRecorder && isUserEnableRecordingReminder) {
    return recordingReminderForRecorder.isEnableRecordingReminder;
  }
  return false;
}

export function isSupportReport(
  reportDomain,
  boAttendeeStatus = ATTENDEE_STATUS.INITIAL,
) {
  return (
    !!reportDomain &&
    meetingConfig.meetingOptions.isReportParticipantsEnabled &&
    !isWebinar() &&
    !isInRoom(boAttendeeStatus)
  );
}

export function isParticipantSupportReport(
  reportDomain,
  boAttendeeStatus = ATTENDEE_STATUS.INITIAL,
) {
  return (
    isSupportReport(reportDomain, boAttendeeStatus) &&
    meetingConfig.meetingOptions.isAllowParticipantsReport
  );
}

export function isFirstEnter() {
  return !easyStore.easyGet(LOCALSTORAGE_KEYS.webClient_first_enter_flag);
}

export function isShowUpdateBrowserTips() {
  return false;
}

export function isShowNewFeatureTips() {
  return false;
}

export function getRecordingStatus(bRecord, bPause) {
  if (typeof bRecord !== 'undefined' || typeof bPause !== 'undefined') {
    if (bPause) {
      return RECORDING_STATUS.PAUSE;
    }
    if (bRecord) {
      return RECORDING_STATUS.RECORDING;
    }
    return RECORDING_STATUS.STOP;
  }
  return undefined;
}
export function isRecordingPaused(bRecord) {
  return bRecord === RECORDING_STATUS.PAUSE;
}
export function isRecordingStoped(bRecord) {
  return bRecord === RECORDING_STATUS.STOP;
}
export function isRecordingInProgress(bRecord) {
  return bRecord === RECORDING_STATUS.RECORDING;
}

export function isOnZoomMeeting() {
  return meetingConfig.onZoomOptions && meetingConfig.onZoomOptions.isOnZoom;
}
export function isDisableInviteForOnZoom() {
  return isOnZoomMeeting() && meetingConfig.onZoomOptions.disableInvite;
}
/* export function isHostAutoStartVideo() {
  return isOnZoomMeeting() && meetingConfig.optionVideoHost;
}
export function isParticipantsAutoStartVideo() {
  return isOnZoomMeeting() && meetingConfig.optionVideoParticipants;
} */

const isPreviewAudioJoin = () => {
  if (inIframe()) {
    return false;
  }

  if (isOnZoomMeeting()) {
    return true;
  }
  if (isViewOnlyInPreview()) {
    return true;
  }
  return queryBit(getPreviewOptions(), PREVIEW_OPTIONS.AUTO_JOIN_AUDIO);
};

const isPreviewAudioUnmuted = () => {
  return queryBit(getPreviewOptions(), PREVIEW_OPTIONS.AUDIO_ON);
};

// not for platform
const isPreviewVideoOn = (userRole) => {
  const previewOptions = getPreviewOptions();
  if (inIframe() || isViewOnly(userRole)) {
    return false;
  }

  if (isOnZoomMeeting()) {
    return meetingConfig.isStart
      ? meetingConfig.optionVideoHost
      : meetingConfig.optionVideoParticipants;
  }

  return meetingConfig.isStart
    ? meetingConfig.optionVideoHost // onZoom also check optionVideoHost for original host
    : !!isBitSet(previewOptions, 0b100);
};

export const isAutoTurnOnVideo = (userRole) => {
  // normal mode: browsers
  return isPreviewVideoOn(userRole);
};

export const isAutoTurnOnAudio = () => {
  return isPreviewAudioJoin();
};

export const isAutoUnmuteAudio = () => {
  return isPreviewAudioUnmuted();
};

/** if take audio bridge solution */
export const isAudioBridge =
  opAudioBridgeFlag !== FEATUTRE_FLAG.FEATUTRE_FLAG_UNDEFINE
    ? opAudioBridgeFlag === FEATUTRE_FLAG.FEATUTRE_FLAG_ENABLE
    : isOnIOS() &&
      (meetingConfig.useAudioBridgeForIOS ||
        meetingConfig.useAudioBridgeForIOS === undefined);

/** if take audio siphone */
export const isSiphone =
  !isAudioBridge &&
  (meetingConfig.useTelephoneAudio ||
    !(isSupportAV() && isSupportAudioWorklet()) ||
    isOnIOS());

export function cacheMainSessionEncryptKey(
  encryptKeyType,
  { encryptKey, additionalType },
  isInBO,
) {
  if (!isInBO) {
    easyStore.easySet(
      encryptKeyType,
      {
        encryptKey,
        additionalType,
      },
      storeType.sessionStorage,
    );
  }
}

export const getMainSessionEncryptKey = (encryptKeyType) => {
  return easyStore.easyGet(encryptKeyType);
};

export const isNewJoinFlowEnabled = () => {
  return meetingConfig.isNewJoinFlow;
};

export const isSinglePageFlowEnabled = () => {
  return meetingConfig.joinFlowPhase3;
};

/**
 * is support preview option setting.
 * @returns bool
 */
export function isSupportPOSetting() {
  if (isExternalControlledMode()) {
    return false;
  }
  if (isOnZoomMeeting()) {
    return false;
  }
  return true;
}

// remove po in query string to avoid previewOptions reset after page refresh, see ZOOM-352317
export const removePOInQuery = () => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if (!_.isNil(query.po)) {
    delete query.po;
    window.history.replaceState(
      null,
      '',
      `${window.location.pathname}?${qs.stringify(query)}`,
    );
  }
};

export const relativeWindowHeight = (height) => {
  if (height) {
    return height;
  }
  return window.innerHeight;
};

export const relativeWindowWidth = (width) => {
  if (width) {
    return width;
  }
  return window.innerWidth;
};

export function getWaterMarkText() {
  return meetingConfig.userEmail || easyStore.easyGet('sessionUserName');
}

// #region PMC newChat releated
export function isEnablePMC() {
  return meetingConfig.meetingOptions.isEnablePersistentMeetingChat;
}

export const getUserFullName = (function getUserFullNameWrapper() {
  let fullName;
  const hasGetName = false;
  return () => {
    if (!hasGetName) {
      fullName = [meetingConfig.firstName, meetingConfig.lastName]
        .filter(Boolean)
        .join(' ');
    }
    return fullName;
  };
})();

/*
newMeetingChatExperience: {
    "deleteMsgInMeetingChat": false,
    "screenshotInMeetingChat": false,
    "editMsgInMeetingChat": false,
    "allowNewMeetingChatExperience": false,
    "ReactionInMeetingChat": false
}
*/
// new chat enable for meeting level
export function isNewChatWebSettingEnable() {
  if (_.isObject(meetingConfig.meetingOptions.newMeetingChatExperience)) {
    return meetingConfig.meetingOptions.newMeetingChatExperience
      ?.allowNewMeetingChatExperience;
  }
  return false;
}

export function isNewChatUIReady(isInBo = false) {
  if (isWebinar()) return false;
  if (isEnablePMC() && !isInBo) return true;
  return isNewChatWebSettingEnable();
}

export function isNewChatEnableDelete() {
  return (
    isNewChatUIReady() &&
    meetingConfig.meetingOptions.newMeetingChatExperience
      ?.deleteMsgInMeetingChat
  );
}

export function isNewChatEnableEdit() {
  return (
    isNewChatUIReady() &&
    meetingConfig.meetingOptions.newMeetingChatExperience?.editMsgInMeetingChat
  );
}

/**
 *
 * v5.12.6, op flag default OFF
 * op flag = OFF isEnableMeetingChatReaction = false
 * PMC display reply & reaction
 * normal meeting hide  reply & reaction, web setting also hide switch
 * op flag = ON isEnableMeetingChatReaction = true
 * PMC show reply & reaction
 * normal meeting reply，reaction follow web setting(new chat experience - reaction)
 *
 * result: PMC always show reply & reaction
 * if flag off, normal meeting hide reply & reaction, otherwise follow web setting
 */
export function isNewChatEnableReplyReactionInOp() {
  const { isEnableMeetingChatReaction } = meetingConfig.meetingOptions || {};
  if (typeof isEnableMeetingChatReaction === 'undefined') {
    return true;
  }
  return isEnableMeetingChatReaction;
}

export function isNewChatEnableReaction() {
  if (isEnablePMC()) return true;
  return (
    isNewChatUIReady() &&
    meetingConfig.meetingOptions.newMeetingChatExperience
      ?.ReactionInMeetingChat &&
    isNewChatEnableReplyReactionInOp()
  );
}

export function isNewChatEnableReply() {
  if (isEnablePMC()) return true;
  return isNewChatEnableReplyReactionInOp();
}

export function getMeetingChannelId() {
  return meetingConfig.meetingOptions.channelId;
}

export function getUserXmppJidRemoveMN(jid = '', meetingNumber) {
  const prefixReg = new RegExp(`^\\w\\w_${meetingNumber}_`);
  return jid.replace(prefixReg, '');
}

export function isBlockedJoinMeeting() {
  if (!meetingConfig.meetingForceBreak) {
    return false;
  }
  return meetingConfig.meetingForceBreak.errorCode === 13210;
}

// #endregion

export const getCookieDomain = () => {
  const domain = location.host.match(/[^.]*\.[^.]{2,3}(?:\.[^.]{2,3})?$/)[0];
  return `.${domain}`;
};

export function getVideoRwcIpAddress({ svcUrl, meetingNumber, conferenceID }) {
  return `wss://${svcUrl}/wc/media/${meetingNumber}?type=v&cid=${conferenceID}`;
}

export function getAudioRwcIpAddress({ svcUrl, meetingNumber, conferenceID }) {
  return `wss://${svcUrl}/wc/media/${meetingNumber}?type=a&cid=${conferenceID}`;
}

export function getInviteEmail() {
  const emailEl = document.getElementById('invite_email');
  if (emailEl && emailEl.value) {
    return emailEl.value;
  } else {
    return meetingConfig.invite_email;
  }
}

export const getWebMediaBlockConfig = () => meetingConfig.webMediaBlockConfig;
