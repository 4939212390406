import { createFullResourceUrl, getBaseUrl } from './service';

const getFileName = (path) => {
  const splitIndex = path.lastIndexOf('/') + 1;
  return path.substring(splitIndex);
};

export const LoadJs = /** @class */ (function () {
  function LoadJs(config) {
    this.value = null;
    this.browserAgent = this.getAgent();
    this.config = config;
    this.head =
      document.getElementsByTagName('head')[0] || document.documentElement;
    this.defaultBaseUrl = getBaseUrl();
  }
  LoadJs.prototype.getAgent = function () {
    if (window.ActiveXObject || 'ActiveXObject' in window) {
      return 'ie';
    }
    return 'other';
  };
  LoadJs.start = function (config) {
    return Promise.resolve(new LoadJs(config));
  };
  LoadJs.prototype.add = function (...paths) {
    let url = '';
    if (paths.length === 1) {
      url = createFullResourceUrl(this.defaultBaseUrl, ...paths);
    } else {
      url = createFullResourceUrl(...paths);
    }
    return this.load(url);
  };
  LoadJs.prototype.asyncAdd = function () {};
  LoadJs.prototype.load = function (url, isSync = true) {
    // create script for adding into the head
    const fileName = getFileName(url);

    const script = document.createElement('script');
    if (this.config?.ondemandAssetsSRI[fileName]) {
      script.integrity = this.config?.ondemandAssetsSRI[fileName];
      script.crossOrigin = 'anonymous';
    }
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    if (!isSync) {
      script.async = true;
    }
    script.id = url;
    script.src = url;
    const that = this;
    return new Promise(function (resolve, reject) {
      let done = false;
      const loadFunction = function () {
        if (
          !done &&
          (!script.readyState ||
            script.readyState === 'loaded' ||
            script.readyState === 'complete')
        ) {
          done = true;
          // Handle memory leak in IE
          script.onload = null;
          script.onreadystatechange = null;
          resolve(that);
        }
      };
      script.onload = loadFunction;
      script.onreadystatechange = loadFunction;
      script.onerror = reject;
      that.head.appendChild(script);
    });
  };
  return LoadJs;
})();
