import axios from 'axios';
import qs from 'qs';
import meetingConfig from 'meetingConfig';
import { PREVIEW_AUDIO_STATUS, PREVIEW_VIDEO_STATUS } from './consts';
import { PREVIEW_OPTIONS } from '../../global/enum';
import { queryBit, toggleBit } from '../../global/util';
import { errorLog } from '../../global/web-client-logger';
import { easyStore, storeType } from '../../global/easy-store';
import { SESSIONSTORAGE_KEYS } from '../../global/constant';

export const generatePreviewOptions = (audioStatus, videoStatus) => {
  let previewOptions = 0;
  switch (audioStatus) {
    case PREVIEW_AUDIO_STATUS.UNMUTED:
      previewOptions += PREVIEW_OPTIONS.AUTO_JOIN_AUDIO;
      previewOptions += PREVIEW_OPTIONS.AUDIO_ON;
      break;
    case PREVIEW_AUDIO_STATUS.MUTED:
      previewOptions += PREVIEW_OPTIONS.AUTO_JOIN_AUDIO;
      break;
    case PREVIEW_AUDIO_STATUS.NOT_CONNECTED:
      previewOptions += PREVIEW_OPTIONS.AUDIO_ON;
      break;
    default:
      break;
  }
  if (videoStatus === PREVIEW_VIDEO_STATUS.OPEN) {
    previewOptions += PREVIEW_OPTIONS.VIDEO_ON;
  }
  return previewOptions;
};

export const setPreviewOptions = (value, bit, bitVal, bit2, bitVal2) => {
  let newVal = value;
  const currentBitVal = queryBit(value, bit);
  if (bitVal !== currentBitVal) {
    newVal = toggleBit(value, bit);
  }
  if (bit2) {
    const currentBitVal2 = queryBit(value, bit2);
    if (bitVal2 !== currentBitVal2) {
      newVal = toggleBit(newVal, bit2);
    }
  }
  if (newVal === value) {
    return;
  }
  const url = `${meetingConfig.baseUrl}/wc/change_setting`;
  const data = {
    type: 'webclient',
    hash: meetingConfig.wc_hash,
    avOptions: newVal,
  };
  easyStore.easySet(SESSIONSTORAGE_KEYS.PO, newVal, storeType.sessionStorage);
  axios
    .post(url, qs.stringify(data))
    .then(({ data: resData }) => {
      if (!resData.status) {
        errorLog(resData.errorMessage);
      }
    })
    .catch((error) => errorLog(error));
};
