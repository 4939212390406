import { reportResourceTiming } from './global/utils/resource-timing';
import { tableLog } from './global/web-client-logger';
import { makeLogger, reportPerformance } from './global/logger/laplace';

const logger = makeLogger(['Performance', 'First Screen Performance Event']);

export const PERFORMANCE_MARK = {
  start_entryJS: 'start_entryJS',

  start_PreviewJS: 'start_PreviewJS',
  inPreview_hideLoading: 'inPreview_hideLoading',
  inPreview_RWC_start: 'inPreview_RWC_start',
  inPreview_RWC_end: 'inPreview_RWC_end',
  inPreview_RWG_start: 'inPreview_RWG_start',
  beenPreview_initAudioEncode: 'beenPreview_initAudioEncode',
  beenPreview_initAudioDecode: 'beenPreview_initAudioDecode',
  beenPreview_initVideoDecode: 'beenPreview_initVideoDecode',
  beenPreview_initVideoEncode: 'beenPreview_initVideoEncode',
  inPreview_audioBridgeDecodeSuccess: 'inPreview_audioBridgeDecodeSuccess',
  inPreview_audioBridgeEncodeSuccess: 'inPreview_audioBridgeEncodeSuccess',
  inPreview_onJoinFetch: 'inPreview_onJoinFetch',
  inPreview_onJoin: 'inPreview_onJoin',
  inPreview_onJoin_fromJBH: 'inPreview_onJoin_fromJBH',
  start_InMeetingJS_fromPreview: 'start_InMeetingJS_fromPreview',

  start_InMeetingJS: 'start_InMeetingJS',
  inMeeting_RWC_start: 'inMeeting_rwc_start',
  inMeeting_RWC_end: 'inMeeting_RWC_end',
  inMeeting_RWG_start: 'inMeeting_RWG_start',
  inMeeting_RWG_success: 'inMeeting_RWG_success',
  inMeeting_hideLoading: 'inMeeting_hideLoading',
  inMeeting_initAudioDecode: 'inMeeting_initAudioDecode',
  inMeeting_initAudioEncode: 'inMeeting_initAudioEncode',
  inMeeting_initVideoDecode: 'inMeeting_initVideoDecode',
  inMeeting_initVideoEncode: 'inMeeting_initVideoEncode',
  inMeeting_initSharingDecode: 'inMeeting_initSharingDecode',
  inMeeting_initSharingEncode: 'inMeeting_initSharingEncode',
  inMeeting_initVB: 'inMeeting_initVB',
  inMeeting_initVBSuccess: 'inMeeting_initVBSuccess',
  inMeeting_audioDecodeSuccess: 'inMeeting_audioDecodeSuccess',
  inMeeting_audioEncodeSuccess: 'inMeeting_audioEncodeSuccess',
  inMeeting_videoDecodeSuccess: 'inMeeting_videoDecodeSuccess',
  inMeeting_videoEncodeSuccess: 'inMeeting_videoEncodeSuccess',
  inMeeting_sharingDecodeSuccess: 'inMeeting_sharingDecodeSuccess',
  inMeeting_sharingEncodeSuccess: 'inMeeting_sharingEncodeSuccess',
  inMeeting_audioBridgeDecodeSuccess: 'inMeeting_audioBridgeDecodeSuccess',
  inMeeting_audioBridgeEncodeSuccess: 'inMeeting_audioBridgeEncodeSuccess',
  inMeeting_videoDecodeFirstFrame: 'inMeeting_videoDecodeFirstFrame',

  inMeeting_failed: 'inMeeting_failed',
};

export const COMMAND = {
  continue: 'continue',
  pickShorterOne: 'pickShorterOne',
};

export const measureList = [
  // preview
  [
    PERFORMANCE_MARK.start_entryJS,
    PERFORMANCE_MARK.start_PreviewJS,
    COMMAND.continue,
    [
      [
        PERFORMANCE_MARK.start_PreviewJS,
        PERFORMANCE_MARK.inPreview_hideLoading,
      ],
      [
        PERFORMANCE_MARK.inPreview_RWC_start,
        PERFORMANCE_MARK.inPreview_RWC_end,
      ],
      [
        PERFORMANCE_MARK.inPreview_RWC_end,
        PERFORMANCE_MARK.inPreview_RWG_start,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initAudioEncode,
        PERFORMANCE_MARK.inMeeting_audioEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initAudioDecode,
        PERFORMANCE_MARK.inMeeting_audioDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initVideoDecode,
        PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.beenPreview_initVideoEncode,
        PERFORMANCE_MARK.inMeeting_videoEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoinFetch,
        PERFORMANCE_MARK.inPreview_onJoin,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoin,
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
      ],
      [
        PERFORMANCE_MARK.inPreview_onJoin_fromJBH,
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_sharingDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_sharingEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_audioBridgeDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
        PERFORMANCE_MARK.inMeeting_audioBridgeEncodeSuccess,
      ],
    ],
  ],
  // in-meeting
  [
    PERFORMANCE_MARK.start_entryJS,
    PERFORMANCE_MARK.start_InMeetingJS,
    COMMAND.continue,
    [
      [
        PERFORMANCE_MARK.start_InMeetingJS,
        PERFORMANCE_MARK.inMeeting_RWC_start,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWC_start,
        PERFORMANCE_MARK.inMeeting_RWC_end,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWC_end,
        PERFORMANCE_MARK.inMeeting_RWG_start,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWG_start,
        PERFORMANCE_MARK.inMeeting_RWG_success,
      ],
      [
        PERFORMANCE_MARK.inMeeting_RWG_success,
        PERFORMANCE_MARK.inMeeting_hideLoading,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioDecode,
        PERFORMANCE_MARK.inMeeting_audioDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioEncode,
        PERFORMANCE_MARK.inMeeting_audioEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initVideoDecode,
        PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initVideoEncode,
        PERFORMANCE_MARK.inMeeting_videoEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initSharingDecode,
        PERFORMANCE_MARK.inMeeting_sharingDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initSharingEncode,
        PERFORMANCE_MARK.inMeeting_sharingEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioDecode,
        PERFORMANCE_MARK.inMeeting_audioBridgeDecodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_initAudioEncode,
        PERFORMANCE_MARK.inMeeting_audioBridgeEncodeSuccess,
      ],
      [
        PERFORMANCE_MARK.inMeeting_videoDecodeSuccess,
        PERFORMANCE_MARK.inMeeting_videoDecodeFirstFrame,
      ],
    ],
  ],
  [PERFORMANCE_MARK.inMeeting_initVB, PERFORMANCE_MARK.inMeeting_initVBSuccess],
];

export const performanceMark = (name) => {
  performance.mark(name);
};
export const performanceCancel = (name) => {
  performance.clearMarks(name);
};
export const measureDuration = (_startPoint, _stopPoint) => {
  try {
    let startPoint = _startPoint;
    if (Array.isArray(startPoint)) {
      startPoint = startPoint.find(
        (v) => !!performance.getEntriesByName(v).length,
      );
    }

    let stopPoint = _stopPoint;
    if (Array.isArray(stopPoint)) {
      stopPoint = stopPoint.find(
        (v) => !!performance.getEntriesByName(v).length,
      );
    }

    const data = performance.measure(
      `${startPoint} to ${stopPoint}`,
      startPoint,
      stopPoint,
    );
    if (CLIENT_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        '[performance measure]: ',
        `${startPoint} to ${stopPoint} = ${data.duration}`,
      );
    }
    return data;
  } catch (e) {
    if (CLIENT_ENV === 'development') {
      logger.print(e);
    }
    return null;
  }
};

const getMinPerformance = (p1, p2) => {
  if (!p1 || !p2) {
    return null;
  }
  return p1.duration > p2.duration ? p2 : p1;
};

const takeMeasure = (_list) => {
  const result = [];

  const doMeasure = (list) => {
    list.forEach(([startPoint, stopPoint, command, continueList]) => {
      let ret = measureDuration(startPoint, stopPoint);

      switch (command) {
        case COMMAND.continue: {
          if (ret && continueList && continueList.length > 0) {
            doMeasure(continueList);
          }
          break;
        }
        case COMMAND.pickShorterOne: {
          if (continueList && continueList.length === 2) {
            const [start, stop] = continueList;
            ret = getMinPerformance(measureDuration(start, stop), ret);
          }
          break;
        }
      }
      if (ret) {
        result.push(ret);
      }
    });
  };

  doMeasure(_list);

  return result
    .sort((a, b) => a.startTime - b.startTime)
    .map(({ name, startTime, duration }) => ({
      name,
      startTime: parseInt(startTime),
      duration: parseInt(duration),
    }));
};

const splitLog = (promises, mainMeasure) => {
  mainMeasure.forEach((v) => {
    promises.push(logger.log(JSON.stringify(v)));
  });
};
const myTableLog = (measureList) => {
  tableLog(
    measureList.reduce((result, { name, ...resProps }) => {
      result[name] = resProps;
      return result;
    }, {}),
  );
};

export const performanceMeasure = () => {
  try {
    // Pull out all of the measurements.
    const result = takeMeasure(measureList);
    const promises = [];
    myTableLog(result);
    splitLog(promises, result);
    reportResourceTiming();

    Promise.all(promises).then(() => {
      reportPerformance();
    });

    // Finally, clean up the entries.
    performance.clearMarks();
    performance.clearMeasures();
  } catch (e) {
    logger.print(e);
  }
};
