import { createContext } from 'react';

export const globalVariable = {
  storeContext: (() => {
    let localContext = null;
    return {
      setContext(context) {
        localContext = createContext(context);
      },
      getContext() {
        if (!localContext) throw Error('Context is not created');
        return localContext;
      },
    };
  })(),
  avSocket: null,
};
